import create from "zustand";
import { Dictionary } from ".";
import { IVideoFile } from "../assets/videos";
import { INumberCard } from "../components/cards/NumberCard";
import DATABASE from "../database";
import { Paths } from "../context/RouterContext";
import { ITitleCard } from "../components/cards/TitleCard";
import { IImageCard } from "../components/cards/ImageCard";

export interface IClientTag {
    logo: string;
    clientPicture?: string;
    clientName?: string;
    clientComment?: string;
    clientCommentSummary?: string;
    stars?: string;

    link?: string;
    clutch?: string;
    starts?: number;
}

export interface ICaseStudy {
    id: string;
    hide?: boolean;
    customPath?: Paths;

    preview: {
        title: string;
        description: string;
        image?: string;
        video?: IVideoFile;
        smaller?: boolean;
    };

    carousel: {
        images: string[];
        imagePosition?: string;
    };

    clientTag: IClientTag;

    headingA: string;

    about: {
        firstDescription: string;
        secondDescription?: string;
        cards?: INumberCard[];
        titleCards?: ITitleCard[];
        imageCards?: IImageCard[];
        videoReel?: string;
    };

    headingB?: string;

    treeviewRole?: {
        description: string;
        cards?: INumberCard[];
        image?: {
            width: string;
            height: string;
            image: string;
        };
    };

    developmentServices?: {
        useTreeviewRole?: boolean;
        description: string;
        services?: {
            title: string;
            description?: string | any;
            items: string[];
            noTick?: boolean;
        }[];
        image?: {
            width: string;
            height: string;
            image: string;
        };
    };

    specialSections?: {
        visionwear?: {
            texts: string[];
            images: string[][];
        };
    };

    clients?: {
        image: string;
        link: string;
    }[];

    videoReel?: string; // Vimeo video Id.

    press?: {
        title: string;
        description: string;
        thumbnail: string;
        link: string;
    }[];

    becomeAnAlphaTester?: {
        description: string;
        path: string;
    };

    history?: {
        cards: { icon: string; description: string }[];
    };
}

export interface ICaseStudyMediaItem {
    title: string;
    description: string;
    image: string;
}

interface IState {
    caseStudies: Dictionary<ICaseStudy>;
    init: (value: Dictionary<ICaseStudy>) => void;
}

const useCaseStudies = create<IState>()((set) => ({
    caseStudies: DATABASE["case-studies"],
    init: (value) => set((state) => ({ caseStudies: value })),
}));

export default useCaseStudies;
