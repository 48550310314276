import "styled-components";
import { DefaultTheme } from "styled-components";
import deepcopy from "deepcopy";

export interface IThemeFontSizes {
    H1: string;
    H2: string;
    H3: string;
    H4: string;
    H5: string;
    H6: string;
    H7: string;
}

export interface IThemeColors {
    primary: string;
    light: string;
    medium: string;
    mediumDark: string;
    dark: string;
}

export interface IThemeGradients {
    primary: {
        left: string;
        right: string;
    };
    secondary: {
        left: string;
        middle: string;
        right: string;
    };
}

declare module "styled-components" {
    export interface DefaultTheme {
        colors: IThemeColors;
        gradients: IThemeGradients;
        fontSize: IThemeFontSizes;
        sizes: {
            pageWidth: {
                main: string;
                small: string;
            };
        };
        mobileQuery: string;
    }
}

const Theme: DefaultTheme = {
    colors: {
        primary: "#006EFF",
        light: "#FFFFFF",
        medium: "#C4C4C4",
        mediumDark: "#8D8D8D",
        dark: "#252525",
    },
    gradients: {
        primary: {
            left: "#007bff",
            right: "#00c2ff",
        },
        secondary: {
            left: "#EB00FF",
            middle: "#007BFF",
            right: "#00C2FF",
        },
    },
    fontSize: {
        H1: "40px",
        H2: "32px",
        H3: "28px",
        H4: "21px",
        H5: "18px", // 16
        H6: "17px", // 15
        H7: "15px", // 13
    },
    sizes: {
        pageWidth: {
            main: "1264px",
            small: "1048px",
        },
    },
    mobileQuery: "@media only screen and (max-width: 1200px)",
};

export const MobileTheme = deepcopy(Theme);

// Mobile Configuration.
{
    MobileTheme.fontSize.H1 = "24px";
    MobileTheme.fontSize.H2 = "20px";
    MobileTheme.fontSize.H3 = "18px";
    MobileTheme.fontSize.H4 = "16px";
    MobileTheme.fontSize.H5 = "15px"; // 13
    MobileTheme.fontSize.H6 = "14px"; // 12
    MobileTheme.fontSize.H7 = "12px"; // 11
}

export default Theme;
