import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Image, Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/university-of-alberta";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";

import imageAboutA from "./about-a.png";
import imageAboutB from "./about-b.png";
import History from "../../case-study/History";

const SAboutPictures = styled.div`
    height: 497px;
    width: 100%;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;

    ${(p) => p.theme.mobileQuery} {
        grid-template-columns: 1fr;
        grid-template-rows: 397px 397px;
        height: auto;
        margin: 32px 0;
    }
`;

const SDevServicesImage = styled.div`
    height: 113px;
    width: 826px;
    max-width: 90vw;
    margin: 34px auto;
`;

function UniversityOfAlberta() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />
                <ClientTag caseStudy={caseStudy} />
                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>
                <About caseStudy={caseStudy}>
                    The{" "}
                    <a
                        href="https://www.ualberta.ca/index.html"
                        target="_blank"
                    >
                        University of Alberta
                    </a>{" "}
                    is a Top 3 Canadian university located in Edmonton, Alberta,
                    and home to 40,000 students in a wide variety of programs.
                    The Faculty of Engineering launched its augmented reality
                    learning platform to provide students with an immersive
                    technology learning experience.
                    <br />
                    <br />
                    The University needed a spatial computing solution that
                    their staff and students could build upon to create custom
                    simulations and access them from anywhere. With the spatial
                    computing platform, students can browse a full catalog of
                    the University's augmented reality experiences to engage
                    with the next generation of learning content from anywhere.{" "}
                    <SAboutPictures>
                        <Image image={imageAboutA} contain />
                        <Image image={imageAboutB} contain />
                    </SAboutPictures>
                    The platform serves as an <b>educational tool</b>, enabling
                    University faculty to{" "}
                    <b>
                        design virtual lab simulations and deliver them directly
                        to each student's home.
                    </b>{" "}
                    This has been crucial for accelerating the speed and
                    iterations of experiments while promoting remote learning.
                </About>
                <SSection gridGap="16px" small>
                    <Video
                        id="903790243?h=36204a6e0e"
                        title="🔥  Heat Particle Physics Simulation Example"
                        whiteTitle
                    />
                </SSection>
                <TreeviewRole caseStudy={caseStudy}>
                    We developed the Faculty of Engineering's augmented reality
                    learning platform as a tool for remote learning and as a
                    sandbox for faculty to design and deploy in-house
                    experiences. The University of Alberta AR platform was
                    developed in collaboration with engineering graduate
                    assistants and faculty professors.
                    <br />
                    <br />
                    Our role is to develop an augmented reality platform
                    infrastructure that allows engineering students to create
                    and deploy their own AR experiences to the platform.
                    <SDevServicesImage>
                        <Image
                            contain
                            image={
                                caseStudy?.developmentServices?.image?.image ??
                                ""
                            }
                        />
                    </SDevServicesImage>
                    We have worked closely with the engineering faculty members,
                    designing and developing their augmented reality learning
                    platform. The priority was to provide a scalable AR platform
                    that the faculty can use internally to design, build, and
                    launch their AR content.
                </TreeviewRole>
                <History caseStudy={caseStudy} />
                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default UniversityOfAlberta;
