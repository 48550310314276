import { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Paths } from "../context/RouterContext";
import { useIsMobile, useRouter } from "../hooks";
import logoImage from "../theme/white-logo.png";
import { Button, IconButton, Image, SVG, Text } from "./UI";
import icons from "../assets/icons";

const STopbar = styled.div`
    display: grid;
    place-items: center;
`;

const SContent = styled.div`
    width: ${(p) => p.theme.sizes.pageWidth.main};
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;

    ${(p) => p.theme.mobileQuery} {
        width: 100%;
        padding: 0 16px;
    }
`;

const SLogo = styled.div`
    height: 30px;
    width: 171px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;

const SNav = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 32px;
    align-items: center;
`;

const Topbar = () => {
    const router = useRouter();
    const location = useLocation();
    const isMobile = useIsMobile();
    const [menu, setMenu] = useState(true);

    useEffect(() => {
        if (menu) setMenu(false);
    }, [location.pathname]);

    function getTabs(): JSX.Element[] {
        return [
            { label: "Home", path: Paths.home },
            { label: "About Us", path: Paths.aboutUs },
            { label: "Services", path: Paths.services },
            { label: "Our Work", path: Paths.ourWork },
            // { label: "Why Us?", path: Paths.whyUs },
        ].map((tab) => (
            <Text
                key={tab.label}
                onClick={() => router.setPath(tab.path)}
                color="light"
                size="H7"
                oneline
                selected={tab.path === location.pathname}
            >
                {tab.label}
            </Text>
        ));
    }

    const navContent = getTabs();
    navContent.push(
        <Text
            key="blog"
            onClick={() =>
                (window.location.href = "https://treeview.studio/blog")
            }
            color="light"
            size="H7"
            oneline
        >
            Blog
        </Text>
    );

    navContent.push(
        <Button
            key="contact"
            small
            solid
            onClick={() => router.setPath(Paths.contact)}
        >
            Schedule a Meeting
        </Button>
    );

    return (
        <STopbar>
            <SContent>
                <SLogo onClick={() => router.setPath(Paths.home)}>
                    <Image image={logoImage} contain />
                </SLogo>
                <div />
                {isMobile ? (
                    <IconButton
                        onClick={() => setMenu((p) => !p)}
                        image={icons.menu}
                    />
                ) : (
                    <SNav>{navContent}</SNav>
                )}
            </SContent>

            {isMobile && <MobileMenu items={navContent} active={menu} />}
        </STopbar>
    );
};

const SMobileMenu = styled.div<IMobileMenuProps>`
    position: fixed;
    transition: 0.3s;
    width: 100vw;
    height: 100vh;
    top: 70px;
    opacity: ${(p) => (p.active ? 1 : 0)};
    right: ${(p) => (p.active ? "0" : "-100%")};

    background-color: ${(p) => p.theme.colors.dark};
    z-index: 100;

    background-image: radial-gradient(#2e2e2e 10%, transparent 10%);
    background-color: #292929;
    background-position: 0 0;
    background-size: 2rem 2rem;
`;

const SMobileNav = styled.div`
    display: grid;
    place-items: center;
`;

const SMobileNavItem = styled.div`
    height: 60px;
`;

interface IMobileMenuProps {
    items: JSX.Element[];
    active: boolean;
}

const MobileMenu: FC<IMobileMenuProps> = (props) => {
    return (
        <SMobileMenu {...props}>
            <SMobileNav>
                {props.items.map((item, i) => (
                    <SMobileNavItem key={i}>{item}</SMobileNavItem>
                ))}
            </SMobileNav>
        </SMobileMenu>
    );
};

export default Topbar;
