import React from "react";
import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Button, Image, Text } from "../../../components/UI";
import caseStudy from "../../../database/case-studies/inviewer";
import About from "../../case-study/About";
import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import DevelopmentServices from "../../case-study/DevelopmentServices";
import BecomeAnAlphaTester from "../../case-study/BecomeAnAlphaTester";
import Youtube from "../../../components/Youtube";
import ImageCarousel from "../../../components/ImageCarousel";

import downloadMeta from "../../../database/case-studies/inviewer/meta.png";
import downloadAVP from "../../../database/case-studies/inviewer/visionPro.png";
import { useRouter } from "../../../hooks";
import { Paths } from "../../../context/RouterContext";
import Clients from "../../case-study/Clients";
import Vimeo from "../../../components/Vimeo";

const SVideo = styled.div`
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;
    aspect-ratio: 16/9;
    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SAvailableOn = styled.div`
    display: grid;
    grid-gap: 29px;
    place-items: center;
    margin: 65px 0;
`;

const SAvailableOnButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;

    ${(p) => p.theme.mobileQuery} {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
    }
`;

const SAvailableOnButton = styled.div`
    width: 240px;
    height: 84px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.05);
    }
`;

const SContact = styled.div`
    display: grid;
    place-items: center;
    grid-gap: 37px;
`;

function Inviewer() {
    const router = useRouter();
    return (
        <Page title="Inviewer">
            <SSection>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />
                <SAvailableOn>
                    <Text color="light" size="H3" circularFont>
                        Available On
                    </Text>
                    <SAvailableOnButtons>
                        <SAvailableOnButton
                            onClick={() =>
                                window.open(
                                    "https://www.meta.com/en-gb/experiences/7611117132274033/"
                                )
                            }
                        >
                            <Image image={downloadMeta} contain />
                        </SAvailableOnButton>
                        <SAvailableOnButton
                            onClick={() =>
                                window.open(
                                    "https://apps.apple.com/us/app/inviewer/id6479699008"
                                )
                            }
                        >
                            <Image image={downloadAVP} contain />
                        </SAvailableOnButton>
                    </SAvailableOnButtons>
                </SAvailableOn>
                <SVideo>
                    <Vimeo id={caseStudy.videoReel ?? ""} />
                </SVideo>
                <div />
                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>
                <div />
                <About caseStudy={caseStudy} />
                <SContact>
                    <Text circularFont size="H2" color="light">
                        Contact Us to Build Your Own Spatial Simulations
                    </Text>
                    <Button onClick={() => router.setPath(Paths.contact)}>
                        Schedule a meeting now!
                    </Button>
                </SContact>
                <div />
                <SSection small>
                    <Clients
                        caseStudy={caseStudy}
                        title="Companies We Collaborate With"
                        expandWidth
                    />
                </SSection>
                <div />
                <div />

                <DevelopmentServices
                    caseStudy={caseStudy}
                    title="Platform Details"
                    titleBlue
                />
                <div />
                <BecomeAnAlphaTester
                    caseStudy={caseStudy}
                    title={
                        "🔌 Stay up to Date with the Latest Developments in Spatial Computing"
                    }
                />
                <div />
                <MoreCaseStudies caseStudy={caseStudy} />
                <div />
                <div />
            </SSection>
        </Page>
    );
}

export default Inviewer;
