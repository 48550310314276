import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import previewVideo from "../../../assets/videos/case-studies/university-of-alberta";
import imageIcons from "../../../assets/images/icons";

const UniversityOfAlberta: ICaseStudy = {
    id: "university-of-alberta",
    customPath: Paths.universityOfAlberta,

    preview: {
        title: "Education Technology",
        description:
            "The University of Alberta launched its augmented reality learning platform to provide students an immersive technology learning experience.",
        image: require("./preview.png"),
        video: previewVideo,
    },

    carousel: {
        images: [require("./carousel/a.png")],
    },

    clientTag: {
        logo: clientLogos.alberta,
        clientPicture: require("./client-picture.png"),
        clientName:
            "Bryan Rapati\nEngineering Department Administration. University of Alberta",
        clientComment:
            "Treeview did a really good job of constantly communicating with us and meeting our needs. They also did a really good job of collaborating with our students during the development and design, and I’m happy with how attentive they were.",
        clientCommentSummary:
            "They keep us informed along the way and are able to meet all of the deadlines that we give them.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#review-1965740",
        link: "https://www.ualberta.ca/index.html",
    },

    headingA: "Augmented Reality Engineering\nSimulation Platform",

    about: {
        firstDescription:
            "The University of Alberta is a Top 3 Canadian university located in Edmonton, Alberta, and home to 40,000 students in a wide variety of programs. The Faculty of Engineering launched its augmented reality learning platform to provide students an immersive technology learning experience.\n\nWith UAlberta AR students can browse a full catalog of the University's AR experiences to engage with the next generation of learning content.\n\nThe AR platform provides an educational tool that brings lab material to each student’s home, which has been vital to overcoming the lack of access to laboratory material in remote learning.",
    },

    treeviewRole: {
        description:
            "Treeview developed the Faculty of Engineering‘s augmented reality learning platform as a tool for remote learning. The University of Alberta AR platform was developed in collaboration with engineering students and faculty professors.\nTreeview’s role is to provide the AR infrastructure to allow engineering students to create and deploy their own AR experiences to the platform.",
        cards: [
            {
                targetValue: 1500,
                unit: "",
                description: "Student Users",
            },
            {
                targetValue: 10,
                unit: "+",
                description: "AR Courses",
            },
            {
                targetValue: 50,
                unit: "+",
                description: "AR Learning Experiences",
            },
        ],
    },

    developmentServices: {
        description:
            "Treeview has worked closely with the engineering faculty members and students, developing their augmented reality learning platform. The service is focused on providing a scalable AR platform that the Faculty can use internally to design, build and launch their AR content. The current service is focused on augmented reality with plans for virtual and mixed reality in the future.",
        image: {
            width: "826px",
            height: "113.4px",
            image: require("./development-services.png"),
        },
    },

    // videisoReel: "248496167",
    history: {
        cards: [
            {
                icon: imageIcons.bank,
                description:
                    "Struggling with access to lab experiments in remote learning, the University wanted to use AR/VR bring content to students' homes.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "Treeview lead the development of the University's augmented reality learning platform.",
            },
            {
                icon: imageIcons.tube,
                description:
                    "The AR Platform enabled faculty members to design and deploy AR simulations to their students remotely",
            },
        ],
    },
};

export default UniversityOfAlberta;
