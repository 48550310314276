import videos from "../assets/videos";
import ClientReviews from "../components/ClientReviews";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import CaseStudies from "./our-work/CaseStudies";

function Services() {
    return (
        <Page title="Our Work">
            <PageTitle
                title="Our Work"
                subTitle={
                    "We have a strong track record in the AR/VR industry. We prioritize your success. We value long-term professional relationships and focus on building strong, lasting partnerships. "
                }
                description={
                    "Learn how we have worked with our clients building successful spatial computing products and solutions."
                }
                video={videos.aboutUsHowWeDoBusiness}
            />
            <ClientReviews marginTop={0} startIndex={6} />
            <CaseStudies />
        </Page>
    );
}

export default Services;
