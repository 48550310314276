import clientLogos from "../../../assets/images/client-logos";
import { ICaseStudy } from "../../../global-state/caseStudies";

const Medtronic: ICaseStudy = {
    id: "medtronic",
    // customPath:

    preview: {
        title: "",
        description: "",
        // image: require("./preview.png"),
    },

    carousel: {
        images: [],
        imagePosition: "top",
    },

    clientTag: {
        logo: clientLogos.medtronic,
        clientPicture: require("./client-picture.png"),
        clientName: "Melvin Sim\nOperations &\nCustomer Experience",
        clientCommentSummary:
            "I appreciated the good attitude of the entire team and how accommodating they were.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#review-2209271",
        link: "https://www.medtronic.com/uy-es/index.html",
    },

    headingA: "",

    about: {
        firstDescription: "",
        secondDescription: "",
        titleCards: [
            {
                title: "",
                subTitle: "",
            },
            {
                title: "",
                subTitle: "",
            },
            {
                title: "",
                subTitle: "",
            },
        ],
    },

    developmentServices: {
        useTreeviewRole: true,
        description: "",
    },
};

export default Medtronic;
