import { FC } from "react";
import styled, { css } from "styled-components";
import { GradientBorder, Text } from "../UI";

const STitleCard = styled.div<{ subTitle?: string }>`
    display: grid;
    place-items: center;
    margin: auto;

    position: relative;
    height: 100%;

    ${(p) =>
        p.subTitle &&
        css`
            margin-top: -30px;
        `}
`;

const SLine = styled.div`
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    background: -webkit-linear-gradient(
        left,
        ${(p) => p.theme.gradients.secondary.left},
        ${(p) => p.theme.gradients.secondary.middle},
        ${(p) => p.theme.gradients.secondary.right}
    );
    width: 100px;
    max-width: 100px;
    height: 4px;
`;

const SSubTitle = styled.div`
    position: absolute;
    bottom: 10px;
`;

export interface ITitleCard {
    title: string;
    subTitle?: string;
}

const TitleCard: FC<ITitleCard> = ({ title = "", subTitle }) => {
    return (
        <GradientBorder
            style={{
                height: "252px",
                display: "grid",
                placeItems: "center",
            }}
        >
            <STitleCard subTitle={subTitle}>
                <Text size="H3" circularFont gradient center>
                    {title}
                </Text>
                <SLine />
                {subTitle && (
                    <SSubTitle>
                        <Text size="H5" circularFont color="light" center>
                            {subTitle}
                        </Text>
                    </SSubTitle>
                )}
            </STitleCard>
        </GradientBorder>
    );
};

export default TitleCard;
