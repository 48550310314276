import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Paths } from "../../context/RouterContext";
import { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile, useRouter } from "../../hooks";
import { GradientBorder, Image, Text } from "../UI";
import VideoView from "../VideoView";

const SCaseStudyCard = styled.div`
    display: grid;
    grid-gap: 16px;
`;

const SInteractive = styled.div<{ smaller?: boolean }>`
    cursor: pointer;
    transition: 0.3s;
    transform: scale(${(p) => (p.smaller ? 0.8 : 0.9)});
    :hover {
        transform: scale(${(p) => (p.smaller ? 0.9 : 1)});
    }
    height: 100%;
`;

interface IProps {
    caseStudy: ICaseStudy;
    small?: boolean;
}

export const CaseStudyCard: FC<IProps> = ({ caseStudy, small }) => {
    const isMobile = useIsMobile();
    const router = useRouter();
    const [isHover, setIsHover] = useState(true);

    useEffect(() => {
        setTimeout(() => setIsHover(false), 100);
    }, []);

    function clickHandler() {
        if (caseStudy.customPath) router.setPath(caseStudy.customPath);
        else {
            router.setParam("case-study", caseStudy.id);
            router.setPath(Paths.caseStudy, true);
        }
    }

    return (
        <SCaseStudyCard
            onClick={() => clickHandler()}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <GradientBorder
                style={{
                    height: small ? "203px" : isMobile ? "236px" : "380px",
                }}
            >
                <SInteractive smaller={caseStudy.preview.smaller}>
                    {caseStudy.preview.video && isHover ? (
                        <VideoView
                            video={caseStudy.preview.video}
                            noAnimation
                        />
                    ) : (
                        <Image image={caseStudy.preview.image} contain />
                    )}
                </SInteractive>
            </GradientBorder>
            <Text size={small ? "H4" : "H3"} color="light" circularFont oneline>
                {caseStudy.preview.title}
            </Text>
            {!small && (
                <Text size="H7" color="medium">
                    {caseStudy.preview.description}
                </Text>
            )}
            {!small && (
                <Text
                    size="H7"
                    color="light"
                    onClick={() => clickHandler()}
                    noBorder
                    oneline
                >
                    Read more {"->"}
                </Text>
            )}
        </SCaseStudyCard>
    );
};
