import { FC } from "react";
import styled from "styled-components";
import { useIsMobile } from "../../hooks";
import { GradientBorder, Image, Text } from "../UI";

const SBasicCard = styled.div`
    display: grid;
    grid-gap: 7px;
    margin: 24px 36px 36px 36px;
`;

const SIcon = styled.div<{ index: number }>`
    height: 26px;
    width: 26px;

    margin-bottom: 20px;

    border-radius: 50%;
    background: -webkit-linear-gradient(
        ${(p) => `${p.index * 45}deg`},
        ${(p) => p.theme.gradients.secondary.left},
        ${(p) => p.theme.gradients.secondary.middle},
        ${(p) => p.theme.gradients.secondary.right}
    );
`;

const SImage = styled.div`
    height: 40px;
    width: 40px;
    margin-bottom: 16px;
`;

interface IProps {
    height?: string;
    title?: string;
    description: string;
    image?: string;
    index?: number;
}

const BasicCard: FC<IProps> = ({
    height,
    title,
    description,
    image,
    index = 0,
}) => {
    const isMobile = useIsMobile();
    return (
        <GradientBorder style={{ height: height ?? "auto" }}>
            <SBasicCard>
                {image ? (
                    <SImage>
                        <Image image={image} contain />
                    </SImage>
                ) : (
                    <SIcon index={index} />
                )}
                {title && (
                    <Text
                        oneline={!isMobile}
                        color="light"
                        size="H4"
                        circularFont
                    >
                        {title}
                    </Text>
                )}
                <Text color="medium" size="H7">
                    {description}
                </Text>
            </SBasicCard>
        </GradientBorder>
    );
};

export default BasicCard;
