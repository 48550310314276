import { useState, useEffect, useContext } from "react";

import Router, { IRouter } from "../context/RouterContext";

export function useAwakeBool() {
    const [state, set] = useState(false);
    useEffect(() => {
        if (!state) set(true);
    }, [state]);
    return state;
}

export function useRouter() {
    const router = useContext(Router) as IRouter;
    return router;
}

export function useOutsideAlerter(ref: any, callback: () => void) {
    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>();

    function handleResize() {
        const { innerWidth: width } = window;
        setIsMobile(width <= 1200);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
};
