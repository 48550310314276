import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import previewVideo from "../../../assets/videos/case-studies/ulta-beauty";
import imageIcons from "../../../assets/images/icons";

const UltaBeauty: ICaseStudy = {
    id: "ulta-beauty",
    customPath: Paths.ultaBeauty,

    preview: {
        title: "In-store Brand Activation",
        description:
            "ULTA Beauty’s digital innovation team is deploying AR/VR experiences throughout their innovation platforms for brand and product partnership activations.",
        image: require("./preview.png"),
        video: previewVideo,
    },

    carousel: {
        images: [require("./carousel/a.png")],
    },

    clientTag: {
        logo: clientLogos.ulta,
        clientPicture: require("./client-picture.png"),
        clientName:
            "Javier Camacho\n Director, Digital Innovation New Experiences at ULTA Beauty",
        clientComment:
            "I'd absolutely work with them again; Treeview was always very open to adapting without putting the deadline at risk",
        clientCommentSummary:
            "Treeview is always very open to adapting without putting the deadline at risk.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#summary",
        link: "https://www.ulta.com/",
    },

    headingA: "Beauty Meets Technology\nwith Digital Innovation",

    about: {
        firstDescription:
            "Ulta Beauty is the largest beauty retailer in the United States. The company is the premier destination for cosmetics, fragrance, body, skin and haircare products. With over 25,000 products from approximately 500 beauty brands across all categories",
        cards: [
            {
                targetValue: 1,
                unit: "#",
                left: true,
                description: "USA Beauty Retailer",
            },
            {
                targetValue: 1200,
                unit: "+",
                description: "Retail Stores",
            },
            {
                targetValue: 25,
                unit: "k",
                description: "Products",
            },
        ],
    },

    headingB: "Leveraging AR/VR Experiences for\nCustomer and Brand Engagement",

    treeviewRole: {
        description:
            "Treeview works closely with ULTA Beauty’s digital innovation team providing development services to enable ULTA to leverage Unity’s advanced AR/VR tech stack and rendering pipeline. As part of the collaboration Treeview has developed ULTA’s Unity AR Framework enabling ULTA to dynamically deploy AR/VR experiences throughout their internal innovation platforms.",
        cards: [
            { targetValue: 3, unit: "+", description: "Years Collaborating" },
            { targetValue: 5, unit: "+", description: "Projects Developed" },
            { targetValue: 4, unit: "+", description: "AR/VR Technologies" },
        ],
    },

    developmentServices: {
        description:
            "✅ ULTA Unity AR Framework enabling\n\t✅ Table Top AR Experiences\n\t✅ Face AR Experiences\n\t✅ Image-Tracked AR Experiences\n✅ ULTA Unity AR Content Creation Onboarding Platform\n✅ Content Distribution Server\n✅ Content Libraries, including sample experiences, advanced unity materials, and reusable logical components.\n✅ Native iOS and Android integrations via Unity as a Library\n✅ Multiple brand activations leveraging the platform.",
    },

    history: {
        cards: [
            {
                icon: imageIcons.telescope,
                description:
                    "ULTA Beauty’s Digital Innovation Team wanted to expand their R&D capabilities into AR/VR technologies. ",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "Working with Treeview since 2020 developing multiple R&D projects, brand activations and in store immersive experiences.",
            },
            {
                icon: imageIcons.starEyes,
                description:
                    "Multiple successful AR/VR deployments and brand activations.R&D initiatives exploring thefuture of retail with spatial computing.",
            },
        ],
    },
};

export default UltaBeauty;
