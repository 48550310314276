import { FC } from "react";
import styled from "styled-components";
import Grid from "../../components/grids/Grid";
import { Button, Image, Text } from "../../components/UI";
import { useIsMobile } from "../../hooks";
import { SSection } from "../home/";
import behindTheScenesImages from "./behind-the-scenes-images";

const SBehindTheScenes = styled.div`
    display: grid;
    grid-gap: 30px;
    place-items: center;
`;

const BehindTheScenes: FC = () => {
    const isMobile = useIsMobile();

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : 96 }}>
            <div style={{ display: "flex", gap: 17, flexDirection: "column" }}>
                <Text gradient size="H2" oneline circularFont>
                    Behind the Scenes
                </Text>
                <Text color="light" size="H4" circularFont>
                    Cultivating a culture of ❤️ Passion, ⭐ Excellence, 🤝 Trust
                    and 🏄‍♂️ Freedom.
                </Text>
            </div>
            <SBehindTheScenes>
                <Grid
                    style={{
                        marginBottom: "40px",
                        marginTop: 50,
                        placeItems: "center",
                    }}
                >
                    {Object.values(behindTheScenesImages).map((image) => (
                        <Image
                            image={image}
                            contain
                            round
                            style={{ width: 323, height: 233 }}
                        />
                    ))}
                </Grid>
                <Text gradient size="H2" oneline circularFont>
                    We're Hiring
                </Text>
                <Text color="light" size="H4" oneline circularFont>
                    Join LATAM's #1 AR/VR Development Studio!
                </Text>
                <Button
                    onClick={() =>
                        (window.location.href = "https://treeview.notion.site/")
                    }
                >
                    More Info
                </Button>
            </SBehindTheScenes>
        </SSection>
    );
};

export default BehindTheScenes;
