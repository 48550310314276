import React, { FC, PropsWithChildren, useEffect } from "react";
import styled from "styled-components";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useLocation,
} from "react-router-dom";

import Topbar from "./components/Topbar";

import { Paths, RouterContext } from "./context/RouterContext";

// Pages.
import Home from "./pages/Home";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import WhyUs from "./pages/WhyUs";
import OurWork from "./pages/OurWork";
import Contact from "./pages/Contact";
import CaseStudy from "./pages/CaseStudy";
import Policy from "./pages/Policy";

import NotFound from "./pages/NotFound";
import { Text } from "./components/UI";
import NewtonsRoom from "./pages/case-studies/newtons-room";
import ThinkDigital from "./pages/case-studies/think-digital";
import XRMedicalSolutions from "./pages/case-studies/xr-medical-solutions";
import Engineus from "./pages/case-studies/engineus";
import UniversityOfAlberta from "./pages/case-studies/university-of-alberta";
import VisionwearX from "./pages/case-studies/visionwear-x";
import UltaBeauty from "./pages/case-studies/ulta";
import Ariot from "./pages/case-studies/ariot";
import Neom from "./pages/case-studies/neom";
import Pressenger from "./pages/case-studies/pressenger";
import Inviewer from "./pages/case-studies/inviewer";
import Microsoft from "./pages/case-studies/microsoft";

const SBackground = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-image: radial-gradient(#2e2e2e 10%, transparent 10%);
    background-color: #252525;
    background-position: 0 0;
    background-size: 2rem 2rem;
`;

const SLayout = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-rows: 70px auto;
`;

const Context: FC<PropsWithChildren> = ({ children }) => {
    return <RouterContext>{children}</RouterContext>;
};

const Layout: FC<{ error?: boolean }> = ({ error }) => {
    return (
        <Context>
            <SLayout>
                <Topbar />
                <React.Fragment>
                    {error ? <NotFound /> : <Outlet />}
                </React.Fragment>
            </SLayout>
        </Context>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        errorElement: <Layout error />,
        children: [
            {
                path: Paths.home,
                element: <Home />,
            },
            {
                path: Paths.services,
                element: <Services />,
            },
            {
                path: Paths.whyUs,
                element: <WhyUs />,
            },
            {
                path: Paths.aboutUs,
                element: <AboutUs />,
            },
            {
                path: Paths.ourWork,
                element: <OurWork />,
            },
            {
                path: Paths.contact,
                element: <Contact />,
            },
            {
                path: Paths.caseStudy,
                element: <CaseStudy />,
            },
            {
                path: Paths.policy,
                element: <Policy />,
            },
            {
                path: Paths.designRush,
                element: (
                    <Text
                        style={{
                            padding: "20px",
                            height: "min-content",
                            width: "min-content",
                        }}
                        oneline
                        color="light"
                        onClick={() =>
                            window.open(
                                "https://www.designrush.com/agency/profile/treeview"
                            )
                        }
                    >
                        https://www.designrush.com/agency/profile/treeview
                    </Text>
                ),
            },
            {
                path: Paths.blog,
                element: <div />,
            },
            // Custom Case Studies.
            {
                path: Paths.newtonsRoom,
                element: <NewtonsRoom />,
            },
            {
                path: Paths.inviewer,
                element: <Inviewer />,
            },
            {
                path: Paths.thinkDigital,
                element: <ThinkDigital />,
            },
            {
                path: Paths.xrMedicalSolutions,
                element: <XRMedicalSolutions />,
            },
            {
                path: Paths.engineus,
                element: <Engineus />,
            },
            {
                path: Paths.universityOfAlberta,
                element: <UniversityOfAlberta />,
            },
            {
                path: Paths.visionwearX,
                element: <VisionwearX />,
            },
            {
                path: Paths.ultaBeauty,
                element: <UltaBeauty />,
            },
            {
                path: Paths.ariot,
                element: <Ariot />,
            },
            {
                path: Paths.neom,
                element: <Neom />,
            },
            {
                path: Paths.pressenger,
                element: <Pressenger />,
            },
            {
                path: Paths.microsoft,
                element: <Microsoft />,
            },
        ],
    },
]);

function App() {
    return (
        <SBackground>
            <RouterProvider router={router} />
        </SBackground>
    );
}

export default App;
