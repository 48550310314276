import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import videoPreview from "../../../assets/videos/case-studies/neom";
import imageIcons from "../../../assets/images/icons";

const Neom: ICaseStudy = {
    id: "neom",
    customPath: Paths.neom,
    // hide: true,

    preview: {
        title: "Sports Performance Enhancement",
        description:
            "NEOM is a new destination being created in the northwest of Saudi Arabia. It aims to be a land of the future, where the best ideas come together to create solutions for humanity's key challenges.",
        image: require("./preview.png"),
        video: videoPreview,
    },

    carousel: {
        images: [require("./carousel/a.png")],
        imagePosition: "top",
    },

    clientTag: {
        logo: clientLogos.neom,
        clientPicture: require("./client-picture.png"),
        clientName: "Margarita Karimova\nCognitive Cities Manager at NEOM",
        clientComment:
            "Treeview was highly responsive, motivated, and engaged throughout the project. They always met our needs and went above and beyond for our project. Treeview worked independently, and we feel like we got a lot of value out of the engagement.",
        clientCommentSummary:
            "They provide a lot of value for the money you spend.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#review-2255418",
        link: "https://www.neom.com/en-us",
    },

    headingA: "Mixed Reality Ski\nPerformance Enhancement",

    about: {
        firstDescription:
            "NEOM is a new destination being created in the northwest of Saudi Arabia. It aims to be a land of the future, where the best ideas, most promising startups, established companies, future industries, and top talents come together to create solutions for humanity's key challenges.\n\nOne of NEOM’s regions is Trojena, which is located in the mountains of NEOM. Trojena will offer year-round outdoor skiing and adventure sports, with completion scheduled for 2026. The facilities will include 36 km of outdoor ski slopes.",
        secondDescription:
            "Trojena’s vision is to seamlessly integrate nature and technology to cater to the interests and needs of modern travelers. Trojena aims to be an iconic, world-class destination that blends natural and developed landscapes, offering unique human-centric experiences for both residents and visitors alike.\n\nAs part of this vision, NEOM is looking to integrate the latest technological innovations into the location's experience. NEOM contacted Treeview to build a proof-of-concept mixed-reality ski experience for Magic Leap 2. This will allow stakeholders to explore the value of mixed reality and assess the feasibility of full deployment of this technology on the slopes of Trojena.",
        imageCards: [
            {
                image: require("./about-images/a.png"),
                caption: "Image Source: NEOM",
            },
            {
                image: require("./about-images/b.png"),
                caption: "Image Source: NEOM",
            },
        ],
    },

    developmentServices: {
        useTreeviewRole: true,
        description:
            "Treeview collaborated with NEOM’s team to develop a proof of concept mixed reality application for Magic Leap 2 and has tested the software on ski slopes, producing output analysis reports of Magic Leap 2’s performance on the ski sessions and overall user experience for this use-case.\n\nThe proof of concept serves as a key technology and product understanding for the feasibility of a full deployment of the latest mixed reality technology at NEOM.",
        services: [
            {
                title: "🏎️  Features",
                description: "",
                items: [
                    "Mixed Reality Slalom Route Mapping",
                    "Speed Visualizer",
                    "Altitude Visualizer",
                    "Calorie Estimation",
                    "Session Summary",
                ],
            },
            {
                title: "🎯 Project Objectives",
                description: "",
                items: [
                    "Develop a fully operational proof-of-concept Magic Leap 2 application for on-site testing.",
                    "Confirm and evaluate high-speed outdoor tracking quality of the Magic Leap 2.",
                    "Develop and validate the core features to confirm the value proposition of the project.",
                    "Test the software in the field to gather user feedback, which will be used to develop a future version of the software.",
                    "Test and review motion sickness, overall user experience and battery longevity in the field.",
                ],
            },
        ],
    },

    videoReel: "877992580?h=c2935b7695",
    history: {
        cards: [
            {
                icon: imageIcons.lightBlub,
                description:
                    "NEOM approached us with the need to explore and research use-case applications of the latest mixed reality technology.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "We have collaborated with NEOM's team to develop proof-of-concept deployments to better understand the technology opportunities in different use cases.",
            },
            {
                icon: imageIcons.scientist,
                description:
                    "Successfully developed, deployed, and field-tested different use cases of mixed reality to better evaluate investments in the technology for the future region of NEOM.",
            },
        ],
    },
};

export default Neom;
