import { FC } from "react";
import { SSection } from "./";
import { OffsetGrid } from "../../components/grids/OffsetGrid";
import { Button, Text } from "../../components/UI";
import BasicCard from "../../components/cards/BasicCard";
import imageIcons from "../../assets/images/icons";
import { useIsMobile, useRouter } from "../../hooks";
import { Paths } from "../../context/RouterContext";

const WhyChooseUs: FC = () => {
    const router = useRouter();
    const items = [
        {
            description:
                "Our full-service team specializes in delivering customized, world-class AR/VR software services, tailored to meet your unique company needs with expert execution.",
            image: imageIcons.work,
        },
        {
            description:
                "Our boutique size enables swift pivoting and adaptation to your unique objectives and needs.",
            image: imageIcons.car,
        },
        {
            description:
                "Prioritizing communication, we ensure you're continuously updated through real time communication and weekly meetings.",
            image: imageIcons.wifi,
        },
        {
            description:
                "We build your own digital assets. The product remains your intellectual property. We grant you full control over the process.",
            image: imageIcons.gift,
        },
    ].map((i) => (
        <BasicCard key={i.image} description={i.description} image={i.image} />
    ));

    return (
        <SSection>
            <Text gradient size="H2" oneline circularFont>
                Why Choose Us?
            </Text>
            <OffsetGrid items={items} />
            <div
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    marginTop: 50,
                }}
            >
                <Button onClick={() => router.setPath(Paths.services)}>
                    Discover our Services
                </Button>
            </div>
        </SSection>
    );
};

export default WhyChooseUs;
