import { FC } from "react";
import styled from "styled-components";
import { SSection } from "./";
import Grid from "../../components/grids/Grid";
import { Button, Text } from "../../components/UI";
import AboutUsCard from "./AboutUsCard";
import { useIsMobile, useRouter } from "../../hooks";
import { Paths } from "../../context/RouterContext";
import videos from "../../assets/videos";
import DATABASE from "../../database";

const SOurPlan = styled.div`
    display: grid;
    grid-gap: 30px;
    place-items: center;
    margin-bottom: 160px;
`;

const OurPlan: FC = () => {
    const router = useRouter();
    const isMobile = useIsMobile();

    const items = [
        {
            title: "Step 1: Discovery",
            descriptionElement: (
                <Text color="medium" size="H7" center>
                    <b>We schedule a discovery meeting.</b> If we are a good
                    fit, we will enter a scoping phase to learn everything about
                    your company.
                </Text>
            ),
            video: videos.aboutUsWhereWeAreGoing,
        },
        {
            title: "Step 2: Scoping",
            descriptionElement: (
                <Text color="medium" size="H7" center>
                    Over the next two weeks, we will collaborate on a{" "}
                    <b>pilot project plan</b> with the{" "}
                    <b>
                        goal of establishing your company as a leading innovator
                        in your industry.
                    </b>
                </Text>
            ),
            video: videos.ourWorkHero,
        },
        {
            title: "Step 3: Execution",
            descriptionElement: (
                <Text color="medium" size="H7" center>
                    <b>We begin with a 6-12 week pilot project,</b> which is low
                    risk and effort, to <b>establish trust</b>. After this first
                    project, we embark on a long-term partnership to{" "}
                    <b>guide your way into the future!</b>
                </Text>
            ),
            video: videos.whyUsHero,
        },
    ].map((i) => (
        <AboutUsCard
            key={i.title}
            title={i.title}
            descriptionElement={i.descriptionElement}
            video={i.video}
        />
    ));

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : 0 }}>
            <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
                <Text gradient size="H2" oneline circularFont>
                    How We Work
                </Text>
            </div>
            <SOurPlan>
                <Grid style={{ marginBottom: "40px" }}>{items}</Grid>
                <Text color="light" size="H4" circularFont center>
                    We rocket your company into the future with the latest
                    <br />
                    spatial computing technologies, helping you avoid being left
                    behind.
                </Text>
                <Button onClick={() => router.setPath(Paths.contact)}>
                    Schedule our first meeting now!
                </Button>
            </SOurPlan>
        </SSection>
    );
};

export default OurPlan;
