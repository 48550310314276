import { title } from "process";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import preview from "./preview.png";
import previewVideo from "../../../assets/videos/case-studies/inviewer";

const Inviewer: ICaseStudy = {
    id: "inviewer",
    customPath: Paths.inviewer,
    videoReel: "929114048?h=a55334f411",
    // hide: true,
    preview: {
        title: "STEM Simulations",
        description:
            "Inviewer is a Spatial STEM Simulation Library that delivers high-quality, real-time simulations for immersive learning and professional development.",
        image: preview,
        video: previewVideo,
        smaller: true,
    },
    carousel: {
        images: [require("./carousel/a.png")],
        // imagePosition: "center",
    },
    clientTag: {
        logo: "",
        clientPicture: "",
        clientName: "",
        clientComment: "",
        link: undefined,
        clutch: undefined,
    },
    headingA: "Inviewer\nSpatial STEM Simulations",
    about: {
        firstDescription:
            "Inviewer is a Spatial STEM Simulation Library that delivers high-quality, real-time simulations for immersive learning and professional development.\n\nInviewer's library content offers a range of experiences in science, technology, engineering, and mathematics to provide interactive materials enabled via Mixed Reality technology, unlocking a hands-on, engaging experience on a wide range of subjects.",
        imageCards: [
            {
                image: require("./about-images/a.png"),
                caption: "Chemistry Simulation",
            },
            {
                image: require("./about-images/b.png"),
                caption: "Human Gestation Simulation",
            },
            {
                image: require("./about-images/c.png"),
                caption: "Multistage Simulations",
            },
            {
                image: require("./about-images/d.png"),
                caption: "Simulation Catalog",
            },
        ],
    },
    clients: [
        {
            image: require("./clients/alberta.png"),
            link: "https://treeview.studio/university-of-alberta",
        },
        {
            image: require("./clients/adelaide.png"),
            link: "https://www.adelaide.edu.au/",
        },
        {
            image: require("./clients/medtronic.png"),
            link: "https://asiapac.medtronic.com/xp-en/c/medtronic-customer-experience-center.html",
        },
        {
            image: require("./clients/daiichi-sankyo.png"),
            link: "https://treeview.studio/blog/treeview-at-dspace-innovation-day/",
        },
    ],
    developmentServices: {
        description: "",
        services: [
            {
                title: "🏎️  Platform Features",
                description:
                    "Inviewer is built from the ground up for the latest Mixed Reality technologies, offering full compatibility across all platforms.",
                items: [
                    "Meta Quest, built on Meta Presence Platform SDK.",
                    "Apple Vision Pro, built on VisionOS.",
                    "Magic Leap 2 - Coming soon.",
                    "Lenovo VRX - Coming soon.",
                ],
            },
            {
                title: "💎 Platform Use Cases",
                description:
                    "Inviewer is a versatile spatial communication platform that enables highly customized simulations. High value use cases include:",
                items: [
                    "📚 Interactive Education Material",
                    "🏥 Healthcare Communication",
                    "‍🏭 Training & Knowledge Transfer",
                    "⚙️ Engineering Visualization",
                    "🎁 Product Presentations",
                ],
                noTick: true,
            },
        ],
    },
    becomeAnAlphaTester: {
        description:
            "Subscribe to stay up to date with the latest developments and updates. Unsubscribe at any time.",
        path: "contact/inviewer/subscribers",
    },
};

export default Inviewer;
