import { logEvent } from "firebase/analytics";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { analytics } from "../firebase";

const Router = React.createContext({});

export enum Paths {
    home = "/",
    services = "/services",
    whyUs = "/why-us",
    aboutUs = "/about-us",
    ourWork = "/our-work",
    contact = "/contact",
    caseStudy = "/case-study",

    policy = "/privacy",

    blog = "/blog",

    designRush = "/designrush",

    // Custom case studies:
    newtonsRoom = "/newtons-room",
    inviewer = "/inviewer",
    thinkDigital = "/think-digital",
    xrMedicalSolutions = "/xr-medical-solutions",
    engineus = "/engineus",
    universityOfAlberta = "/university-of-alberta",
    visionwearX = "/visionwear-x",
    ultaBeauty = "/ulta-beauty",
    microsoft = "/digitaltwin",
    ariot = "/ariot",
    neom = "/neom",
    pressenger = "/pressenger",
}

export type TParams = "case-study";

export interface IRouter {
    setPath(path: Paths, keepParams?: boolean): void; // Set new url path. (Can keep params or reset them)
    setParam(param: TParams, value?: string): void;
    getParam(param: TParams): string;
    back(): void; // Same as browser back.
}

export const RouterContext: FC<PropsWithChildren> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [prevState, setPrevState] = useState({
        pathname: "",
        search: "",
    });

    useEffect(() => {
        if (
            prevState.pathname != location.pathname ||
            prevState.search != location.search
        )
            window.analytics.page();

        setPrevState({
            pathname: location.pathname,
            search: location.search,
        });

        logEvent(analytics, "page_view");
    }, [location.pathname, location.search]);

    const value: IRouter = {
        setPath(path, keepParams = false) {
            let pathWithParams: string = path;
            if (keepParams) pathWithParams += window.location.search;
            navigate(pathWithParams);
        },
        setParam(param, value) {
            try {
                const params = new URLSearchParams(location.search);
                if (value) params.set(param, value.toString());
                else params.delete(param);
                navigate(`${location.pathname}?${params.toString()}`);
            } catch {}
        },
        getParam(param) {
            try {
                return new URLSearchParams(location.search).get(param) ?? "";
            } catch {
                return "";
            }
        },
        back() {
            navigate(-1);
        },
    };

    return <Router.Provider value={value}>{props.children}</Router.Provider>;
};

export default Router;
