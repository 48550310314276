import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const SContainer = styled.div<IProps>`
    position: relative;
    height: ${(p) => p.height ?? "641px"};
    ${(p) => p.theme.mobileQuery} {
        height: ${(p) => p.height ?? "293px"};
        max-width: 93vw;
    }
`;

const SContainerChild = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;

    left: 50%;
    transform: translateX(-50%);
`;

interface IProps extends PropsWithChildren {
    height?: string;
    style?: React.CSSProperties;
}

const FullWidthContainer: FC<IProps> = (props) => {
    return (
        <SContainer {...props}>
            <SContainerChild>{props.children}</SContainerChild>
        </SContainer>
    );
};

export default FullWidthContainer;
