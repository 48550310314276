import { FC } from "react";
import styled from "styled-components";
import { SSection } from "../home/";
import { Button, Text } from "../../components/UI";
import Grid from "../../components/grids/Grid";
import TeamMember from "../../components/cards/TeamMember";
import useTeamMembers from "../../global-state/teamMembers";
import { useIsMobile } from "../../hooks";

const SHiring = styled.div`
    display: grid;
    place-items: center;
    grid-gap: 20px;
`;

const OurTeam: FC = () => {
    const isMobile = useIsMobile();
    const teamMembers = useTeamMembers();
    const members = Object.values(teamMembers.teamMembers).map((member) => (
        <TeamMember member={member} key={member.name} />
    ));

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : "100px" }}>
            <div style={{ display: "grid", gridGap: "10px" }}>
                <Text gradient size="H2" oneline circularFont>
                    Our Team
                </Text>
                <Text color="light" size="H4" oneline circularFont>
                    Meet our world-class development team.
                </Text>
                <div />
            </div>
            <Grid
                small
                columns={isMobile ? 2 : 4}
                style={{ placeItems: "center" }}
            >
                {members}
            </Grid>
        </SSection>
    );
};

export default OurTeam;
