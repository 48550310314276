import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, InputField, Text } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import { addContact } from "../../api";

const SBecomeAnAlphaTester = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 36px;
    margin-top: 60px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 93vw;
        margin-left: 0;
    }
`;

const SInput = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 16px;
`;

const BecomeAnAlphaTester: FC<{ caseStudy: ICaseStudy; title?: any }> = ({
    caseStudy,
    title,
}) => {
    const [state, set] = useState<{ email: string; feedback?: string }>({
        email: "",
    });
    const [loading, setLoading] = useState(false);

    async function signUpHandler() {
        if (state.email === "") {
            set((p) => ({ ...p, feedback: "Enter email." }));
            return;
        }
        if (!caseStudy.becomeAnAlphaTester?.path) return;

        const emailRegex =
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const emailIsValid = emailRegex.test(state.email);

        if (!emailIsValid) {
            set((p) => ({ ...p, feedback: "Enter a valid email." }));
            return;
        }

        setLoading(true);
        set((p) => ({ ...p, feedback: "Sending..." }));

        await addContact(
            caseStudy.becomeAnAlphaTester.path,
            state.email,
            state.email
        );

        set((p) => ({ ...p, email: "", feedback: "Subscribed." }));
        setLoading(false);
    }

    return (
        <SBecomeAnAlphaTester>
            <Text circularFont color="light" size="H3">
                {title ??
                    "🔌 Stay up to Date with the Latest Developments in Mixed Reality"}
            </Text>
            <Text size="H6" color="medium">
                {caseStudy.becomeAnAlphaTester?.description}
            </Text>

            <SInput>
                <InputField
                    value={state.email}
                    onChange={(email) => set((p) => ({ ...p, email }))}
                    title="Email Address"
                    placeholder="Your Email"
                    secondGradient
                    onEnter={() => signUpHandler()}
                />
                <Button
                    onClick={() => signUpHandler()}
                    small
                    style={{
                        marginTop: "auto",
                        width: 186,
                        height: 50,
                        textAlign: "center",
                    }}
                >
                    Sign Up
                </Button>
            </SInput>
            <Text color="light" oneline size="H5" gradientB>
                {loading ? "Sending..." : state.feedback}
            </Text>
        </SBecomeAnAlphaTester>
    );
};

export default BecomeAnAlphaTester;
