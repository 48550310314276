import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Image, Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/visionwear-x";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import SpecialSectionVisionwearX from "../../case-study/special-sections/Visionwearx";
import History from "../../case-study/History";

function VisionwearX() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />
                <ClientTag caseStudy={caseStudy} />
                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>
                <About caseStudy={caseStudy}>
                    <a href="https://visionwear-x.com/" target="_blank">
                        VisionWEARx
                    </a>{" "}
                    provides virtual reality solutions for{" "}
                    <b>diagnosing and treating learning disabilities.</b> With
                    the incorporation of a fully immersive testing environment
                    leveraging quantitative data, this solution provides a
                    groundbreaking medical breakthrough that is changing lives.
                    <br />
                    <br />
                    VisionWEARx is a learning disorder diagnosis platform that
                    allows professionals to screen, diagnose and treat learning
                    disorders. The platform enables patients to conduct the
                    tests in virtual reality while the professionals run these
                    tests via a companion controller mobile application.
                    <Video
                        id="904035487?h=9139c3905e"
                        style={{ margin: "32px 0" }}
                    />
                    VisionWEARx leverages virtual reality to personalize and
                    monitor the environment & user experience when taking a
                    learning disorder test. The software allows the
                    personalization of the following:
                    <br />
                    <br />✅ The virtual environment where the user executes the
                    tests.
                    <br />✅ Spatial audio stimulates focus and other mental
                    attributes.
                    <br />✅ Test fonts, sizes, and colors.
                    <br />
                    <br />
                    The platform is compatible with multiple learning disorders,
                    including dyslexia, dyscalculia, and irlen syndrome.
                </About>
                <SSection gridGap="16px" small>
                    <Video
                        id="904038493?h=67f2a5616a"
                        title="📖  Dyslexia Screening Test - Decoding"
                        whiteTitle
                    />
                    <div />
                    <div />
                    <Video
                        id="905552185?h=5d776cf5ce"
                        title="✍️   Dyslexia Screening Test - Eidetic Encoding "
                        whiteTitle
                    />
                </SSection>
                <SpecialSectionVisionwearX caseStudy={caseStudy} />

                <TreeviewRole caseStudy={caseStudy}>
                    We worked with the VisionWEARx team and subject matter
                    experts like SGDA to redesign traditional paper-based
                    learning disorder tests into the first-of-its-kind native
                    virtual reality diagnosis platform.
                    <br />
                    <br />
                    We provides a full suite of development services, including
                    AR/VR development, product design, and project management.
                    We are also leading VisionWEARx's R&D initiatives to provide
                    grown breaking technologies applying AR/VR technologies to
                    other learning disabilities.
                </TreeviewRole>

                <History caseStudy={caseStudy} />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default VisionwearX;
