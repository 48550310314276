import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import thinkDigitalClientLogos from "./clients";
import ThinkDigitalPress from "./press";
import previewVideo from "../../../assets/videos/case-studies/think-digital";
import imageIcons from "../../../assets/images/icons";

const ThinkDigital: ICaseStudy = {
    id: "think-digital",
    customPath: Paths.thinkDigital,

    preview: {
        title: "Agriculture Technology",
        description:
            "Think Digital is the leading AR/VR AGTech startup in Australia providing a range of creative immersive technology services and products.",
        image: require("./preview.png"),
        video: previewVideo,
    },

    carousel: {
        images: [
            require("./carousel/c.jpg"),
            require("./carousel/a.jpg"),
            require("./carousel/b_new.png"),
            require("./carousel/d.jpg"),
            require("./carousel/e.jpg"),
            require("./carousel/f.jpg"),
            require("./carousel/g.jpg"),
            require("./carousel/h.jpg"),
            require("./carousel/i_new.png"),
        ],
    },

    clientTag: {
        logo: clientLogos.thinkDigital,
        clientPicture: require("./client-picture.png"),
        clientName: "Tim Gentle\nFounder",
        clientComment:
            "We wouldn’t be a leader in agricultural immersive tech without Treeview. They have greatly contributed to our success.",
        clientCommentSummary:
            "We wouldn't be a leader in agricultural immersive tech without them. They have greatly contributed to our success.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#review-1691906",
        link: "https://think.digital/",
    },

    headingA:
        "Leading AR/VR Startup in the Australian\nEducation and Agricultural Sectors.",

    about: {
        firstDescription: "FROM HTML",
        cards: [
            {
                targetValue: 1,
                unit: "#",
                left: true,
                description: "AR/VR AGTech\nStartup in Australia",
            },
            {
                targetValue: 25,
                unit: "+",
                description: "B2B Clients Served",
            },
        ],
    },

    headingB:
        "Successful long-term development partnership building\nAustralia's leading AR/VR agriculture startup.",

    treeviewRole: {
        description:
            "Treeview has worked with Think Digital since 2017, helping build one of the most important AR/VR tech companies in agriculture in Australia.\nHaving worked on dozens of high-profile AR/VR projects and internal platforms, Treeview has been Think Digital's key technological partner on the journey from a vision to a sustainable AR/VR business.",
        cards: [
            {
                targetValue: 6,
                unit: "+",
                description: "Years Collaborating",
            },
            {
                targetValue: 50,
                unit: "+",
                description: "AR/VR Products Shipped",
            },
            {
                targetValue: 10,
                unit: "+",
                description: "AR/VR Technologies",
            },
        ],
    },

    developmentServices: {
        description:
            "Treeview has provided software development, design, and project management services during the several years of collaborations. We have worked with dozens of different AR/VR technologies on multiple different projects.",
        services: [
            {
                title: "Virtual Reality",
                description:
                    "Think Digital's VR journey has traveled from the early days of Cardboard to the latest Oculus Quest 2 headsets. The VR headsets we've developed for include:",
                items: [
                    "Pico Neo 3",
                    "Vive Focus 3",
                    "Oculus Rift",
                    "Oculus Quest",
                    "Oculus GO",
                    "GearVR",
                    "Google Cardboard",
                    "HTC Vive",
                ],
            },
            {
                title: "Augmented Reality",
                description:
                    "With the launch of Apple ARKit in 2017, ThinkDigital has journeyed AR technologies offering AR Solutions to their clients. The AR technologies we've worked on include:",
                items: [
                    "Hololens",
                    "Magic Leap",
                    "Apple ARKit",
                    "Google ARCore",
                    "ZSpace",
                ],
            },
            {
                title: "Web Development",
                description:
                    "To enable ThinkDigital to access the whole market of non-AR/VR users, we have developed native web applications with the following technologies.",
                items: ["ReactJS", "ThreeJS"],
            },
            {
                title: "Backend Development",
                description:
                    "Backend development has been vital to managing dynamic content, user management, and multiplayer experience.",
                items: ["Firebase", "Google Cloud", "Photon Network"],
            },
        ],
    },

    clients: [
        {
            image: thinkDigitalClientLogos.woolworths,
            link: "https://www.woolworths.com.au/",
        },
        {
            image: thinkDigitalClientLogos.ampc,
            link: "https://www.ampc.com.au/",
        },
        {
            image: thinkDigitalClientLogos.coalsLight,
            link: "https://aglifesciences.tamu.edu/",
        },
        {
            image: thinkDigitalClientLogos.connectNGrow,
            link: "https://connectngrow.edu.au/",
        },
        {
            image: thinkDigitalClientLogos.dpir,
            link: "https://www.pir.sa.gov.au/",
        },
        {
            image: thinkDigitalClientLogos.elders,
            link: "https://elders.com.au/",
        },
        {
            image: thinkDigitalClientLogos.forestLearning,
            link: "https://www.mla.com.au/",
        },
        {
            image: thinkDigitalClientLogos.nsw,
            link: "https://www.dpi.nsw.gov.au/",
        },
        {
            image: thinkDigitalClientLogos.optus,
            link: "https://www.optus.com.au/",
        },
        {
            image: thinkDigitalClientLogos.uoa,
            link: "https://www.pir.sa.gov.au/",
        },
        {
            image: thinkDigitalClientLogos.theshowbanksa,
            link: "https://www.mla.com.au/",
        },
        {
            image: thinkDigitalClientLogos.australianEggs,
            link: "https://www.australianeggs.org.au/r",
        },
        {
            image: thinkDigitalClientLogos.mla,
            link: "https://www.mla.com.au/",
        },
    ],

    // videoReel: "248496167",

    press: [
        {
            title: "Educating farmers about emergency and animal diseases with AR.",
            description: "Microsoft Australia News Centre",
            thumbnail: ThinkDigitalPress.a,
            link: "https://news.microsoft.com/en-au/features/educating-farmers-about-emergency-animal-diseases-with-augmented-reality/",
        },
        {
            title: "It’s a wrap for MLA’s virtual reality roadshow",
            description: "MLA Corporate",
            thumbnail: ThinkDigitalPress.b,
            link: "https://www.mla.com.au/news-and-events/industry-news/Sharing-red-meats-story-with-15000-consumers-in-three-months/",
        },
        {
            title: "Think Digital brings Microsoft AR to SA farmers",
            description: "ARN",
            thumbnail: ThinkDigitalPress.c,
            link: "https://www.arnnet.com.au/article/702824/think-digital-brings-microsoft-ar-sa-farmers/",
        },
        {
            title: "Augmented Reality Sheep Emergency Animal Disease tool launch",
            description: "Think Digital Studios",
            thumbnail: ThinkDigitalPress.d,
            link: "https://think.digital/2022/08/augmented-reality-sheep-emergency-animal-disease-tool-launch/",
        },
        {
            title: "Virtual reality teaching students cattle handling skills safely",
            description:
                "Faculty of Sciences, Engineering and Technology | University of Adelaide",
            thumbnail: ThinkDigitalPress.f,
            link: "https://theshow.com.au/",
        },
        {
            title: "Using XR to solve problems in the Meat Processing Industry",
            description: "Think Digital Studios",
            thumbnail: ThinkDigitalPress.g,
            link: "https://think.digital/2022/10/using-xr-to-solve-problems-in-the-meat-processing-industry/",
        },
        {
            title: "Augmented Reality in Agriculture at Ag Innovation Expo 2022",
            description: "Think Digital Studios",
            thumbnail: ThinkDigitalPress.h,
            link: "https://think.digital/2022/05/augmented-reality-in-agriculture-innovation-expo-2022/",
        },
        {
            title: "Virtual Reality Large Animal Handling Simulator",
            description: "Think Digital Studios",
            thumbnail: ThinkDigitalPress.i,
            link: "https://think.digital/2020/04/virtual-reality-large-animal-handling-simulator/",
        },
    ],

    history: {
        cards: [
            {
                icon: imageIcons.plant,
                description:
                    "Founded in 2017 with a vision of using AR/VR to solve challenges in the Agriculture Industry.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "Ongoing collaboration for 6+ years. Shipped over 50+ AR/VR products. ",
            },
            {
                icon: imageIcons.cup,
                description:
                    "Leading AR/VR Startup in Australia's Agriculture industry. Servicing enterprise and government.",
            },
        ],
    },
};

export default ThinkDigital;
