import { title } from "process";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import preview from "./preview.png";
import previewVideo from "../../../assets/videos/case-studies/newtons-room";
import { Text } from "../../../components/UI";

import blogImages from "./blog";

const NewtonsRoom: ICaseStudy = {
    id: "newtons-room",
    customPath: Paths.newtonsRoom,
    videoReel: "AuoIvhqE9R4",
    preview: {
        title: "Experiential Learning",
        description:
            "Newton’s Room is an educational mixed reality app where the user applies the theory of Newton’s laws of physics to solve challenging puzzles.",
        image: preview,
        video: previewVideo,
    },
    carousel: {
        images: [],
    },
    clientTag: {
        logo: "",
        clientPicture: "",
        clientName: "",
        clientComment: "",
        link: undefined,
        clutch: undefined,
    },
    headingA: "",
    about: {
        firstDescription:
            "Newton's Room is a mixed reality physics education app where users apply Newton's laws of physics to solve challenging puzzles that adapt and respond to their physical environment.\n\nThe completion of each puzzle requires not only a conceptual understanding of these principles, but also the ability to creatively apply them in diverse scenarios.\n\nThe interactive and problem solving nature of the app encourages experiential learning, fostering a deep and intuitive understanding of Newtonian physics.",
        titleCards: [
            {
                title: "Mixed Reality",
            },
            {
                title: "Experiental\nLearning",
            },
        ],
    },
    developmentServices: {
        description: `Newton's Room is a mixed reality physics education app where users apply Newton's laws of physics to solve challenging puzzles that adapt and respond to their physical environment.

The completion of each puzzle requires not only a conceptual understanding of these principles, but also the ability to creatively apply them in diverse scenarios.

The interactive and problem solving nature of the app encourages experiential learning, fostering a deep and intuitive understanding of Newtonian physics.
        `,
        services: [
            {
                title: "🏎️  Core Features",
                description:
                    "Newton's Room is a mixed reality application that explores the potential of experiential learning in making physics education fun and engaging. The application features:",
                items: [
                    "5 mixed reality ambient environments",
                    "5 projectile mass sizes",
                    "Over 30 challenging education puzzles",
                    "Sandbox mode to design your own level",
                ],
            },
            {
                title: "🥽  Mixed Reality",
                description: (
                    <>
                        Newton's Room is built from the ground up on Meta's{" "}
                        <a
                            href="https://developer.oculus.com/presence-platform/"
                            target="_blank"
                        >
                            Presence Platform SDK
                        </a>{" "}
                        leveraging the latest and most complete library for
                        mixed reality development which includes features like:
                    </>
                ),
                items: [
                    "Color passthrough",
                    "Scene understanding",
                    "Interaction SDK",
                    "Compatible with Quest 2, Quest 3 and Quest Pro",
                ],
            },
        ],
    },
    press: [
        {
            title: "Finding Your Mixed Reality Idea:\nA Guide to Idea Generation",
            link: "https://treeview.studio/blog/finding-your-mixed-reality-idea/",
            thumbnail: blogImages.d,
            description: "",
        },
        {
            title: "How to Build a Mixed Reality Prototype:\nAgile Software Development",
            link: "https://treeview.studio/blog/how-to-build-a-mixed-reality-prototype/",
            thumbnail: blogImages.e,
            description: "",
        },
        {
            title: "Best Practices in Mixed Reality Design:\nAn In-Depth Guide: ",
            link: "https://treeview.studio/blog/designing-for-mixed-reality/",
            thumbnail: blogImages.f,
            description: "",
        },
    ],
    becomeAnAlphaTester: {
        description:
            "Treeview is one of the world's leading AR/VR development studios. Subscribe to stay up to date with the latest developments and updates. Unsubscribe at any time.",
        path: "contact/newtons-room/alpha-testers",
    },
};

export default NewtonsRoom;
