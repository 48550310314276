import { FC } from "react";
import styled, { css } from "styled-components";
import { SSection } from "../home/";
import { Text } from "../../components/UI";
import { Helmet } from "react-helmet";

const SClutch = styled.div`
    height: 645px;
    width: 920px;
    margin: auto;
    position: relative;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        height: 260px;
    }
`;

const SBorder = styled.div<{ right?: boolean }>`
    position: absolute;
    width: 32px;
    top: 0;
    bottom: 0;
    background-color: ${(p) => p.theme.colors.dark};
    ${(p) =>
        p.right &&
        css`
            right: 0;
        `}
`;

const Testimonials: FC = (props) => {
    return (
        <SClutch>
            <iframe
                frameBorder={0}
                width="100%"
                height="100%"
                src="https://clutch-reviews-test-treeview.web.app"
            />

            <SBorder />
            <SBorder right />
        </SClutch>
    );
};

export default Testimonials;
