import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/think-digital";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import Press from "../../case-study/Press";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import Clients from "../../case-study/Clients";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import History from "../../case-study/History";

const SGap = styled.div`
    height: 100px;
`;

function ThinkDigital() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <ClientTag caseStudy={caseStudy} />

                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>

                <About caseStudy={caseStudy}>
                    <a href="https://think.digital/" target="_blank">
                        Think Digital
                    </a>
                     is a startup that primarily works with the{" "}
                    <b>
                        Australian education, government and agricultural
                        sectors.
                    </b>{" "}
                    Think Digital provides a range of creative immersive
                    technology services and solutions, such as 360° journeys, VR
                    training environments, AR productivity applications, app
                    development, event activations, hardware, workshops, and
                    industry AR/VR tools.
                </About>

                <SSection gridGap="16px" small>
                    <Clients caseStudy={caseStudy} />
                    <SGap />
                    <Video
                        id="814514263?h=e18c2688e6"
                        title="Augmented Reality Development"
                    />
                    <SGap />
                    <Video
                        id="770915947?h=72cf3007bd"
                        title="Virtual Reality Development"
                    />
                    <Press caseStudy={caseStudy} />
                </SSection>

                <TreeviewRole caseStudy={caseStudy}>
                    We have been collaborating with Think Digital{" "}
                    <b>since 2017</b>, helping build one of the{" "}
                    <b>most important AR/VR tech companies in agriculture</b> in
                    Australia.
                    <br />
                    <br />
                    Having worked on dozens of high-profile AR/VR projects and
                    internal platforms, we have been Think Digital's{" "}
                    <b>key technological partner</b> on the journey from a
                    vision to a thriving AR/VR business.
                    <br />
                    <br />
                    We have provided software development, design, and project
                    management services during several years of collaboration.
                    We have worked with dozens of different AR/VR technologies
                    on multiple projects.
                </TreeviewRole>

                <History caseStudy={caseStudy} />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default ThinkDigital;
