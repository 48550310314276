import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Image, Text } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile } from "../../hooks";

const SClientTag = styled.div`
    min-height: 137px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${(p) => p.theme.mobileQuery} {
        grid-template-columns: auto;
        grid-template-rows: 41px auto;
        grid-gap: 44px;
    }
`;

const SLogo = styled.div<{ clickable: boolean }>`
    height: 65px;
    width: 40%;

    ${(p) =>
        p.clickable &&
        css`
            cursor: pointer;
            transition: 0.3s;
            :hover {
                transform: scale(1.1);
            }

            ${(p) => p.theme.mobileQuery} {
                :hover {
                    transform: scale(1.01);
                }
            }
        `}
`;

const SRight = styled.div`
    display: grid;
    grid-gap: 24px;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        grid-template-columns: min-content auto;
    }
`;

const SClient = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 16px;
    align-items: center;

    ${(p) => p.theme.mobileQuery} {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        width: 64px;
    }
`;

const ClientPicture = styled.div`
    height: 64px;
    width: 64px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
`;

const ClientTag: FC<{ caseStudy: ICaseStudy; bigLogo?: boolean }> = ({
    caseStudy,
    bigLogo,
}) => {
    const isMobile = useIsMobile();

    const client = (
        <SClient>
            <ClientPicture>
                <Image contain image={caseStudy.clientTag.clientPicture} />
            </ClientPicture>
            <Text size="H7" color="light" center={isMobile}>
                {caseStudy.clientTag.clientName}
            </Text>
        </SClient>
    );

    return (
        <SClientTag>
            <SLogo
                clickable={caseStudy.clientTag.link != undefined}
                onClick={() =>
                    caseStudy.clientTag.link &&
                    window.open(caseStudy.clientTag.link)
                }
            >
                <Image
                    contain={!bigLogo || isMobile}
                    image={caseStudy.clientTag.logo}
                    imagePosition="left"
                />
            </SLogo>

            <SRight>
                {isMobile && client}
                <Text size="H6" color="medium" style={{ fontStyle: "Italic" }}>
                    {caseStudy.clientTag.clientComment}

                    {caseStudy.clientTag.clutch && (
                        <Text
                            oneline
                            onClick={() =>
                                window.open(caseStudy.clientTag.clutch)
                            }
                            size="H6"
                            color="light"
                            noBorder
                        >
                            {" "}
                            - Clutch.co
                        </Text>
                    )}
                </Text>
                {!isMobile && client}
            </SRight>
        </SClientTag>
    );
};

export default ClientTag;
