import a from "./a.png";
import b from "./b.png";
import c from "./c.png";
import d from "./d.png";
import f from "./f.png";
import g from "./g.png";
import h from "./h.png";
import i from "./i.png";

const ThinkDigitalPress = {
    a,
    b,
    c,
    d,
    f,
    g,
    h,
    i,
};

export default ThinkDigitalPress;
