import videos from "../assets/videos";
import ClientReviews from "../components/ClientReviews";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import { Text } from "../components/UI";
import { useIsMobile } from "../hooks";
import BehindTheScenes from "./about-us/BehindTheScenes";
import Blog from "./about-us/Blogs";
import OurTeam from "./about-us/OurTeam";
import Stats from "./about-us/Stats";

function AboutUs() {
    const isMobile = useIsMobile();
    return (
        <Page title="About Us">
            <PageTitle
                title={"Driving You into the Future"}
                subTitle={
                    "We rank as the #1 AR/VR development team in LATAM\nand among the Top 10 globally, as recognized by Clutch."
                }
                descriptionNode={
                    <Text size="H6" color="medium" center={isMobile}>
                        The technological revolution is just beginning, with
                        spatial computing set to be a major driver of the next
                        chapter of technology. We want you to play a key role in
                        building this future.
                        <br />
                        <br />
                        Founded in 2016, we are a high-end boutique development
                        studio{" "}
                        <b>
                            building custom spatial computing (AR/VR) software
                        </b>
                        . We help companies like yours drive into the future and
                        ensure you're never left behind
                    </Text>
                }
                video={videos.aboutUsHero}
            />
            <Stats />
            <Blog />
            <OurTeam />
            <ClientReviews title="What Our Clients Are Saying" startIndex={2} />
            <BehindTheScenes />
        </Page>
    );
}

export default AboutUs;
