import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/neom";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import Press from "../../case-study/Press";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import Clients from "../../case-study/Clients";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import History from "../../case-study/History";

const SGap = styled.div`
    height: 100px;
`;

function Neom() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <ClientTag caseStudy={caseStudy} />

                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>

                <About
                    caseStudy={caseStudy}
                    secondChildren={
                        <>
                            Trojena’s vision is to{" "}
                            <b>seamlessly integrate nature and technology</b> to
                            cater to the interests and needs of modern
                            travelers. Trojena aims to be an iconic, world-class
                            destination that blends natural and developed
                            landscapes, offering unique human-centric
                            experiences for both residents and visitors alike.
                            <br />
                            <br />
                            As part of this vision, NEOM is looking to{" "}
                            <b>
                                integrate the latest technological innovations
                            </b>{" "}
                            into the location's experience. NEOM contacted us to
                            build a proof-of-concept mixed-reality ski
                            experience for Magic Leap 2. This will allow
                            stakeholders to explore the value of mixed reality
                            and assess the feasibility of full deployment of
                            this technology on the slopes of Trojena.{" "}
                        </>
                    }
                >
                    <a href="https://www.neom.com/en-us" target="_blank">
                        NEOM
                    </a>{" "}
                    is a new destination being created in the northwest of Saudi
                    Arabia. It aims to be a <b>land of the future</b>, where the
                    best ideas, most promising startups, established companies,
                    future industries, and top talents come together to create
                    solutions for humanity's key challenges.
                    <br />
                    <br /> One of NEOM’s regions is{" "}
                    <a
                        href="https://www.neom.com/en-us/regions/trojena"
                        target="_blank"
                    >
                        Trojena
                    </a>
                    , which is located in the mountains of NEOM. Trojena will
                    offer year-round outdoor skiing and adventure sports, with
                    completion scheduled for 2026. The facilities will include
                    36 km of outdoor ski slopes.
                </About>

                <SSection gridGap="16px" small>
                    <Video id="877992580?h=c2935b7695" />
                </SSection>

                <TreeviewRole caseStudy={caseStudy}>
                    We collaborated with NEOM's team to develop a proof of
                    concept mixed reality application for Magic Leap 2 and
                    tested the software on ski slopes, producing output analysis
                    reports of Magic Leap 2's performance during the ski
                    sessions and overall user experience for this use case.
                    <br />
                    <br /> We collaborated on the research and development of
                    key platform features such as the slalom route mapping
                    feature, performance visualizers, and session reports. The
                    proof of concept serves as a critical technology and product
                    assessment for the feasibility of a full deployment and the
                    capabilities of the latest mixed reality technology at NEOM.
                    <br />
                    <br /> We continue to collaborate with NEOM as an R&D
                    partner, exploring use cases and technological capabilities
                    of the latest mixed reality technologies.
                </TreeviewRole>
                <Video id="877972786?h=ead7b09666" />

                <History caseStudy={caseStudy} />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default Neom;
