import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, InputField, Text } from "./UI";
import { subscribeToMailchimp } from "../api";

const SNewsLetter = styled.div``;

const SInput = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    grid-gap: 16px;
`;

const NewsLetter: FC = () => {
    const [state, set] = useState<{ email: string; feedback?: string }>({
        email: "",
    });
    const [loading, setLoading] = useState(false);

    async function signUpHandler() {
        if (state.email === "") {
            set((p) => ({ ...p, feedback: "Enter email." }));
            return;
        }

        const emailRegex =
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const emailIsValid = emailRegex.test(state.email);

        if (!emailIsValid) {
            set((p) => ({ ...p, feedback: "Enter a valid email." }));
            return;
        }

        setLoading(true);
        set((p) => ({ ...p, feedback: "Sending..." }));

        await subscribeToMailchimp(state.email);

        set((p) => ({ ...p, email: "", feedback: "Subscribed." }));
        setLoading(false);
    }

    return (
        <SNewsLetter>
            <SInput>
                <InputField
                    value={state.email}
                    onChange={(email) => set((p) => ({ ...p, email }))}
                    title="Newsletter"
                    placeholder="Your Email"
                    secondGradient
                    onEnter={() => signUpHandler()}
                />
                <Button
                    onClick={() => signUpHandler()}
                    small
                    style={{
                        textAlign: "center",
                        height: 49,
                        marginTop: "auto",
                    }}
                >
                    Subscribe
                </Button>
            </SInput>
            <Text color="light" oneline size="H5" gradientB>
                {loading ? "Sending..." : state.feedback}
            </Text>
        </SNewsLetter>
    );
};

export default NewsLetter;
