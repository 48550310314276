import React, { FC, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import NumberCard from "../../components/cards/NumberCard";
import { Text } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import TitleCard from "../../components/cards/TitleCard";
import ImageCard from "../../components/cards/ImageCard";
import FullWidthContainer from "../../components/FullWidthContainer";
import Vimeo from "../../components/Vimeo";

const SAbout = styled.div`
    display: grid;
    grid-gap: 16px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

export const SCardsHolder = styled.div`
    display: grid;
    place-items: center;
    padding: 80px 0;

    ${(p) => p.theme.mobileQuery} {
        padding: 50px 0;
    }
`;

export const SCards = styled.div<{ big?: boolean; grid?: boolean }>`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 36px;

    * {
        width: ${(p) => (p.big ? "490px" : "279px")};
    }

    ${(p) =>
        p.grid &&
        css`
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: row;
        `}

    ${(p) => p.theme.mobileQuery} {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        * {
            width: 90vw;
        }
    }
`;

interface IProps extends PropsWithChildren {
    caseStudy: ICaseStudy;
    secondChildren?: React.ReactNode;
    style?: React.CSSProperties;
}

const About: FC<IProps> = ({ caseStudy, children, secondChildren, style }) => {
    return (
        <SAbout style={style}>
            <Text circularFont color="light" size="H3">
                👋 About
            </Text>
            <Text size="H6" color="medium">
                {children ?? caseStudy.about.firstDescription}
            </Text>

            {caseStudy.about.cards && (
                <SCardsHolder>
                    <SCards>
                        {caseStudy.about.cards.map((c, i) => (
                            <NumberCard {...c} key={i} />
                        ))}
                    </SCards>
                </SCardsHolder>
            )}
            {caseStudy.about.imageCards && (
                <SCardsHolder>
                    <SCards big grid={caseStudy.about.imageCards.length > 2}>
                        {caseStudy.about.imageCards.map((c, i) => (
                            <ImageCard {...c} key={i} />
                        ))}
                    </SCards>
                </SCardsHolder>
            )}
            {caseStudy.about.titleCards && (
                <SCardsHolder>
                    <SCards>
                        {caseStudy.about.titleCards.map((c, i) => (
                            <TitleCard {...c} key={i} />
                        ))}
                    </SCards>
                </SCardsHolder>
            )}
            {(caseStudy.about.secondDescription || secondChildren) && (
                <Text size="H6" color="medium">
                    {secondChildren ?? caseStudy.about.secondDescription}
                </Text>
            )}

            {caseStudy.about.videoReel && (
                <FullWidthContainer height="85vh" style={{ marginTop: "40px" }}>
                    <Vimeo id={caseStudy.about.videoReel} />
                </FullWidthContainer>
            )}
        </SAbout>
    );
};

export default About;
