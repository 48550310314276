import arrowLeft from "./arrow-left.svg";
import arrowRight from "./arrow-right.svg";
import discord from "./discord.svg";
import instagram from "./instagram.svg";
import linkedin from "./linkedin.svg";
import menu from "./menu.svg";
import star from "./star.svg";
import twitter from "./twitter.svg";
import youtube from "./youtube.svg";

const icons = {
    arrowLeft,
    arrowRight,
    discord,
    instagram,
    linkedin,
    menu,
    star,
    twitter,
    youtube,
};

export default icons;
