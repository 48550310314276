import a from "./blog-a.png";
import b from "./blog-b.png";
import c from "./blog-c.png";

const blogImages = {
    a,
    b,
    c,
};

export default blogImages;
