import { FC } from "react";
import styled from "styled-components";
import BasicCard from "../../components/cards/BasicCard";
import Grid from "../../components/grids/Grid";
import { Button, Text } from "../../components/UI";
import { useIsMobile, useRouter } from "../../hooks";
import { SSection } from "../home/";
import { Paths } from "../../context/RouterContext";

const AdditionalServices: FC = () => {
    const isMobile = useIsMobile();
    const router = useRouter();
    const items = [
        {
            title: "Full Stack Development",
            description:
                "We offer full-stack development services to assist you in building key components of world-class spatial computing software.",
        },
        {
            title: "Project Management",
            description:
                "Our project managers oversee day-to-day operations, facilitating dynamic collaboration.",
        },
        {
            title: "Spatial Computing Consulting",
            description:
                "Our expert partners provide early-stage product guidance and high-level CTO consultancy.",
        },
        {
            title: "Quality Assurance",
            description:
                "We provide QA services to make sure the development process runs smoothly to produce world-class software.",
        },
        {
            title: "Support and Maintenance",
            description:
                "We provide comprehensive support and maintenance, including bug fixing, feature enhancements, and other ongoing tasks.",
        },
        {
            title: "Devops",
            description:
                "Our team of DevOps experts is fully equipped to develop complex software architecture.",
        },
    ].map((item, i) => (
        <BasicCard
            height="217px"
            key={item.title}
            title={item.title}
            description={item.description}
            index={i}
        />
    ));

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : "231px" }}>
            <div style={{ gap: 10, display: "grid" }}>
                <Text
                    gradient
                    size="H2"
                    oneline={!isMobile}
                    style={{ width: "100%" }}
                    circularFont
                >
                    Additional Services
                </Text>
                <Text color="light" size="H4" circularFont>
                    A complete team of professionals to support your innovation
                    initiatives!
                </Text>
            </div>
            <Grid smallGap>{items}</Grid>
            <div
                style={{ display: "grid", placeItems: "center", marginTop: 30 }}
            >
                <Button onClick={() => router.setPath(Paths.contact)}>
                    Schedule a meeting now!
                </Button>
            </div>
        </SSection>
    );
};

export default AdditionalServices;
