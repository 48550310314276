import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import previewVideo from "../../../assets/videos/case-studies/xr-medical-solutions";
import imageIcons from "../../../assets/images/icons";

const XRMedicalSolutions: ICaseStudy = {
    id: "xr-medical-solutions",
    customPath: Paths.xrMedicalSolutions,

    preview: {
        title: "Mental Wellness",
        description:
            "XR Medical Solutions Group is a startup that offers FDA SaMD approved virtual reality treatments in the health and wellness sector.",
        image: require("./preview.png"),
        video: previewVideo,
    },

    carousel: {
        images: [require("./carousel/f.png")],
    },

    clientTag: {
        logo: clientLogos.xrMedicalSolutions,
        clientPicture: require("./client-picture.png"),
        clientName: "Michael Berman\nFounder-COO",
        clientComment:
            "Treeview has exceeded our expectations on all levels across VR and AR developments. The engagement exceeded the expectations of the internal team, forming the foundation of our business.",
        clientCommentSummary:
            "Treeview has exceeded our expectations on all levels across VR and AR developments.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#review-1169409",
    },

    headingA: "Transforming Mental Health with\nImmersive Digital Solutions",

    about: {
        firstDescription:
            "XR Medical Solutions Group is a startup that offers FDA SaMD approved virtual reality treatments in the health and wellness sector.  The company has developed a technology platform to treat numerous physical & psychological conditions to improve personal well-being.  XR Medical Solutions offers a B2B subscription service providing a full suite of hardware + software solutions to clients.",
        cards: [
            {
                targetValue: 0,
                unit: "✓",
                description: "FDA SaMD",
            },
            {
                targetValue: 5,
                unit: "+",
                description: "Enterprised Clients",
            },
        ],
        secondDescription:
            "XR Medical Solutions offers 300+ combinations of interactive therapeutic guided meditation experiences, revolutionizing mindfulness and wellbeing in the modern age. Developed based on clinically verified research in neuroscience, Mind/Body techniques and psychology. The platform offers a healing gymnasium for your mind with a wide range of applications and general wellness.",
    },

    headingB:
        "Successful long-term developmentpartnership building\nFDA SaMD medically approved virtual reality solutions.",

    treeviewRole: {
        description:
            "Treeview has worked with XR Medical Solutions Group since 2017, building and iterating the VR platform. The solution includes an enterprise-level VR platform with a web back office CMS and mobile remote controller. The platform offers a wide range of passive and interactive VR treatment therapies.  Treeview has been XR Medical Solutions Group’s primary AR/VR development partner for over four years of collaboration.",
        cards: [
            {
                targetValue: 4,
                description: "Years Collaborating",
            },
            {
                targetValue: 50,
                unit: "+",
                description: "VR Therapies",
            },
            {
                targetValue: 5,
                unit: "+",
                description: "AR/VR Technologies",
            },
        ],
    },

    developmentServices: {
        description:
            "Throughout several years of collaboration, Treeview has developed several business verticals and provided R&D to XR Medical Solutions Group. Treeview has provided software development, product design, and project management for several projects ranging through different AR/VR technologies.",
        services: [
            {
                title: "Virtual Reality",
                items: ["Oculus Go", "Oculus Quest 1 & 2", "Pico G2"],
            },
            {
                title: "Augmented Reality",
                items: ["Epson Moverio Smart-Glasses", "Microsoft Hololens"],
            },
            {
                title: " Backend Development",
                items: ["Firebase", "Google Cloud"],
            },
            {
                title: "Server Development",
                items: ["Custom LAN Content Server in .Net"],
            },
        ],
    },

    videoReel: "779408336?h=7739314066",

    history: {
        cards: [
            {
                icon: imageIcons.plant,
                description:
                    "Leading AR/VR Startup in Australia's Agriculture industry. Servicing enterprise and government.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "We collaborated for over 4+ years developing multiple product lines and enterprise solutions.",
            },
            {
                icon: imageIcons.cup,
                description:
                    "Successful long-term development partnership building FDA SaMD medically approved VR solutions.",
            },
        ],
    },
};

export default XRMedicalSolutions;
