import React, { FC } from "react";
import styled from "styled-components";
import { CaseStudyCard } from "../../components/cards/CaseStudyCard";
import Grid from "../../components/grids/Grid";
import { Text } from "../../components/UI";
import useCaseStudies, { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile } from "../../hooks";

const SMoreCaseStudies = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 36px;
    margin-top: 60px;
    ${(p) => p.theme.mobileQuery} {
        width: 93vw;
    }
`;

const MoreCaseStudies: FC<{ caseStudy: ICaseStudy }> = ({ caseStudy }) => {
    const caseStudies = useCaseStudies();
    const isMobile = useIsMobile();

    const array = Object.values(caseStudies.caseStudies);
    const arrays = array.concat(array).concat(array);
    const index = array.indexOf(caseStudy) + array.length;

    const items = arrays
        .slice(index, index + 5)
        .filter((cs) => cs.id != caseStudy.id && !cs.hide)
        .map((caseStudy) => (
            <CaseStudyCard small key={caseStudy.id} caseStudy={caseStudy} />
        ));

    return (
        <SMoreCaseStudies>
            <Text oneline circularFont gradient size="H3">
                More Case Studies
            </Text>
            <Grid columns={isMobile ? 1 : 4}>{items}</Grid>
        </SMoreCaseStudies>
    );
};

export default MoreCaseStudies;
