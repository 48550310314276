import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import videoPreview from "../../../assets/videos/case-studies/pressenger";

const Pressenger: ICaseStudy = {
    id: "pressenger",
    customPath: Paths.pressenger,

    preview: {
        title: "Spatial Notifications",
        description:
            "Pressenger is a world leader in high-end push notifications for brands, working with clients such as Borussia Dortmund to bring innovation and drive user engagement.",
        image: require("./preview.png"),
        video: videoPreview,
    },

    carousel: {
        images: [require("./carousel/a.png")],
        imagePosition: "top",
    },

    clientTag: {
        logo: clientLogos.pressenger,
    },

    headingA: "Spatial Computing Notifications\nFor Brand Engagement",

    about: {
        firstDescription: "",
        secondDescription: "",
        titleCards: [
            {
                title: "Top 10\nMobile Marketing",
                subTitle: "Solutions Providers\nin Europe: MarTech",
            },
            {
                title: "Winner FootballCan 2041",
                subTitle: "Santander Football\nInnovation Award",
            },
            {
                title: "Sport Thinkers\nInnovation Award",
                subTitle: "Microsoft Global Sports Innovation Center",
            },
        ],
    },

    developmentServices: {
        useTreeviewRole: true,
        description: "",
    },
};

export default Pressenger;
