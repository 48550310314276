import eightyKeh from "./80keh.svg";
import moa from "./moa.svg";
import seensy from "./seensy.svg";
import side from "./side.svg";
import weissglut from "./weissglut.svg";
import zirup from "./zirup.svg";

const ariotClients = {
    eightyKeh,
    moa,
    seensy,
    side,
    weissglut,
    zirup,
};

export default ariotClients;
