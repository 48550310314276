import { FC } from "react";
import styled from "styled-components";
import NumberCard from "../../components/cards/NumberCard";
import { Button, Text } from "../../components/UI";
import DATABASE from "../../database";
import { useRouter } from "../../hooks";
import { Paths } from "../../context/RouterContext";

const SStats = styled.div`
    display: grid;
    grid-gap: 16px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;
    margin-top: -60px;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SCardsHolder = styled.div`
    display: grid;
    place-items: center;
    padding: 80px 0;
`;

const SCards = styled.div<{ big?: boolean }>`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 36px;

    * {
        width: ${(p) => (p.big ? "490px" : "279px")};
    }

    ${(p) => p.theme.mobileQuery} {
        grid-auto-flow: row;
    }
`;

const Stats: FC = () => {
    const router = useRouter();
    return (
        <SStats>
            <SCardsHolder>
                <SCards>
                    <NumberCard
                        targetValue={8}
                        unit="+"
                        description="Years in Business"
                    />
                    <NumberCard
                        targetValue={150}
                        unit="+"
                        description="AR/VR Projects Developed"
                    />
                    <NumberCard
                        targetValue={DATABASE["global-variables"].totalClients}
                        unit="+"
                        description="Clients Served"
                    />
                </SCards>
            </SCardsHolder>
            <Text center circularFont color="light" size="H2">
                Become a Leading Innovator in Your Industry
            </Text>
            <div
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    marginTop: 10,
                }}
            >
                <Button onClick={() => router.setPath(Paths.contact)}>
                    Schedule a meeting now!
                </Button>
            </div>
        </SStats>
    );
};

export default Stats;
