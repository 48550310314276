import videos from "../assets/videos";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";

import AdditionalServices from "./services/AdditionalServices";
import HowWeWork from "./services/HowWeWork";
import MainServices from "./services/MainServices";
import OurExpertise from "./services/OurExpertise";

function Services() {
    return (
        <Page title="Services">
            <PageTitle
                title={"End-to-End Spatial Computing\nDevelopment Services "}
                subTitle={
                    "Spatial computing software development, from ideation to implementation, to rocket your company into the future, regardless of your industry. "
                }
                description={
                    "We've partnered with sectors including entertainment, healthcare, and education.What industry do you work in?"
                }
                video={videos.servicesHeroLightBulb}
            />

            <MainServices />
            <AdditionalServices />
            <OurExpertise />
            <HowWeWork />
        </Page>
    );
}

export default Services;
