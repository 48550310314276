import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "animate.css/animate.min.css"; // Animate on scroll.
import App from "./App";

// Theme.
import { ThemeProvider } from "styled-components";
import Theme, { MobileTheme } from "./theme";
import { useIsMobile } from "./hooks";

declare global {
    interface Window {
        analytics: any;
    }
}

function Container() {
    const [theme, setTheme] = useState(Theme);
    const isMobile = useIsMobile();

    useEffect(() => {
        setTheme(isMobile ? MobileTheme : Theme);
    }, [isMobile]);

    return (
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <Container />
    </React.StrictMode>
);
