import React, { FC } from "react";
import { SSection } from "../pages/home/";
import { Text } from "./UI";
import { useIsMobile } from "../hooks";
import HorizontalCarousel from "./HorizontalCarousel";

const ClientReviews: FC<{
    title?: string;
    marginTop?: number;
    startIndex?: number;
}> = ({ title, marginTop, startIndex }) => {
    const isMobile = useIsMobile();

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : marginTop ?? 150 }}>
            {title && (
                <Text gradient size="H2" circularFont style={{ width: "100%" }}>
                    {title}
                </Text>
            )}
            <HorizontalCarousel startIndex={startIndex} />
        </SSection>
    );
};

export default ClientReviews;
