import { FC } from "react";
import styled from "styled-components";
import videos from "../../assets/videos";
import Grid from "../../components/grids/Grid";
import { Button, Text } from "../../components/UI";
import { useIsMobile } from "../../hooks";
import { SSection } from "../home/";
import AboutUsCard from "../home/AboutUsCard";

const SMainServices = styled.div`
    display: grid;
    grid-gap: 30px;
    place-items: center;
`;

const MainServices: FC = () => {
    const isMobile = useIsMobile();
    const items = [
        {
            title: "AR/VR Development",
            description: "We work with all major AR/VR hardware platforms.",
            video: videos.servicesArVrDevelopment,
        },
        {
            title: "3D Design",
            description:
                "Our 3D artists create the ultimate immersive experiences.",
            video: videos.services3dDesign,
        },
        {
            title: "UX/UI Design",
            description: "We create user-experience-focused product design.",
            video: videos.servicesUiUx,
        },
    ].map((i) => (
        <AboutUsCard
            key={i.title}
            title={i.title}
            description={i.description}
            video={i.video}
        />
    ));

    return (
        <SSection style={{ marginTop: "68px" }}>
            <div style={{ display: "grid", gap: 10 }}>
                <Text
                    gradient
                    size="H2"
                    oneline={!isMobile}
                    circularFont
                    style={{ width: "100%" }}
                >
                    Main Services
                </Text>
                <Text color="light" size="H4" circularFont>
                    We take the whole spatial computing development process off
                    your shoulders!
                </Text>
            </div>
            <SMainServices>
                <Grid style={{ gridGap: "32px", marginBottom: "30px" }}>
                    {items}
                </Grid>
            </SMainServices>
        </SSection>
    );
};

export default MainServices;
