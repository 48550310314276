import styled, { css } from "styled-components";

export const SSection = styled.div<{ small?: boolean; gridGap?: string }>`
    display: grid;
    grid-gap: ${(p) => p.gridGap ?? "35px"};

    ${(p) =>
        p.small &&
        css`
            margin: auto;
            width: ${(p) => p.theme.sizes.pageWidth.small};
        `}

    ${(p) => p.theme.mobileQuery} {
        width: 100%;
        grid-gap: 25px;
    }
`;
