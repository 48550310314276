import videos from "../assets/videos";
import ClientReviews from "../components/ClientReviews";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import { Text } from "../components/UI";
import { Paths } from "../context/RouterContext";
import { useIsMobile, useRouter } from "../hooks";

import AboutUs from "./home/AboutUs";
import Awards from "./home/Awards";
import LatestWork from "./home/LatestWork";
import OurClients from "./home/OurClients";
import OurPlan from "./home/OurPlan";
import WhyChooseUs from "./home/WhyChooseUs";

function Home() {
    const router = useRouter();
    const isMobile = useIsMobile();
    return (
        <Page title="Home">
            <PageTitle
                title={
                    "Start Working with World-Class\nSpatial Computing Developers Today"
                }
                subTitle={
                    "We rank as the #1 AR/VR development team in LATAM\nand among the Top 10 globally, as recognized by Clutch."
                }
                descriptionNode={
                    <Text size="H6" color="medium" center={isMobile}>
                        Treeview is a{" "}
                        <b>high-end boutique development studio</b>, guiding
                        enterprises into the future. We are a full suite team of{" "}
                        <b>AR/VR software developers</b> ready to support your
                        innovation initiatives!
                    </Text>
                }
                action={{
                    text: "Schedule our first meeting now!",
                    onClick: () => router.setPath(Paths.contact),
                }}
                video={videos.homeHero}
                extra={<Awards />}
            />
            <AboutUs />
            <ClientReviews
                title={"We Are Dedicated to Fostering\nLong-Term Partnerships"}
            />
            <OurClients />
            <OurPlan />
            <WhyChooseUs />

            <LatestWork />
        </Page>
    );
}

export default Home;
