import React, { FC, PropsWithChildren } from "react";
import styled, { css } from "styled-components";

const SGrid = styled.div<IProps>`
    display: grid;
    grid-template-columns: repeat(${(p) => p.columns ?? 3}, 1fr);
    grid-gap: ${(p) => (p.smallGap ? "26px" : "36px")};

    justify-content: space-between;

    ${(p) =>
        p.small &&
        css`
            margin: auto;
            width: ${(p) => p.theme.sizes.pageWidth.small};
        `}

    ${(p) => p.theme.mobileQuery} {
        grid-template-columns: repeat(${(p) => p.columns ?? 1}, 1fr);
        width: ${(p) => (p.columns === 2 ? "min-content" : "inherit")};
        width: 100%;
        /* background: red; */
        /* justify-items: center; */
        margin: auto;
        grid-gap: 16px;
    }
`;

interface IProps extends PropsWithChildren {
    columns?: number;
    style?: any;
    small?: boolean;
    smallGap?: boolean;
}

const Grid: FC<IProps> = (props) => {
    return <SGrid {...props}>{props.children}</SGrid>;
};

export default Grid;
