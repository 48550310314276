import React, {
    FC,
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from "react";
import { IThemeColors, IThemeFontSizes } from "../theme";
import styled, { css, CSSProperties } from "styled-components";

//#region Text
interface ITextProps extends PropsWithChildren {
    size?: keyof IThemeFontSizes;
    color?: keyof IThemeColors;
    gradient?: boolean;
    gradientB?: boolean;
    weight?: "medium";
    oneline?: boolean;
    selected?: boolean;
    circularFont?: boolean;
    onClick?(): void;
    noBorder?: boolean;
    center?: boolean;
    style?: CSSProperties;
}

const CSS_Gradient = css`
    background: -webkit-linear-gradient(
        left,
        ${(p) => p.theme.gradients.primary.left},
        ${(p) => p.theme.gradients.primary.right}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    width: min-content;
`;

const CSS_GradientBottom = css`
    width: min-content;

    border-bottom: 2px solid transparent;
    border-image: -webkit-linear-gradient(
        left,
        ${(p) => p.theme.gradients.secondary.left},
        ${(p) => p.theme.gradients.secondary.middle},
        ${(p) => p.theme.gradients.secondary.right}
    );

    border-image-slice: 1;
    padding-bottom: 1px;
`;

const CSS_Border_Bottom_Gradient = css`
    border-image: linear-gradient(
            to right,
            ${(p) => p.theme.gradients.primary.left},
            ${(p) => p.theme.gradients.primary.right}
        )
        1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 10px 0;
`;

const CSS_Border_Gradient = css`
    // Border version.
    position: relative;
    padding: 18px 30px;

    :hover {
        filter: brightness(1.1);
        :before {
            background: linear-gradient(
                0deg,
                ${(p) => p.theme.gradients.primary.left},
                ${(p) => p.theme.gradients.primary.right}
            );
        }
    }

    :before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 17px;
        padding: 2px; /* control the border thickness */
        background: linear-gradient(
            90deg,
            ${(p) => p.theme.gradients.primary.left},
            ${(p) => p.theme.gradients.primary.right}
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
`;

const SText = styled.div<ITextProps>`
    // Size.
    font-size: ${(p) => p.theme.fontSize[p.size ?? "H6"]};
    // One line.
    white-space: ${(p) => (p.oneline ? "nowrap" : "pre-wrap")};

    // Center.
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    // Color.
    ${(p) =>
        p.color &&
        css`
            color: ${p.theme.colors[p.color]};
        `}

    // Gradient.
    ${(p) => p.gradient && CSS_Gradient}

    // Gradient B.
    ${(p) => p.gradientB && CSS_GradientBottom}

    // Weight.
    ${(p) =>
        p.weight === "medium" &&
        css`
            font-weight: 500;
        `}

    // Interactable.
    ${(p) =>
        p.onClick &&
        css`
            cursor: pointer;
            :hover {
                ${CSS_Gradient}
                ${!p.noBorder && CSS_Border_Bottom_Gradient}
            }
        `}
    
    // Is Selected.
    ${(p) =>
        p.selected &&
        css`
            ${CSS_Gradient}
            ${CSS_Border_Bottom_Gradient}
        `}

    // Is Cicrular Font.
    ${(p) =>
        p.circularFont &&
        css`
            font-family: Circular;
        `}
`;

export const Text: FC<ITextProps> = (props) => {
    return <SText {...props}>{props.children}</SText>;
};

//#endregion

//#region GradientBorder
interface IGradientBorderProps extends PropsWithChildren {
    padding?: string;
    style?: CSSProperties;
    second?: boolean;
}

const SGradientBorder = styled.div<IGradientBorderProps>`
    position: relative;
    padding: ${(p) => p.padding};

    ::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 17px;
        padding: 1px; /* control the border thickness */
        background: linear-gradient(
            90deg,
            ${(p) => p.theme.gradients.secondary.left},
            ${(p) => p.theme.gradients.secondary.middle},
            ${(p) => p.theme.gradients.secondary.right}
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
    }
`;

const SGradientBorderSecond = styled.div<IGradientBorderProps>`
    position: relative;
    ${CSS_Border_Gradient}
    padding: ${(p) => p.padding ?? 0};
`;

export const GradientBorder: FC<IGradientBorderProps> = (props) => {
    if (props.second)
        return (
            <SGradientBorderSecond {...props}>
                {props.children}
            </SGradientBorderSecond>
        );
    else return <SGradientBorder {...props}>{props.children}</SGradientBorder>;
};
//#endregion

//#region Button
interface IButtonProps extends PropsWithChildren {
    solid?: boolean;
    small?: boolean;
    onClick?(): void;
    style?: CSSProperties;
}

const SButton = styled.div<IButtonProps>`
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    width: min-content;

    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }

    // Style.
    ${(p) =>
        p.solid
            ? css`
                  // Solid version.
                  background: linear-gradient(
                      90deg,
                      rgba(0, 123, 255, 1) 0%,
                      rgba(0, 194, 255, 1) 100%
                  );

                  :hover {
                      filter: brightness(1.1);
                      background: linear-gradient(
                          0deg,
                          ${(p) => p.theme.gradients.primary.left},
                          ${(p) => p.theme.gradients.primary.right}
                      );
                  }
              `
            : CSS_Border_Gradient}
`;

export const Button: FC<IButtonProps> = (props) => {
    return (
        <SButton {...props}>
            <Text size={props.small ? "H7" : "H6"} color="light" oneline>
                {props.children}
            </Text>
        </SButton>
    );
};
//#endregion

//#region Image
const SImageContainer = styled.div<IImageProps>`
    height: 100%;
    border-radius: ${(p) => (p.round ? "10px" : 0)};
    overflow: hidden;
`;

const SImage = styled.div<IImageProps>`
    background-image: url(${(p) => p.image}), url(${(p) => p.placeholder});
    background-size: ${(p) =>
        p.contain ? "contain" : p.fit ? "100% 100%" : "cover"};
    background-repeat: no-repeat;
    background-position: ${(p) => p.imagePosition ?? "center"};
    height: 100%;
`;

interface IImageProps extends PropsWithChildren {
    url?: string;
    image?: string;
    placeholder?: string;
    contain?: boolean;
    fit?: boolean;
    round?: boolean;
    style?: CSSProperties;
    imagePosition?: string;
}

export const Image: FC<IImageProps> = (props) => {
    return (
        <SImageContainer {...props}>
            <SImage {...props}>{props.children}</SImage>
        </SImageContainer>
    );
};
//#endregion

//#region SVG
interface ISVGProps extends PropsWithChildren {
    image: string;
    contain?: boolean;
    gradient?: boolean;
}

export const SVG = styled.div<ISVGProps>`
    mask-image: url(${(p) => p.image});
    mask-size: ${(p) => (p.contain ? "contain" : "cover")};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors.light};
    height: 100%;
    ${(p) =>
        p.gradient &&
        css`
            background: linear-gradient(
                90deg,
                rgba(0, 123, 255, 1) 0%,
                rgba(0, 194, 255, 1) 100%
            );
        `}
`;
//#endregion

//#region Inputfield
const SInputField = styled.input<IInputFieldProps>`
    background: none;
    font-size: ${(p) => p.theme.fontSize.H5};
    ::placeholder {
        color: ${(p) => p.theme.colors.mediumDark};
    }
    font-weight: 500;
    color: ${(p) => p.theme.colors.light};
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}
    width: 100%;
    height: 100%;
    border: none;
    padding: 15px;
    display: grid;
`;

const SInputFieldContainer = styled.div<{ disabled?: boolean; icon?: string }>`
    box-sizing: border-box;
    height: 48px;
    position: relative;
    background: none;

    ${(p) =>
        !p.disabled &&
        css`
            :hover {
                filter: brightness(98%);
            }
        `}

    ${(p) =>
        p.icon &&
        css`
            ${SVG} {
                position: absolute;
                left: 0;
                width: 1.875rem;
                height: 1.875rem;
            }

            ${SInputField} {
                padding-left: 1.875rem;
            }
        `}
`;

const SInputFieldWithTitle = styled.div`
    display: grid;
    grid-gap: 10px;
    height: min-content;
`;

interface IInputFieldProps extends PropsWithChildren {
    autoSelect?: boolean;
    center?: boolean;
    placeholder?: string;
    value: string;
    onChange(v: string): void;
    onEnter?(): void;
    type?: string;
    icon?: string;
    style?: CSSProperties;
    title?: string;
    disabled?: boolean;
    secondGradient?: boolean;
}

export const InputField: FC<IInputFieldProps> = (props) => {
    const inputRef = useRef();

    // Select inputfield if props.autoSelect is true.
    useEffect(() => {
        if (props.autoSelect && inputRef.current)
            (inputRef.current as any).select();
    }, [inputRef.current]);

    let content = (
        <GradientBorder second={props.secondGradient}>
            <SInputFieldContainer disabled={props.disabled} icon={props.icon}>
                {props.icon && <SVG image={props.icon} />}
                <SInputField
                    //@ts-ignore
                    ref={inputRef}
                    {...props}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && props.onEnter) props.onEnter();
                    }}
                    value={props.value ? props.value : ""}
                    onChange={
                        props.disabled
                            ? () => {}
                            : (e: any) => props.onChange(e.target.value)
                    }
                    type={props.type ? props.type : "text"}
                />
            </SInputFieldContainer>
        </GradientBorder>
    );

    // Add title text if contains.
    if (props.title)
        content = (
            <SInputFieldWithTitle>
                <Text size="H4" circularFont oneline color="light">
                    {props.title}
                </Text>
                {content}
            </SInputFieldWithTitle>
        );

    return content;
};
//#endregion

//#region TextArea
const STextArea = styled.textarea<{ center?: boolean }>`
    background: none;
    outline: none;
    border: none;
    height: 320px;
    width: 100%;
    font-size: ${(p) => p.theme.fontSize.H5};
    padding: 15px;
    ${(p) =>
        p.center &&
        css`
            text-align: center;
        `}

    color: ${(p) => p.theme.colors.light};

    :hover {
        filter: brightness(98%);
    }

    resize: none;
`;

interface ITextAreaProps {
    autoSelect?: boolean;
    center?: boolean;
    placeholder?: string;
    value: string;
    onChange(v: string): void;
    onEnter?(): void;
    type?: string;
    style?: CSSProperties;
    rows?: number;
    cols?: number;
    title?: string;
    secondGradient?: boolean;
}

export const TextArea: FC<ITextAreaProps> = (props) => {
    const inputRef = useRef();

    // Select inputfield if props.autoSelect is true.
    useEffect(() => {
        if (props.autoSelect && inputRef.current)
            (inputRef.current as any).select();
    }, [inputRef.current]);

    let content = (
        <GradientBorder second={props.secondGradient}>
            <STextArea
                //@ts-ignore
                ref={inputRef}
                {...props}
                value={props.value ? props.value : ""}
                onChange={(e) => props.onChange(e.target.value)}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && props.onEnter) props.onEnter();
                }}
            />
        </GradientBorder>
    );

    // Add title text if contains.
    if (props.title)
        content = (
            <SInputFieldWithTitle>
                <Text size="H4" oneline color="light">
                    {props.title}
                </Text>
                {content}
            </SInputFieldWithTitle>
        );

    return content;
};
//#endregion

//#region IconButton
interface IIconButtonProps {
    image: string;
    solid?: boolean;
    onClick?(): void;
}

const SIconButton = styled.div<IIconButtonProps>`
    width: 36px;
    height: 36px;
    cursor: pointer;

    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }

    ${(p) =>
        p.solid
            ? css`
                  border-radius: 50%;
                  background: -webkit-linear-gradient(
                      left,
                      ${(p) => p.theme.gradients.primary.left},
                      ${(p) => p.theme.gradients.primary.right}
                  );
                  padding: 5px;
              `
            : css`
                  ${SVG} {
                      background: -webkit-linear-gradient(
                          left,
                          ${(p) => p.theme.gradients.primary.left},
                          ${(p) => p.theme.gradients.primary.right}
                      );
                  }
              `}
`;

export const IconButton: FC<IIconButtonProps> = (props) => {
    return (
        <SIconButton {...props}>
            <SVG image={props.image} />
        </SIconButton>
    );
};
//#endregion
