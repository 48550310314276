import { FC } from "react";
import styled from "styled-components";
import { SSection } from "./";
import { CaseStudyCard } from "../../components/cards/CaseStudyCard";
import { OffsetGrid } from "../../components/grids/OffsetGrid";
import { Button, Text } from "../../components/UI";
import useCaseStudies from "../../global-state/caseStudies";
import { useIsMobile, useRouter } from "../../hooks";
import { Paths } from "../../context/RouterContext";

const STitle = styled.div`
    display: grid;
    grid-auto-flow: column;
    width: min-content;
    grid-gap: 16px;
    align-items: center;
`;

const LatestWork: FC = () => {
    const caseStudies = useCaseStudies();
    const router = useRouter();
    const isMobile = useIsMobile();

    const items = Object.values(caseStudies.caseStudies)
        .filter((cs) => !cs.hide)
        .slice(0, 4)
        .map((caseStudy) => (
            <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy} />
        ));

    return (
        <SSection
            style={{ marginTop: isMobile ? 50 : 160, marginBottom: "117px" }}
        >
            <STitle>
                <Text gradient size="H2" oneline circularFont>
                    Latest work
                </Text>
                <Text
                    oneline
                    size="H5"
                    color="light"
                    onClick={() => router.setPath(Paths.ourWork)}
                    noBorder
                >
                    View All {"->"}
                </Text>
            </STitle>
            <OffsetGrid items={items} />
            <div
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    marginTop: 50,
                }}
            >
                <Button onClick={() => router.setPath(Paths.ourWork)}>
                    Explore our latest work!
                </Button>
            </div>
        </SSection>
    );
};

export default LatestWork;
