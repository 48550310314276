import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/xr-medical-solutions";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import Press from "../../case-study/Press";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import Clients from "../../case-study/Clients";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import History from "../../case-study/History";

const SGap = styled.div`
    height: 100px;
`;

function XRMedicalSolutions() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <ClientTag caseStudy={caseStudy} />

                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>

                <About caseStudy={caseStudy}>
                    XR Medical Solutions Group  is a startup that offers{" "}
                    <b>FDA SaMD approved virtual reality treatments</b> in the
                    health and wellness sector. The company has developed a
                    technology platform to treat numerous physical &
                    psychological conditions to improve personal well-being. XR
                    Medical Solutions offers a B2B subscription service
                    providing a full suite of hardware and software solutions to
                    clients.
                </About>

                <SSection gridGap="16px" small>
                    <Video id="779408336?h=7739314066" />
                </SSection>

                <TreeviewRole caseStudy={caseStudy}>
                    We have worked with XR Medical Solutions Group since 2017,
                    building and iterating the VR platform. The solution
                    includes an <b>enterprise-level VR platform</b> with a web
                    back office CMS and mobile remote controller. The platform
                    offers a wide range of passive and interactive VR treatment
                    therapies.
                    <br />
                    <br />
                    We have been XR Medical Solutions Group's primary AR/VR
                    development partner for over four years of collaboration.
                    Throughout several years of collaboration, we have developed
                    several business verticals and provided R&D to XR Medical
                    Solutions Group. We have provided software development,
                    product design, and project management for several projects
                    ranging through different AR/VR technologies.
                </TreeviewRole>

                <History caseStudy={caseStudy} />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default XRMedicalSolutions;
