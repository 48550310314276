import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import ariotClients from "./clients";
import videoPreview from "../../../assets/videos/case-studies/ariot";
import imageIcons from "../../../assets/images/icons";

const Ariot: ICaseStudy = {
    id: "ariot",
    customPath: Paths.ariot,

    preview: {
        title: "Construction and Engineering",
        description:
            "Ariot is an AR tool for building operators and construction workers delivering relevant information efficiently – right before their eyes, at the time they need it.",
        image: require("./preview.png"),
        video: videoPreview,
    },

    carousel: {
        images: [require("./carousel/a.png")],
    },

    clientTag: {
        logo: clientLogos.ariot,
    },

    headingA: "Augmented Reality for\nConstruction, and Engineering",

    about: {
        firstDescription:
            "Ariot is an AR tool for building operators and construction workers delivering relevant information efficiently – right before their eyes, at the time they need it.\nBuilding construction and operation gets more and more complex, so let’s make collaboration and lifecycle information management easier. Use the tools of today to be prepared for tomorrow.",
        titleCards: [
            {
                title: "Innovation & Technology Prize",
                subTitle: "tuwien.ac.at",
            },
            {
                title: "TÜV AUSTRIA Science Award",
                subTitle: "tuv.at",
            },
            {
                title: "Incubation Startup Prize",
                subTitle: "aws.at",
            },
        ],
    },

    treeviewRole: {
        description:
            "Treeview worked with ARIOT from 2017-2020 to developed augmented reality software solutions for construction and building management. ARIOT worked with Treeview to develop industry innovations in developing Microsoft Hololens 1-2 software in the construction industry.\nARIOT is part of an EU research program that fosters the development of new technologies, digital systems, and urban services, focusing on building construction in cities. Treeview worked with ARIOT to develop AR technologies that contribute to the urban adoption of digital transformation, aiming to improve EU cities for their inhabitants.",
    },

    developmentServices: {
        description:
            "Treeview provided a full suite of AR/VR software development services, covering Hololens 1-2 and Mobile AR development. Treeview and ARIOT worked together on multiple iterations of AR/VR solutions and research projects, in collaboration with external partners and government agencies.",
        services: [
            {
                title: "Virtual Reality",
                description: "",
                items: ["Oculus Quest 1 & 2", "Pico"],
            },
            {
                title: "Augmented Reality",
                description: "",
                items: ["ARKit", "ARCore"],
            },
            {
                title: "Web Development",
                description: "",
                items: ["ReactJS", "ThreeJS", "OpenCV"],
            },
            {
                title: "Backend Development",
                description: "",
                items: ["Firebase", "Google CLoud", "GitLab + Unity DevOps"],
            },
        ],
    },

    clients: [
        { image: ariotClients.eightyKeh, link: "" },
        { image: ariotClients.moa, link: "" },
        {
            image: ariotClients.seensy,
            link: "",
        },
        { image: ariotClients.side, link: "" },
        { image: ariotClients.zirup, link: "" },
    ],
    history: {
        cards: [
            {
                icon: imageIcons.plant,
                description:
                    "Founded in 2017 with a vision of using AR/VR addressing challenges within the construction industry.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "We collaborated for 3 yearsdeveloping multiple MVPs andR&D projects using the latest AR technologies.",
            },
            {
                icon: imageIcons.crane,
                description:
                    "Multiple award-winning innovation awards and pilot deployments on construction sites.",
            },
        ],
    },
};

export default Ariot;
