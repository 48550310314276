import React, { FC, PropsWithChildren } from "react";
import styled, { keyframes } from "styled-components";
import { Foot } from "./Foot";

import { Helmet } from "react-helmet";

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(-32px);
	}
	100% {
		opacity: 1;
	}
`;

const SPage = styled.div`
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    display: grid;
    justify-content: center;
`;

const SContent = styled.div`
    animation: ${fadeInAnimation} ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    width: ${(p) => p.theme.sizes.pageWidth.main};

    ${(p) => p.theme.mobileQuery} {
        width: 100vw;
        padding: 0 16px;
    }
`;

interface IProps extends PropsWithChildren {
    title: string;
}

const Page: FC<IProps> = ({ children, title }) => {
    return (
        <SPage id="page">
            <Helmet>
                <title>
                    Treeview - Boutique AR/VR Development Studio - {title}
                </title>
            </Helmet>
            <SContent>
                {children}

                <Foot />
            </SContent>
        </SPage>
    );
};

export default Page;
