import { FC } from "react";
import styled from "styled-components";
import { SSection } from "./";
import Grid from "../../components/grids/Grid";
import { Button, Text } from "../../components/UI";
import AboutUsCard from "./AboutUsCard";
import { useIsMobile, useRouter } from "../../hooks";
import { Paths } from "../../context/RouterContext";
import videos from "../../assets/videos";
import DATABASE from "../../database";

const SAboutUs = styled.div`
    display: grid;
    grid-gap: 30px;
    place-items: center;
`;

const AboutUs: FC = () => {
    const router = useRouter();
    const isMobile = useIsMobile();

    const items = [
        {
            title: `+${DATABASE[
                "global-variables"
            ].getYearsOfExperience()} Years of Experience`,
            description: "We have been developing AR/VR software since 2016.",
            video: videos.homeExperience,
        },
        {
            title: `+${DATABASE["global-variables"].totalClients} Happy Clients`,
            description:
                "We work with companies across diverse sectors and industries.",
            video: videos.homeClients,
        },
        {
            title: "Top Development Studio",
            description:
                "We are ranked as one of the top AR/VR development companies worldwide.",
            video: videos.homeTopArVrStudio,
        },
    ].map((i) => (
        <AboutUsCard
            key={i.title}
            title={i.title}
            description={i.description}
            video={i.video}
        />
    ));

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : 50 }}>
            <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
                <Text gradient size="H2" oneline circularFont>
                    About Us
                </Text>
                <Text color="light" size="H4" circularFont>
                    We are a boutique software development studio, laser-focused
                    in spatial computing.
                </Text>
            </div>
            <SAboutUs>
                <Grid style={{ marginBottom: "40px" }}>{items}</Grid>
                <Text color="light" size="H4" circularFont center>
                    We understand how challenging it can be to trust and invest
                    in a new software vendor.
                </Text>
                <Button onClick={() => router.setPath(Paths.aboutUs)}>
                    Read more about us!
                </Button>
            </SAboutUs>
        </SSection>
    );
};

export default AboutUs;
