import React, { FC } from "react";
import styled from "styled-components";

const SYoutube = styled.div`
    height: 100%;
    * {
        height: 100%;
        width: 100%;
    }
`;

const Youtube: FC<{ id: string }> = ({ id }) => {
    return (
        <SYoutube>
            <div>
                <iframe
                    src={`https://www.youtube.com/embed/${id}`}
                    frameBorder={"0"}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </SYoutube>
    );
};

export default Youtube;
