import React from "react";
import styled, { css } from "styled-components";
import { Button, Image, SVG, Text } from "./UI";
import logo from "../theme/blue-logo.png";
import { Paths } from "../context/RouterContext";
import { useIsMobile, useRouter } from "../hooks";
import { useLocation } from "react-router-dom";
import icons from "../assets/icons";
import NewsLetter from "./NewsLetter";

const SFoot = styled.footer`
    margin: auto;
    margin-top: 96px;
    margin-bottom: 39px;
    display: grid;
    width: ${(p) => p.theme.sizes.pageWidth.small};

    ${(p) => p.theme.mobileQuery} {
        width: 100%;
    }
`;

const SBlock = styled.div<{ height?: string }>`
    border-top: 1px solid #646464;
    ${(p) =>
        p.height &&
        css`
            height: ${p.height};
        `}
    display: grid;
    align-items: center;
`;

const SDiscuss = styled.div`
    display: grid;
    place-items: center;
    grid-gap: 24px;
`;

const SNavigation = styled.div`
    display: grid;
    grid-template-columns: auto repeat(3, 218px);
    padding: 36px 0;

    ${(p) => p.theme.mobileQuery} {
        grid-template-rows: 45px auto;
        grid-template-columns: repeat(3, auto);
    }
`;

const SRelativeNewsLetter = styled.div`
    margin-top: 32px;
    position: relative;
`;

const SAbsoluteNewsLetter = styled.div`
    position: absolute;
    width: 400px;

    ${(p) => p.theme.mobileQuery} {
        width: 300px;
    }
`;

const SSocials = styled.div`
    display: flex;
    gap: 16px;
    height: 24px;

    ${(p) => p.theme.mobileQuery} {
        gap: 8px;
    }
`;

const SSocial = styled.div`
    width: 24px;

    cursor: pointer;
    transition: 0.3s;
    :hover {
        * {
            background-color: ${(p) => p.theme.colors.primary};
        }
    }
`;

const SNavigationColumn = styled.div`
    display: grid;
    grid-gap: 10px;
    height: min-content;
`;

export const Foot = () => {
    const router = useRouter();
    const location = useLocation();
    const isMobile = useIsMobile();

    function getRoutes() {
        return [
            { label: "Home", path: Paths.home },
            { label: "About Us", path: Paths.aboutUs },
            { label: "Services", path: Paths.services },
            { label: "Our Work", path: Paths.ourWork },
            // { label: "Why Us?", path: Paths.whyUs },
        ].map((data) => (
            <Text
                onClick={() => router.setPath(data.path)}
                color="medium"
                size="H7"
                key={data.label}
                noBorder
                oneline
            >
                {data.label}
            </Text>
        ));
    }

    function getSocials() {
        return [
            {
                icon: icons.linkedin,
                link: "https://www.linkedin.com/company/treeview-studios/mycompany/",
            },
            {
                icon: icons.twitter,
                link: "https://twitter.com/treeviewstudio",
            },
            {
                icon: icons.discord,
                link: "https://discord.com/invite/TpHwxYPEwd",
            },
            {
                icon: icons.instagram,
                link: "https://www.instagram.com/treeviewstudio/",
            },
            {
                icon: icons.youtube,
                link: "https://www.youtube.com/@treeview.studio",
            },
        ].map((s) => (
            <SSocial key={s.icon} onClick={() => window.open(s.link)}>
                <SVG image={s.icon} contain />
            </SSocial>
        ));
    }

    return (
        <SFoot>
            {location.pathname != Paths.contact && (
                <SBlock height={isMobile ? "192px" : "327px"}>
                    <SDiscuss>
                        <Text circularFont size="H2" color="light">
                            Become a Leading Innovator in Your Industry
                        </Text>
                        <Button onClick={() => router.setPath(Paths.contact)}>
                            Schedule a meeting now!
                        </Button>
                    </SDiscuss>
                </SBlock>
            )}

            <SBlock>
                <SNavigation>
                    {isMobile && <div />}
                    <SNavigationColumn>
                        <Image
                            style={{
                                height: isMobile ? "19px" : "30px",
                                width: isMobile ? "107px" : "171px",
                                margin: isMobile ? "auto" : "none",
                            }}
                            image={logo}
                            contain
                        />
                    </SNavigationColumn>
                    {isMobile && <div />}
                    <SNavigationColumn>
                        <Text size="H4" color="light" circularFont>
                            Navigation
                        </Text>
                        {getRoutes()}
                        <Text
                            onClick={() =>
                                (window.location.href =
                                    "https://treeview.studio/blog")
                            }
                            color="medium"
                            size="H7"
                            noBorder
                            oneline
                        >
                            Blog
                        </Text>
                    </SNavigationColumn>
                    <SNavigationColumn>
                        <Text size="H4" color="light" circularFont>
                            Headquarters
                        </Text>
                        <Text color="medium" size="H7" oneline>
                            Montevideo, Uruguay 🇺🇾
                        </Text>
                        {!isMobile && (
                            <SRelativeNewsLetter>
                                <SAbsoluteNewsLetter>
                                    <NewsLetter />
                                </SAbsoluteNewsLetter>
                            </SRelativeNewsLetter>
                        )}
                    </SNavigationColumn>
                    <SNavigationColumn>
                        <Text size="H4" color="light" circularFont>
                            Social
                        </Text>
                        <SSocials>{getSocials()}</SSocials>
                    </SNavigationColumn>
                </SNavigation>
            </SBlock>

            {isMobile && <NewsLetter />}

            <Text
                color="medium"
                size="H6"
                style={{ marginTop: 32, marginBottom: 32 }}
            >
                © {new Date().getFullYear()} {"<o>"} Treeview.
            </Text>
        </SFoot>
    );
};
