import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import NumberCard from "../../components/cards/NumberCard";
import { Text, Image } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile } from "../../hooks";

const STreeviewRole = styled.div`
    display: grid;
    grid-gap: 16px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    width: min-content;
    grid-gap: 10px;
`;

const SCardsHolder = styled.div`
    display: grid;
    place-items: center;
    padding: 80px 0;
`;

const SCards = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 36px;

    * {
        width: 279px;
    }

    ${(p) => p.theme.mobileQuery} {
        grid-auto-flow: row;
    }
`;

interface IProps extends PropsWithChildren {
    caseStudy: ICaseStudy;
}

const TreeviewRole: FC<IProps> = ({ caseStudy, children }) => {
    const isMobile = useIsMobile();
    return (
        <STreeviewRole>
            <SRow>
                <Text circularFont oneline size="H3" color="primary">
                    {"<o>"}
                </Text>
                <Text circularFont oneline color="light" size="H3">
                    Treeview's Role
                </Text>
            </SRow>
            {caseStudy?.treeviewRole?.cards && (
                <SCardsHolder>
                    <SCards>
                        {caseStudy.treeviewRole.cards.map((c, i) => (
                            <NumberCard {...c} key={i} />
                        ))}
                    </SCards>
                </SCardsHolder>
            )}

            {caseStudy?.treeviewRole?.image && (
                <div style={{ margin: isMobile ? "auto" : "64px auto" }}>
                    <Image
                        image={caseStudy.treeviewRole.image.image}
                        contain
                        style={{
                            width: isMobile
                                ? "90vw"
                                : caseStudy.treeviewRole.image.width,
                            height: isMobile
                                ? "200px"
                                : caseStudy.treeviewRole.image.height,
                            margin: "auto",
                        }}
                    />
                </div>
            )}
            <Text size="H6" color="medium">
                {children ?? caseStudy?.treeviewRole?.description}
            </Text>
        </STreeviewRole>
    );
};

export default TreeviewRole;
