import { FC } from "react";
import { CaseStudyCard } from "../../components/cards/CaseStudyCard";
import { OffsetGrid } from "../../components/grids/OffsetGrid";
import { Text } from "../../components/UI";
import useCaseStudies from "../../global-state/caseStudies";
import { SSection } from "../home/";

const CaseStudies: FC = () => {
    const caseStudies = useCaseStudies();

    const items = Object.values(caseStudies.caseStudies)
        .filter((cs) => !cs.hide)
        .map((caseStudy) => (
            <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy} />
        ));

    return (
        <SSection style={{ marginBottom: "100px", marginTop: 100 }}>
            <Text gradient size="H2" oneline circularFont>
                Case Studies
            </Text>
            <OffsetGrid items={items} />
        </SSection>
    );
};

export default CaseStudies;
