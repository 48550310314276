import alberta from "./alberta.png";
import ariot from "./ariot.png";
import clutch from "./clutch.png";
import daiichi from "./daiichi.png";
import havas from "./havas.png";
import medtronic from "./medtronic.png";
import microsoft from "./microsoft.png";
import pressenger from "./pressenger.png";
import superWorld from "./superworld.png";
import thinkDigital from "./think-digital.png";
import transfr from "./transfr.png";
import ulta from "./ulta.png";
import visionwearX from "./visionwearx.png";
import neom from "./neom.png";

import engineus from "./engineus.svg";
import xrMedicalSolutions from "./xr-medical-solutions.svg";

const clientLogos = {
    alberta,
    ariot,
    clutch,
    daiichi,
    havas,
    medtronic,
    microsoft,
    pressenger,
    superWorld,
    thinkDigital,
    transfr,
    ulta,
    visionwearX,
    neom,

    engineus,
    xrMedicalSolutions,
};

export default clientLogos;
