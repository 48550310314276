import memberImages from "../assets/images/members";
import { Dictionary } from "../global-state";
import { ICaseStudy } from "../global-state/caseStudies";
import { ITeamMember } from "../global-state/teamMembers";
import Ariot from "./case-studies/ariot";
import Engineus from "./case-studies/engineus";
import Inviewer from "./case-studies/inviewer";
import Microsoft from "./case-studies/microsoft";
import Neom from "./case-studies/neom";
import NewtonsRoom from "./case-studies/newtons-room";
import Pressenger from "./case-studies/pressenger";

import ThinkDigital from "./case-studies/think-digital";
import UltaBeauty from "./case-studies/ulta-beauty";
import UniversityOfAlberta from "./case-studies/university-of-alberta";
import VisionWearX from "./case-studies/visionwear-x";
import XRMedicalSolutions from "./case-studies/xr-medical-solutions";

interface IDatabase {
    "case-studies": Dictionary<ICaseStudy>;
    "team-members": Dictionary<ITeamMember>;
    "global-variables": {
        totalClients: number;
        getYearsOfExperience(): number;
    };
}

const DATABASE: IDatabase = {
    "global-variables": {
        totalClients: 40,
        getYearsOfExperience() {
            return new Date().getFullYear() - 2016;
        },
    },
    "case-studies": {
        [Microsoft.id]: Microsoft,
        [Inviewer.id]: Inviewer,
        [NewtonsRoom.id]: NewtonsRoom,
        [Neom.id]: Neom,
        [ThinkDigital.id]: ThinkDigital,
        [VisionWearX.id]: VisionWearX,
        [UltaBeauty.id]: UltaBeauty,
        [UniversityOfAlberta.id]: UniversityOfAlberta,
        [Engineus.id]: Engineus,
        [XRMedicalSolutions.id]: XRMedicalSolutions,
        [Ariot.id]: Ariot,
        [Pressenger.id]: Pressenger,
    },
    "team-members": {
        horacio: {
            name: "Horacio Torrendell",
            position: "Business Development",
            image: memberImages.horacio,
        },
        pablo: {
            name: "Pablo Bounous",
            position: "Engineering Management",
            image: memberImages.pablo,
        },
        gustavo: {
            name: "Gustavo Gonzalez",
            position: "Engineering Management",
            image: memberImages.gus,
        },
        gonzalo: {
            name: "Gonzalo Piffaretti",
            position: "Engineering Management",
            image: memberImages.gonza,
        },
        gerardo: {
            name: "Gerardo Marset",
            position: "Software Development",
            image: memberImages.gera,
        },
        mateo: {
            name: "Mateo Córdoba",
            position: "Software Development",
            image: memberImages.mateo,
        },
        damian: {
            name: "Damian Hernandez",
            position: "Software Development",
            image: memberImages.dimi,
        },
        nahuel: {
            name: "Nahuel Cardozo",
            position: "UX/UI Design",
            image: memberImages.nahue,
        },
        federico: {
            name: "Federico Pascual",
            position: "Marketing",
            image: memberImages.feco,
        },
        joseMaria: {
            name: "José María Patiño",
            position: "3D Design",
            image: memberImages.joseMaria,
        },
        raimundo: {
            name: "Raimundo Gallino",
            position: "Technical Artist",
            image: memberImages.raimundo,
        },
        santiago: {
            name: "Santiago Febles",
            position: "QA & Customer Success",
            image: memberImages.santiago,
        },
        susana: {
            name: "Susana Cipriota",
            position: "Advisor",
            image: memberImages.susana,
        },
        german: {
            name: "German Almaraz",
            position: "3D Animator",
            image: memberImages.german,
        },
        juanzu: {
            name: "Juan Nieto",
            position: "3D Design",
            image: memberImages.juanzu,
        },
        palo: {
            name: "Paloma Cuello",
            position: "Graphic Design",
            image: memberImages.palo,
        },
    },
};

export default DATABASE;
