import create from "zustand";
import { Dictionary } from ".";
import DATABASE from "../database";

export interface ITeamMember {
    name: string;
    position: string;
    image: string;
}

interface IState {
    teamMembers: Dictionary<ITeamMember>;
    init: (value: Dictionary<ITeamMember>) => void;
}

const useTeamMembers = create<IState>()((set) => ({
    teamMembers: DATABASE["team-members"],
    init: (value) => set((state) => ({ teamMembers: value })),
}));

export default useTeamMembers;
