import { FC } from "react";
import styled from "styled-components";
import { ICaseStudy } from "../../../global-state/caseStudies";
import { Image, Text } from "../../../components/UI";
import Grid from "../../../components/grids/Grid";

const SSpecialSectionVisionwearX = styled.div`
    display: grid;
    grid-gap: 96px;
`;

const SText = styled.div`
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SImage = styled.div<{ i: number }>`
    height: 405px;
    margin-top: ${(p) => (p.i % 2 === 0 ? "0" : "36px")};

    ${(p) => p.theme.mobileQuery} {
        height: 200px;
    }
`;

const SpecialSectionVisionwearX: FC<{ caseStudy: ICaseStudy }> = ({
    caseStudy,
}) => {
    function getImages(index: number) {
        return caseStudy?.specialSections?.visionwear?.images[index].map(
            (image, i) => (
                <SImage key={i} i={i}>
                    <Image image={image} />
                </SImage>
            )
        );
    }
    return (
        <SSpecialSectionVisionwearX>
            <SText>
                <Text color="medium" size="H6">
                    <b>
                        Data collection is a critical component of the system.
                    </b>{" "}
                    The software provides a much more thorough data analysis
                    than what a traditional testing environment can provide.
                    This data collection includes:
                    <br />
                    <br />
                    ✅ Learning disorder test results.
                    <br />
                    ✅ Time analysis of test session specific to each test
                    question.
                    <br />
                    ✅ Eye behavior analysis of the test.
                    <br />
                    ✅ Video recording of first-person point of view video of
                    the testing session.
                    <br />
                    ✅ Heart biometric feedback.
                    <br />
                    ✅ EEG brain analysis output.
                    <br />✅ Environment, audio, and test visualization report.
                </Text>
            </SText>
            <Grid columns={4}>{getImages(1)}</Grid>
        </SSpecialSectionVisionwearX>
    );
};

export default SpecialSectionVisionwearX;
