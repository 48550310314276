import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/ariot";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import Press from "../../case-study/Press";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import Clients from "../../case-study/Clients";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import NoClientTag from "../../case-study/NoClientTag";
import History from "../../case-study/History";

const SGap = styled.div`
    height: 100px;
`;

function Ariot() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <NoClientTag caseStudy={caseStudy} />

                <About caseStudy={caseStudy}>
                    ARIOT is an{" "}
                    <b>augmented reality tool for building operators</b>
                    and construction workers, delivering relevant information
                    efficiently - right before their eyes, at the time they need
                    it.
                    <br />
                    <br />
                    Building construction and operation are getting more and
                    more complex, so let's make collaboration and lifecycle
                    information management easier. Use today's tools to be
                    prepared for tomorrow.
                    <br />
                    <br />
                    ARIOT is part of an{" "}
                    <b>
                        EU research program that fosters the development of new
                        technologies
                    </b>
                    , digital systems, and urban services, focusing on building
                    construction in cities.
                </About>

                <SSection gridGap="16px" small>
                    <Clients
                        caseStudy={caseStudy}
                        title="Industrial Research Partners"
                    />
                    <SGap />
                    <Video
                        id="w7Ra2wEqqcc"
                        title="Augmented Reality Digital Building Management"
                        youtube
                    />
                    <SGap />
                    <Video
                        id="5x6FDMmzAWs"
                        title="Remote Collaboration Tool for Construction Facility Management"
                        youtube
                    />
                </SSection>

                <TreeviewRole caseStudy={caseStudy}>
                    We collaborated with ARIOT from 2017-2020 to developed
                    augmented reality software solutions for construction and
                    building management. ARIOT worked with Treeview to develop
                    industry innovations in developing Microsoft Hololens
                    software in the construction industry.
                    <br />
                    <br /> We worked with ARIOT to develop AR technologies that
                    contribute to the urban adoption of digital transformation,
                    aiming to improve EU cities for their inhabitants.
                    <br />
                    <br />
                    We provided a full suite of AR/VR software development
                    services, covering Microsoft Hololens and Mobile AR
                    development. In collaboration with ARIOT, we worked together
                    on multiple iterations of AR/VR solutions and research
                    projects with external partners and government agencies.
                </TreeviewRole>

                <History caseStudy={caseStudy} />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default Ariot;
