import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Image, Text } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";

const SNoClientTag = styled.div`
    min-height: 110px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 270px;

    ${(p) => p.theme.mobileQuery} {
        grid-template-columns: auto 170px;
    }
`;

const SLogo = styled.div<{ clickable: boolean }>`
    width: 100%;
    height: 100%;
    ${(p) =>
        p.clickable &&
        css`
            cursor: pointer;
            transition: 0.3s;
            :hover {
                transform: scale(1.1);
            }

            ${(p) => p.theme.mobileQuery} {
                :hover {
                    transform: scale(1.01);
                }
            }
        `}
`;

const NoClientTag: FC<{ caseStudy: ICaseStudy }> = ({ caseStudy }) => {
    return (
        <SNoClientTag>
            <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                {caseStudy.headingA}
            </Text>
            <SLogo
                clickable={caseStudy.clientTag.link != undefined}
                onClick={() =>
                    caseStudy.clientTag.link &&
                    window.open(caseStudy.clientTag.link)
                }
            >
                <Image
                    contain
                    image={caseStudy.clientTag.logo}
                    imagePosition="left"
                />
            </SLogo>
        </SNoClientTag>
    );
};

export default NoClientTag;
