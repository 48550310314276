import React, { FC, useState } from "react";
import styled from "styled-components";
import { sendEmail } from "../api";
import Page from "../components/Page";
import { Button, InputField, Text, TextArea } from "../components/UI";
import DATABASE from "../database";
import VideoView from "../components/VideoView";
import videos from "../assets/videos";

const SContact = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;
    grid-gap: 32px;

    @media only screen and (min-width: 1200px) {
        margin-top: 100px;
    }

    @media only screen and (max-width: 1000px) {
        width: 100%;
        grid-template-columns: auto;
        grid-gap: 48px;
    }
`;

const SLeft = styled.div`
    display: grid;
    grid-gap: 10px;
    height: min-content;
`;

const SRight = styled.div`
    display: grid;
    grid-gap: 35px;
`;

const SRow = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
`;

interface IState {
    email: string;
    subject: string;
    message: string;
    feedback: string;
}

const defaultState: IState = {
    email: "",
    subject: "",
    message: "",
    feedback: "",
};

const Contact: FC = () => {
    const [state, set] = useState<IState>(defaultState);
    const [loading, setLoading] = useState(false);

    async function validateHandler() {
        const emailRegex =
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const emailIsValid = emailRegex.test(state.email);

        if (!emailIsValid) {
            feedback("Invalid email.");
            return;
        }

        if (state.subject === "") {
            feedback("Please enter subject.");
            return;
        }

        setLoading(true);

        {
            // Identify User with segment.
            window.analytics.identify({
                email: state.email,
                subject: state.subject,
                message: state.message,
            });
        }

        {
            // Send email.
            await sendEmail(state.email, state.subject, state.message);
        }

        {
            // Reset.
            set({
                ...defaultState,
                feedback: "Message Sent.",
            });
            setLoading(false);
        }
    }

    function feedback(feedback: string): void {
        set((p) => ({ ...p, feedback }));
    }

    return (
        <Page title="Contact">
            <SContact>
                <SLeft>
                    <Text color="light" size="H4" circularFont>
                        Become a Leading Innovator in Your Industry
                    </Text>
                    <Text gradient size="H3" circularFont oneline>
                        Schedule a Meeting Now!
                    </Text>
                    <VideoView
                        playOnce
                        video={videos.homeHowWeWork}
                        style={{ height: 490 }}
                        cover
                    />
                </SLeft>

                <SRight>
                    <InputField
                        value={state.email}
                        onChange={(email) => set((p) => ({ ...p, email }))}
                        title="Email Address*"
                        placeholder="Your Email"
                        secondGradient
                    />
                    <InputField
                        value={state.subject}
                        onChange={(subject) => set((p) => ({ ...p, subject }))}
                        title="Subject*"
                        placeholder="Subject"
                        secondGradient
                    />
                    <TextArea
                        value={state.message}
                        onChange={(message) => set((p) => ({ ...p, message }))}
                        title="Your Message"
                        placeholder="Tell us about your company."
                        secondGradient
                    />
                    <SRow>
                        <Text color="light" oneline size="H5" gradientB>
                            {loading ? "Sending..." : state.feedback}
                        </Text>
                        <Button
                            onClick={() => validateHandler()}
                            style={{ marginLeft: "auto" }}
                        >
                            Send Message
                        </Button>
                    </SRow>
                </SRight>
            </SContact>
        </Page>
    );
};

export default Contact;
