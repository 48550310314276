import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "./UI";
import clientReviews from "../database/clientReviews";
import ClientReview from "./cards/ClientReview";
import { IClientTag } from "../global-state/caseStudies";
import { useIsMobile } from "../hooks";

const SHorizontalCarousel = styled.div`
    display: grid;
    grid-template-rows: 305px min-content;
    grid-gap: 24px;
`;

const SScroller = styled.div`
    overflow: hidden;
    overflow-x: auto;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const SLayout = styled.div`
    width: min-content;
    display: flex;
    height: 100%;
    gap: 48px;
    padding-right: 608px;
`;

const SButtons = styled.div`
    display: flex;
    justify-content: end;
    gap: 16px;
`;

const HorizontalCarousel: FC<{ startIndex?: number }> = ({ startIndex }) => {
    const [selected, setSelected] = useState(-1);
    const isMobile = useIsMobile();
    const lenght = useMemo(
        () => Object.keys(clientReviews).length,
        [clientReviews]
    );
    const getCards = () => {
        let list = Object.values(clientReviews);
        if (startIndex) {
            const start = list.slice(0, startIndex);
            const end = list.slice(startIndex, list.length);

            list = end.concat(start);
        }
        return list.map((tag, i) => (
            <CardHolder focus={i === selected} clientTag={tag} />
        ));
    };

    const doScroll = (direction: 1 | -1) =>
        setSelected((p) => ((p === -1 ? 0 : p) + direction + lenght) % lenght);

    return (
        <SHorizontalCarousel>
            <SScroller>
                <SLayout>{getCards()}</SLayout>
            </SScroller>
            {!isMobile && (
                <SButtons>
                    <Button onClick={() => doScroll(-1)}>{"<"}</Button>
                    <Button onClick={() => doScroll(1)}>{">"}</Button>
                </SButtons>
            )}
        </SHorizontalCarousel>
    );
};

const CardHolder: FC<{ focus: boolean; clientTag: IClientTag }> = ({
    focus,
    clientTag,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!focus || !ref.current) return;

        ref.current.scrollIntoView({
            behavior: "smooth",
            inline: "start",
            block: "nearest",
        });
    }, [focus, ref.current]);

    return (
        <div ref={ref}>
            <ClientReview clientTag={clientTag} />
        </div>
    );
};

export default HorizontalCarousel;
