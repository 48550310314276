import { FC } from "react";
import styled from "styled-components";
import Grid from "../../components/grids/Grid";
import { Image, Text } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile } from "../../hooks";

const SDevelopmentServices = styled.div`
    display: grid;
    grid-gap: 16px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    width: min-content;
    grid-gap: 10px;
`;

const DevelopmentServices: FC<{
    caseStudy: ICaseStudy;
    title?: string;
    titleBlue?: boolean;
}> = ({ caseStudy, title, titleBlue }) => {
    const isMobile = useIsMobile();
    return (
        <SDevelopmentServices>
            {caseStudy?.developmentServices?.useTreeviewRole ? (
                <SRow>
                    <Text circularFont oneline size="H3" color="primary">
                        {"<o>"}
                    </Text>
                    <Text circularFont oneline color="light" size="H3">
                        Treeview's Role
                    </Text>
                </SRow>
            ) : (
                <Text
                    circularFont
                    oneline
                    color="light"
                    gradient={titleBlue}
                    size="H3"
                >
                    {title ?? "👨🏻‍💻 Development Services"}
                </Text>
            )}
            <Text color="medium" size="H6">
                {caseStudy?.developmentServices?.description}
            </Text>
            <br />
            {caseStudy?.developmentServices?.services && (
                <Grid columns={isMobile ? 1 : 2}>
                    {caseStudy.developmentServices.services.map((s) => (
                        <Service key={s.title} {...s} />
                    ))}
                </Grid>
            )}
            {caseStudy?.developmentServices?.image && (
                <div style={{ margin: isMobile ? "auto" : "64px auto" }}>
                    <Image
                        image={caseStudy.developmentServices.image.image}
                        contain
                        style={{
                            width: isMobile
                                ? "90vw"
                                : caseStudy.developmentServices.image.width,
                            height: caseStudy.developmentServices.image.height,
                            margin: "auto",
                        }}
                    />
                </div>
            )}
        </SDevelopmentServices>
    );
};

const SService = styled.div``;

const Service: FC<{
    title: string;
    description?: string | any;
    items: string[];
    noTick?: boolean;
}> = ({ title, description, items, noTick }) => {
    return (
        <SService>
            <Text circularFont color="light" size="H3">
                {title}
            </Text>
            <br />
            {description && (
                <Text color="medium" size="H6">
                    {description}
                </Text>
            )}
            <br />
            {items.map((i) => (
                <Text key={i} color="medium" size="H6">
                    {(noTick ? "" : "✅ ") + i}
                </Text>
            ))}
        </SService>
    );
};

export default DevelopmentServices;
