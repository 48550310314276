import { FC } from "react";
import styled from "styled-components";
import { SSection } from "../home/";
import Grid from "../../components/grids/Grid";
import { Button, Image, Text } from "../../components/UI";
import technologyLogos from "../../assets/images/technology-logos";
import { useIsMobile, useRouter } from "../../hooks";
import { Paths } from "../../context/RouterContext";

const SOurExpertise = styled.div`
    width: 967px;
    margin: auto;
    display: grid;
    grid-gap: 32px;

    ${(p) => p.theme.mobileQuery} {
        width: inherit;
    }
`;

const STech = styled.div`
    height: 52px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;

const tech = {
    unity: { logo: technologyLogos.unity, link: "https://unity.com/" },
    meta: { logo: technologyLogos.meta, link: "https://developer.oculus.com/" },
    magicLeap: {
        logo: technologyLogos.magicLeap,
        link: "https://www.magicleap.com/en-us/",
    },
    microsoftHololens: {
        logo: technologyLogos.microsoftHololens,
        link: "https://www.microsoft.com/en-us/hololens",
    },
    visionPro: {
        logo: technologyLogos.visionPro,
        link: "https://www.apple.com/apple-vision-pro/",
    },
    pico: { logo: technologyLogos.pico, link: "https://www.picoxr.com/" },
    vive: { logo: technologyLogos.vive, link: "https://www.vive.com/us/" },
    lenovo: {
        logo: technologyLogos.lenovo,
        link: "https://www.lenovo.com/us/en/thinkrealityvrx",
    },
};

const OurExpertise: FC = (props) => {
    const isMobile = useIsMobile();
    const router = useRouter();
    const items = Object.entries(tech).map(([key, tech]) => (
        <STech key={key} onClick={() => window.open(tech.link)}>
            <Image
                image={tech.logo}
                contain={!(key === "firebase" || key === "magicLeap")}
                style={{ height: "52px" }}
            />
        </STech>
    ));

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : "192px" }}>
            <div>
                <Text gradient size="H2" oneline circularFont>
                    Technologies We Work With
                </Text>
            </div>
            <SOurExpertise>
                <Grid columns={isMobile ? 3 : 4}>{items.slice(0, 10)}</Grid>
                <Grid columns={isMobile ? 3 : 4}>{items.slice(10, 14)}</Grid>
            </SOurExpertise>
            <div
                style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    marginTop: 20,
                }}
            >
                <Button onClick={() => router.setPath(Paths.ourWork)}>
                    Discover Our Work!
                </Button>
            </div>
        </SSection>
    );
};

export default OurExpertise;
