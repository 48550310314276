import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import FullWidthContainer from "../components/FullWidthContainer";
import ImageCarousel from "../components/ImageCarousel";
import Page from "../components/Page";
import { Text } from "../components/UI";
import Vimeo from "../components/Vimeo";
import { Paths } from "../context/RouterContext";
import useCaseStudies, { ICaseStudy } from "../global-state/caseStudies";
import { useIsMobile, useRouter } from "../hooks";
import About from "./case-study/About";
import Clients from "./case-study/Clients";
import ClientTag from "./case-study/ClientTag";
import DevelopmentServices from "./case-study/DevelopmentServices";
import MoreCaseStudies from "./case-study/MoreCaseStudies";
import Press from "./case-study/Press";
import SpecialSectionVisionwearX from "./case-study/special-sections/Visionwearx";
import TreeviewRole from "./case-study/TreeviewRole";
import { SSection } from "./home/";
import { Helmet } from "react-helmet";

const CaseStudy: FC = ({}) => {
    const router = useRouter();
    const caseStudies = useCaseStudies();
    const caseStudy = caseStudies.caseStudies[router.getParam("case-study")];
    const [refresh, setRefresh] = useState(true);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!caseStudy) router.setPath(Paths.ourWork);

        setRefresh(false);
        setTimeout(() => setRefresh(true), 100);
    }, [caseStudy]);

    function getSpecialSections() {
        if (!caseStudy.specialSections) return;

        if (caseStudy.specialSections.visionwear)
            return <SpecialSectionVisionwearX caseStudy={caseStudy} />;
    }

    if (!refresh) return null;

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection style={{ gridGap: isMobile ? "32px" : "64px" }}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <ClientTag caseStudy={caseStudy} />

                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>

                <About caseStudy={caseStudy} />

                {caseStudy.headingB && (
                    <Text
                        circularFont
                        gradient
                        size="H3"
                        style={{ width: "auto" }}
                    >
                        {caseStudy.headingB}
                    </Text>
                )}

                {caseStudy.treeviewRole && (
                    <TreeviewRole caseStudy={caseStudy} />
                )}

                {caseStudy.developmentServices && (
                    <DevelopmentServices caseStudy={caseStudy} />
                )}

                {caseStudy.clients && !isMobile && (
                    <Clients caseStudy={caseStudy} />
                )}

                {getSpecialSections()}

                {caseStudy.videoReel && (
                    <FullWidthContainer height="85vh">
                        <Vimeo id={caseStudy.videoReel} />
                    </FullWidthContainer>
                )}

                {caseStudy.press && <Press caseStudy={caseStudy} />}

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
};

export default CaseStudy;
