import a from "./a.png";
import b from "./b.png";
import c from "./c.png";
import d from "./d.png";
import e from "./e.png";
import f from "./f.png";
import g from "./g.png";
import h from "./h.png";
import i from "./i.png";
import j from "./j.png";
import k from "./k.png";
import l from "./l.png";

const behindTheScenesImages = {
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
};

export default behindTheScenesImages;
