import clientLogos from "../../../assets/images/client-logos";
import { ICaseStudy } from "../../../global-state/caseStudies";

const Transfr: ICaseStudy = {
    id: "transfr",
    // customPath:

    preview: {
        title: "",
        description: "",
        // image: require("./preview.png"),
    },

    carousel: {
        images: [],
        imagePosition: "top",
    },

    clientTag: {
        logo: clientLogos.transfr,
        clientPicture: require("./client-picture.png"),
        clientName: "Pau Rotger\nRelease Manager",
        clientCommentSummary:
            "They’re professionals with a lot of experience and skills that have helped us reach the quality we wanted.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview#review-1777441",
        link: "https://transfrinc.com/",
    },

    headingA: "",

    about: {
        firstDescription: "",
        secondDescription: "",
        titleCards: [
            {
                title: "",
                subTitle: "",
            },
            {
                title: "",
                subTitle: "",
            },
            {
                title: "",
                subTitle: "",
            },
        ],
    },

    developmentServices: {
        useTreeviewRole: true,
        description: "",
    },
};

export default Transfr;
