import React from "react";
import styled from "styled-components";
import Page from "../components/Page";
import { Button, Image, Text } from "../components/UI";
import { Paths } from "../context/RouterContext";
import { useRouter } from "../hooks";

import image from "../assets/images/404.png";

const SNotFound = styled.div`
    display: grid;
    grid-template-columns: auto 570px;
    height: 570px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;
`;

const SLeft = styled.div`
    display: grid;
    grid-gap: 11px;
    align-content: center;
`;

const NotFound = () => {
    const router = useRouter();
    return (
        <Page title="Not Found">
            <SNotFound>
                <SLeft>
                    <Text circularFont color="light" size="H4" oneline>
                        Error 404
                    </Text>
                    <Text circularFont gradient oneline size="H3">
                        Page Not Found
                    </Text>
                    <Text size="H6" color="medium">
                        The link you clicked may be broken or the page <br />{" "}
                        may have been broken or renamed.
                    </Text>
                    <Button
                        style={{ marginTop: "16px" }}
                        onClick={() => router.setPath(Paths.home)}
                    >
                        Back to Home
                    </Button>
                </SLeft>
                <Image image={image} contain />
            </SNotFound>
        </Page>
    );
};

export default NotFound;
