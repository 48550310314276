import { FC } from "react";
import styled, { css } from "styled-components";
import { SSection } from "./";
import Grid from "../../components/grids/Grid";
import { SVG, Text } from "../../components/UI";
import clientLogos from "../../assets/images/client-logos";
import { useIsMobile } from "../../hooks";
import DATABASE from "../../database";

const SOurClients = styled.div`
    width: 1050px;
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 100%;
    }
`;

export const SClient = styled.div<{ disabled?: boolean }>`
    height: 52px;
    cursor: pointer;
    transition: 0.3s;
    ${(p) =>
        !p.disabled &&
        css`
            :hover {
                transform: scale(1.1);
            }
        `}
`;

const clients = {
    microsoft: {
        logo: clientLogos.microsoft,
        link: "https://treeview.studio/digitaltwin",
        contain: false,
    },
    ulta: {
        logo: clientLogos.ulta,
        link: "https://www.ulta.com/innovation/",
        contain: true,
    },
    medtronic: {
        logo: clientLogos.medtronic,
        link: "https://www.medtronic.com/",
        contain: true,
    },
    havas: {
        logo: clientLogos.havas,
        link: "https://www.havasgroup.com/",
        contain: true,
    },
    neom: {
        logo: clientLogos.neom,
        link: "https://www.neom.com/en-us",
        contain: true,
    },
    transfr: {
        logo: clientLogos.transfr,
        link: "https://transfrinc.com/",
        contain: true,
    },
    alberta: {
        logo: clientLogos.alberta,
        link: "https://www.ualberta.ca/index.html",
        contain: true,
    },
    daiichi: {
        logo: clientLogos.daiichi,
        link: "https://www.daiichisankyo.com/",
        contain: true,
    },
};

const OurClients: FC = (props) => {
    const isMobile = useIsMobile();

    const items = Object.entries(clients).map(([key, client]) => (
        <SClient
            key={key}
            onClick={() => {
                window.open(client.link);
            }}
        >
            <SVG image={client.logo} contain={client.contain} />
        </SClient>
    ));

    return (
        <SSection style={{ marginTop: 120, marginBottom: 180, gridGap: 64 }}>
            <Text gradient size="H2" oneline circularFont>
                +{DATABASE["global-variables"].totalClients} Happy Clients
                Including
            </Text>
            <SOurClients>
                <Grid columns={isMobile ? 2 : 4}>{items}</Grid>
            </SOurClients>
        </SSection>
    );
};

export default OurClients;
