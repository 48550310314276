import videos from "../assets/videos";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import WhyUsContent from "./why-us/WhyUs";

function WhyUs() {
    return (
        <Page title="Why Us">
            <PageTitle
                title={"Seasoned Veterans in\nAR/VR Development"}
                description="Treeview has a proven track record of success in the AR/VR space and a wealth of experience and expertise when it comes to developing augmented and virtual reality applications."
                video={videos.whyUsHero}
            />
            <WhyUsContent />
        </Page>
    );
}

export default WhyUs;
