import { resolve } from "path";

const sendEmailCloudFunction =
    "https://us-central1-treeview-web.cloudfunctions.net/sendEmail ";
const addContactCloudFunction =
    "https://us-central1-treeview-web.cloudfunctions.net/addContact";
const subscribeToMailchimpFunction =
    "https://us-central1-treeview-web.cloudfunctions.net/subscribeToMailchimp";

const makeRequest = async (url: string, body: any): Promise<any> =>
    new Promise(async (resolve, reject) => {
        const response = await (
            await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            })
        ).text();

        resolve(response);
    });

export const sendEmail = (
    from: string,
    subject: string,
    text: string
): Promise<any> =>
    new Promise(async (resolve, reject) => {
        const response = await makeRequest(sendEmailCloudFunction, {
            subject,
            to: "sales@treeview.studio",
            text: `Message from: ${from}\nContent:\n${text}`,
        });

        resolve(response);
    });

export const addContact = (
    path: string,
    email: string,
    contact: any
): Promise<any> =>
    new Promise(async (resolve, reject) => {
        const response = await makeRequest(addContactCloudFunction, {
            path,
            email,
            contact,
        });

        resolve(response);
    });

export const subscribeToMailchimp = (email: string): Promise<any> =>
    new Promise(async (resolve, reject) => {
        const response = await makeRequest(subscribeToMailchimpFunction, {
            email,
        });

        resolve(response);
    });
