import React, { FC, useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useInView } from "react-hook-inview";
import styled, { CSSProperties, css, keyframes } from "styled-components";
import { IVideoFile } from "../assets/videos";
import { key } from "firebase-key";

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: translateY(64px);
	}
    5% {
		opacity: 0;
    }
	100% {
		opacity: 1;
	}
`;

const SVideoView = styled.div<{ noAnimation?: boolean }>`
    height: 100%;

    ${(p) =>
        !p.noAnimation &&
        css`
            animation: ${fadeInAnimation} ease 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        `}
`;

interface IProps {
    style?: CSSProperties;
    video?: IVideoFile;
    playOnce?: boolean;
    noAnimation?: boolean;
    cover?: boolean;
}

const VideoView: FC<IProps> = (props) => {
    const [ref, isVisible] = useInView({ threshold: 0.5 });
    const [id] = useState(key());

    useEffect(() => {
        if (!props.playOnce) return;

        const video = document.getElementById(id);
        //@ts-ignore
        if (isVisible) video.play();
    }, [isVisible]);

    return (
        <SVideoView {...props}>
            <video
                width="100%"
                height="100%"
                autoPlay
                style={props.cover ? { objectFit: "cover" } : {}}
                loop={!props.playOnce}
                muted
                playsInline
                ref={ref}
                id={id}
            >
                <source
                    src={props?.video?.mp4}
                    type='video/mp4; codecs="hvc1"'
                />
                <source src={props?.video?.webm} type="video/webm" />
            </video>
        </SVideoView>
    );
};

export default VideoView;
