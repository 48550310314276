import Engineus from "./case-studies/engineus";
import Medtronic from "./case-studies/medtronic";
import Neom from "./case-studies/neom";
import ThinkDigital from "./case-studies/think-digital";
import Transfr from "./case-studies/transfr";
import UltaBeauty from "./case-studies/ulta-beauty";
import UniversityOfAlberta from "./case-studies/university-of-alberta";
import VisionWearX from "./case-studies/visionwear-x";
import XRMedicalSolutions from "./case-studies/xr-medical-solutions";

const clientReviews = {
    thinkDigital: ThinkDigital.clientTag,
    ultaBeauty: UltaBeauty.clientTag,
    neom: Neom.clientTag,
    universityOfAlberta: UniversityOfAlberta.clientTag,
    xrMedicalSolutions: XRMedicalSolutions.clientTag,
    enginous: Engineus.clientTag,
    medtronic: Medtronic.clientTag,
    transfr: Transfr.clientTag,
    visionwearX: VisionWearX.clientTag,
};

export default clientReviews;
