import { FC } from "react";
import styled from "styled-components";
import Grid from "../../components/grids/Grid";
import { Button, GradientBorder, Image, Text } from "../../components/UI";
import { useIsMobile } from "../../hooks";
import { SSection } from "../home/";
import blogImages from "./blog-images";

const SBlog = styled.div`
    display: grid;
    grid-gap: 30px;
    place-items: center;
`;

const Blog: FC = () => {
    const isMobile = useIsMobile();

    const items = [
        {
            image: blogImages.a,
            title: "Spatial Computing: Complete Guide",
            link: "https://treeview.studio/blog/spatial-computing-complete-guide/",
            useBorder: false,
        },
        {
            image: blogImages.b,
            title: "Difference Between AR/VR & Spatial Computing",
            link: "https://treeview.studio/blog/difference-between-ar-vr-and-spatial-computing/",
        },
        {
            image: blogImages.c,
            title: "Best Practices in Mixed Reality Design",
            link: "https://treeview.studio/blog/designing-for-mixed-reality/",
        },
    ].map((i) => <BlogCard {...i} key={i.title} />);

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : 96 }}>
            <div style={{ display: "flex", gap: 17, flexDirection: "column" }}>
                <Text gradient size="H2" oneline circularFont>
                    Thought Leaders in
                    <br />
                    Spatial Computing
                </Text>
            </div>
            <SBlog>
                <Grid
                    style={{
                        placeItems: "center",
                        marginBottom: "40px",
                        marginTop: 32,
                    }}
                >
                    {items}
                </Grid>
                <Text color="light" size="H4" circularFont center>
                    Become an expert in spatial computing and
                    <br />
                    make sure to stay up-to-date with our specialists’ in-depth
                    articles.
                </Text>
                <Button
                    onClick={() =>
                        (window.location.href = "https://treeview.studio/blog")
                    }
                >
                    Read our Blog!
                </Button>
            </SBlog>
        </SSection>
    );
};

const SBlogCard = styled.div`
    width: 360px;
    /* height: 261px; */
    display: grid;
    grid-gap: 13px;
    grid-template-rows: 203px min-content;

    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;

const BlogCard: FC<{
    image: string;
    title: string;
    link: string;
    useBorder?: boolean;
}> = ({ image, title, link, useBorder = true }) => {
    const imageComponent = <Image image={image} contain round />;
    return (
        <SBlogCard onClick={() => window.open(link, "_blank")}>
            {useBorder ? (
                <GradientBorder>{imageComponent}</GradientBorder>
            ) : (
                imageComponent
            )}
            <Text circularFont size="H6" color="light">
                {title}
            </Text>
        </SBlogCard>
    );
};

export default Blog;
