import { Dictionary } from "../../global-state";

import aboutUsHero from "./about-us-hero";
import aboutUsHowWeDoBusiness from "./about-us-how-we-do-business";
import aboutUsWhereWeAreGoing from "./about-us-where-we-are-going";

import homeClients from "./home-clients";
import homeExperience from "./home-experience";
import homeHero from "./home-hero";
import homeHowWeWork from "./home-how-we-work";
import homeTopArVrStudio from "./home-top-ar-vr-studio";

import services3dDesign from "./services-3d-design";
import servicesArVrDevelopment from "./services-ar-vr-development";
import servicesHeroLightBulb from "./services-hero-lightbulb";
import servicesUiUx from "./services-ui-ux";

import whyUsHero from "./why-us-hero";

import ourWorkHero from "./our-work-hero";

export interface IVideoFile {
    mp4: any;
    webm: any;
}

const videos = {
    aboutUsHero,
    aboutUsHowWeDoBusiness,
    aboutUsWhereWeAreGoing,

    homeClients,
    homeExperience,
    homeHero,
    homeTopArVrStudio,
    homeHowWeWork,

    services3dDesign,
    servicesArVrDevelopment,
    servicesHeroLightBulb,
    servicesUiUx,

    whyUsHero,

    ourWorkHero,
};

export default videos;
