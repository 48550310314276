import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/pressenger";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import Press from "../../case-study/Press";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import Clients from "../../case-study/Clients";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import NoClientTag from "../../case-study/NoClientTag";

const SGap = styled.div`
    height: 100px;
`;

function Pressenger() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <NoClientTag caseStudy={caseStudy} />

                <About caseStudy={caseStudy}>
                    <a href="https://pressenger.com/" target="_blank">
                        Pressenger
                    </a>{" "}
                    is a world <b>leader in high-end push notifications</b> for
                    brands, working with clients such as{" "}
                    <a href="https://pressenger.com/" target="_blank">
                        Borussia Dortmund
                    </a>{" "}
                    to bring innovation and drive user engagement between brands
                    and their audiences.
                    <br />
                    <br />
                    Pressenger provides the most innovative, future-proof
                    notification in a visually eye-catching format to suit their
                    clients' digital media strategy. Pressenger's notification
                    solutions improve the quality of communication between
                    client apps and their user base.
                    <br />
                    <br />
                    Forward-thinking and future-proofing are part of
                    Pressenger's core values, which drive R&D initiatives for
                    the future of user-application engagement.
                </About>

                <TreeviewRole caseStudy={caseStudy}>
                    We collaborated with Pressenger to explore and prototype the
                    future of push notifications in the next computing platform:
                    Spatial Computing. Together with Pressenger, we explored the
                    vision of how users would engage with notifications as an
                    extension of their smartphones.
                    <br />
                    <br />
                    We developed multiple prototypes for Microsoft HoloLens 2
                    that enabled Pressenger to explore user behavior and brand
                    insights for the future of user notification engagement.
                    <br />
                    <br />
                    Pressenger strives to lead the next generation of user
                    engagement through notifications and aims to provide
                    future-proof services and solutions to their clients. By
                    collaborating with us, Pressenger explored today’s spatial
                    computing technology and is prepared for the next chapter of
                    human-computer consumer devices.
                </TreeviewRole>
                <Video id="902891533?h=3540c39437" />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default Pressenger;
