import { FC } from "react";
import styled from "styled-components";
import { Text, Image } from "../UI";
import { useIsMobile } from "../../hooks";

const SImageCard = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-rows: 359px min-content;
    * {
        width: 100%;
    }

    ${(p) => p.theme.mobileQuery} {
        grid-gap: 0;
        margin-top: -30px;
    }
`;

export interface IImageCard {
    image: string;
    caption?: string;
}

const ImageCard: FC<IImageCard> = ({ image = "", caption }) => {
    const isMobile = useIsMobile();
    return (
        <SImageCard>
            <Image image={image} contain />
            {caption && (
                <Text
                    style={{ fontStyle: "italic" }}
                    color="medium"
                    size="H7"
                    center={isMobile}
                >
                    {caption}
                </Text>
            )}
        </SImageCard>
    );
};

export default ImageCard;
