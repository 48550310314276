import unity from "./unity.png";
import arKit from "./ar-kit.png";
import arCore from "./ar-core.png";
import meta from "./meta.png";
import steamVr from "./steam-vr.png";
import firebase from "./firebase.png";
import visionPro from "./vision-pro.png";
import vive from "./vive.png";
import magicLeap from "./magic-leap.png";
import microsoftHololens from "./microsoft-hololens.png";
import sparkAr from "./spark-ar.png";
import googleCloud from "./google-cloud.png";
import lenovo from "./lenovo.png";
import pico from "./pico.png";
import mixedReality from "./mixed-reality.png";
import blender from "./blender.png";

const technologyLogos = {
    unity,
    arKit,
    arCore,
    meta,
    steamVr,
    firebase,
    vive,
    magicLeap,
    microsoftHololens,
    sparkAr,
    googleCloud,
    pico,
    mixedReality,
    blender,
    lenovo,
    visionPro,
};

export default technologyLogos;
