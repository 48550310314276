import { FC } from "react";
import styled, { css } from "styled-components";
import { useIsMobile } from "../../hooks";

const SOffsetGrid = styled.div`
    margin: auto;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
`;

const SColumn = styled.div<{ left?: boolean }>`
    display: grid;
    grid-gap: 36px;

    ${(p) =>
        p.left &&
        css`
            padding-bottom: 39px;
        `}
    ${(p) =>
        !p.left &&
        css`
            padding-top: 39px;
        `}

    height: min-content;
`;

const SMobileGrid = styled.div`
    display: grid;
    grid-gap: 36px;
`;

interface IProps {
    items: React.ReactNode[];
}

export const OffsetGrid: FC<IProps> = (props) => {
    const isMobile = useIsMobile();

    return isMobile ? (
        <SMobileGrid>{props.items}</SMobileGrid>
    ) : (
        <SOffsetGrid>
            <SColumn left>
                {props.items.filter((node, i) => i % 2 === 0)}
            </SColumn>
            <SColumn>{props.items.filter((node, i) => i % 2 != 0)}</SColumn>
        </SOffsetGrid>
    );
};
