import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { SVG } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import BasicCard from "../../components/cards/BasicCard";
import icons from "../../assets/icons";
import { useIsMobile } from "../../hooks";

const SHistory = styled.div`
    display: grid;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    grid-gap: 36px;
    grid-template-columns: repeat(3, 1fr);

    ${(p) => p.theme.mobileQuery} {
        width: 90vw;
        margin-left: 1px;
        grid-template-columns: 1fr;
        place-items: center;
    }
`;

const SArroy = styled.div`
    position: absolute;
    width: 32px;
    height: 32px;
    right: -2px;
    top: 50%;
    transform: translate(100%, -50%);
`;

const SHolder = styled.div`
    position: relative;
    display: flex;

    ${(p) => p.theme.mobileQuery} {
        max-width: 400px;
    }
`;

interface IProps extends PropsWithChildren {
    caseStudy: ICaseStudy;
}

const History: FC<IProps> = ({ caseStudy }) => {
    const isMobile = useIsMobile();
    return (
        <SHistory>
            {caseStudy.history?.cards?.length === 3 &&
                caseStudy.history.cards.map((card, i) => (
                    <SHolder>
                        {!isMobile && i != 2 && (
                            <SArroy>
                                <SVG
                                    image={icons.arrowRight}
                                    color="light"
                                    contain
                                />
                            </SArroy>
                        )}
                        <BasicCard
                            description={card.description}
                            image={card.icon}
                        />
                    </SHolder>
                ))}
        </SHistory>
    );
};

export default History;
