import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import engineusClientLogos from "./clients";
import videoPreview from "../../../assets/videos/case-studies/engineus";
import imageIcons from "../../../assets/images/icons";

const Engineus: ICaseStudy = {
    id: "engineus",
    customPath: Paths.engineus,

    preview: {
        title: "Optical Technology",
        description:
            "Engineus offers one of the most advanced augmented reality facial recognition and optical measurement technologies available on the market.",
        image: require("./preview.png"),
        video: videoPreview,
    },

    carousel: {
        images: [require("./carousel/a.png")],
    },

    clientTag: {
        logo: clientLogos.engineus,
        clientPicture: require("./client-picture.png"),
        clientName: "Jeffrey Williamson\nFounder - CEO",
        clientComment:
            "We couldn't have asked for better partners. They take complete ownership of their work and go the extra mile when necessary to meet our expectations. They're a phenomenal team to work with overall. They're not only extremely innovative but also very forward-thinking.",
        clientCommentSummary:
            "They're not only extremely innovative, but also very forward-thinking.",
        stars: "5.0",
        clutch: "https://clutch.co/profile/treeview?page=1#review-959132",
    },

    headingA: "Revolutionary AR Technology for the\nEyewear Industry",

    about: {
        firstDescription:
            "Engineus’ augmented reality platform is one of the leaders in virtual try-on technology for the eyewear industry. The startup offers one of the most advanced facial recognition and optical measurement solutions on the market.",
    },

    headingB:
        "Successful long-term development partnership building\na leading AR/VR startup of the eyewear industry",

    treeviewRole: {
        description:
            "Treeview has been VisionWEAR’s primary development partner since its initial MVP in 2019. Since the launch of the MVP, Treeview has continued developing the platform and helping VisionWEAR grow its technological offering and B2B client base.  VisionWEAR is now one of the leading augmented reality technology providers of the eyewear industry.",
        cards: [
            {
                targetValue: 4,
                unit: "+",
                description: "Years Collaborating",
            },
            {
                targetValue: 10,
                unit: "+",
                description: "B2B Clients Served",
            },
            {
                targetValue: 5,
                unit: "+",
                description: "Platforms Deployed",
            },
        ],
    },

    developmentServices: {
        description:
            "Treeview provides a full suite of development services, including AR/VR development, product design, and project management. Treeview is also leading VisionWEAR’s R&D initiatives to provide grown breaking technologies applying AR/VR technologies to the eyewear industry.",
        services: [
            {
                title: "Virtual Reality",
                description: "",
                items: ["Oculus Quest 1 & 2", "Pico"],
            },
            {
                title: "Augmented Reality",
                description: "",
                items: ["ARKit", "ARCore"],
            },
            {
                title: "Web Development",
                description: "",
                items: ["ReactJS", "ThreeJS", "OpenCV"],
            },
            {
                title: "Backend Development",
                description: "",
                items: ["Firebase", "Google CLoud", "GitLab + Unity DevOps"],
            },
        ],
    },

    clients: [
        { image: engineusClientLogos.lensync, link: "https://lensync.com/" },
        { image: engineusClientLogos.a, link: "https://ochovision.com/" },
        {
            image: engineusClientLogos.jonasPaul,
            link: "https://jonaspauleyewear.com/",
        },
        { image: engineusClientLogos.sapeur, link: "" },
        {
            image: engineusClientLogos.mask,
            link: "https://www.modernoptical.com/",
        },
        { image: engineusClientLogos.nerdLine, link: "" },
        { image: engineusClientLogos.tHenri, link: "https://thenri.com/" },
    ],

    history: {
        cards: [
            {
                icon: imageIcons.plant,
                description:
                    "Founded in 2019 with a vision of using AR/VR to solve problems in the eyewear Industry.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "Collaboration of over 4+ years Developing and shipping enterprise AR software to 10+ clients",
            },
            {
                icon: imageIcons.cup,
                description:
                    "Leading AR/VR startup technology in optometry and eyewear industry.",
            },
        ],
    },
};

export default Engineus;
