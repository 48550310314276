import a from "./a.png";
import b from "./b.png";
import c from "./c.png";
import d from "./d.png";
import e from "./e.png";
import f from "./f.png";

const blogImages = {
    a,
    b,
    c,
    d,
    e,
    f,
};

export default blogImages;
