import a from "./a.svg";
import jonasPaul from "./jonas-paul.svg";
import lensync from "./lensync.svg";
import mask from "./mask.svg";
import nerdLine from "./nerd-line.svg";
import sapeur from "./sapeur.svg";
import tHenri from "./t-henri.svg";

const engineusClientLogos = {
    a,
    jonasPaul,
    lensync,
    mask,
    sapeur,
    nerdLine,
    tHenri,
};

export default engineusClientLogos;
