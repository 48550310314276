import { FC } from "react";
import styled from "styled-components";
import { Text } from "../../components/UI";
import Vimeo from "../../components/Vimeo";
import Youtube from "../../components/Youtube";
import { useIsMobile } from "../../hooks";

const SVideo = styled.div`
    display: grid;
    grid-gap: 16px;
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SContainer = styled.div`
    width: 100%;
`;

const Video: FC<{
    id: string;
    title?: string;
    whiteTitle?: boolean;
    style?: React.CSSProperties;
    youtube?: boolean;
    customAspectRatio?: string;
}> = ({ id, title, whiteTitle, style, youtube, customAspectRatio }) => {
    const isMobile = useIsMobile();
    return (
        <SVideo style={style}>
            {title && (
                <Text
                    circularFont
                    color="light"
                    gradient={!whiteTitle}
                    size="H3"
                    oneline={!isMobile}
                    style={{ width: "100%" }}
                >
                    {title}
                </Text>
            )}
            <SContainer style={{ aspectRatio: customAspectRatio ?? "16/9" }}>
                {youtube ? <Youtube id={id} /> : <Vimeo id={id} />}
            </SContainer>
        </SVideo>
    );
};

export default Video;
