import { FC, useEffect, useState } from "react";
import { useInView } from "react-hook-inview";
import styled from "styled-components";
import { GradientBorder, Text } from "../UI";

const SNumberCard = styled.div`
    display: grid;
    place-items: center;
    margin: auto;
`;

export interface INumberCard {
    targetValue?: number;
    unit?: string;
    left?: boolean;
    description?: string;
}

const NumberCard: FC<INumberCard> = ({
    targetValue = 0,
    unit = "",
    description = "",
    left = false,
}) => {
    const [value, setValue] = useState(0);
    const [ref, isVisible] = useInView();

    useEffect(() => {
        if (!isVisible || value != 0) return;

        setValue(0);

        const iterations = 10;
        const increment = targetValue / 10;
        const duration = 1; // In seconds.

        const interval = setInterval(() => {
            setValue((p) => {
                p = Math.ceil(p + increment);
                if (p >= targetValue) p = targetValue;
                return p;
            });
        }, (duration * 1000) / iterations);

        // return () => clearInterval(interval);
    }, [targetValue, isVisible]);

    return (
        <GradientBorder
            style={{
                height: "252px",
                display: "grid",
                placeItems: "center",
            }}
        >
            <div ref={ref} style={{ position: "absolute" }} />
            <SNumberCard>
                <Text
                    circularFont
                    gradient
                    style={{ fontSize: "96px", opacity: value / targetValue }}
                    center
                >
                    {left && unit}
                    {targetValue != 0 && value}
                    {!left && unit}
                </Text>
                <Text center circularFont color="light" size="H4">
                    {description}
                </Text>
            </SNumberCard>
        </GradientBorder>
    );
};

export default NumberCard;
