import { FC } from "react";
import styled from "styled-components";
import { IVideoFile } from "../../assets/videos";
import { Text } from "../../components/UI";
import VideoView from "../../components/VideoView";

const SAboutUsCard = styled.div`
    display: grid;
    place-items: center;
`;

const SView = styled.div`
    height: 270px;
    width: 270px;

    ${(p) => p.theme.mobileQuery} {
        width: inherit;
    }
`;

interface IProps {
    title: string;
    description?: string;
    descriptionElement?: React.ReactElement;
    video?: IVideoFile;
}

const AboutUsCard: FC<IProps> = ({
    title,
    description,
    descriptionElement,
    video,
}) => {
    return (
        <SAboutUsCard>
            <SView>
                <VideoView video={video} />
            </SView>
            <Text color="light" size="H4" oneline circularFont>
                {title}
            </Text>
            {description && (
                <Text style={{ height: 30 }} color="medium" size="H7" center>
                    {description}
                </Text>
            )}
            {descriptionElement && descriptionElement}
        </SAboutUsCard>
    );
};

export default AboutUsCard;
