import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/engineus";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import Press from "../../case-study/Press";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import Clients from "../../case-study/Clients";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";
import History from "../../case-study/History";

const SGap = styled.div`
    height: 100px;
`;

function Engineus() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />

                <ClientTag caseStudy={caseStudy} />

                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>

                <About caseStudy={caseStudy}>
                    Engineus' augmented reality platform is one of the leaders
                    in <b>virtual try-on and optical measurement technology</b>{" "}
                    for the eyewear industry. The startup offers one of the most
                    advanced facial recognition and optometry solutions on the
                    market.
                </About>

                <SSection gridGap="16px" small>
                    <Clients caseStudy={caseStudy} />
                    <SGap />
                    <Video id="903404771?h=3b49f0cec0" />
                </SSection>

                <TreeviewRole caseStudy={caseStudy}>
                    We have been Engineus' primary development partner since the
                    initial MVP in 2019. Since the successful launch of the MVP,
                    we have continued developing the platform and helping
                    Engineus grow its technological offering and B2B client
                    base. Engineus' technology is now one of the leading
                    augmented reality technology providers in the eyewear
                    industry.
                    <br />
                    <br />
                    We provide a full suite of development services, including
                    AR/VR development, product design, and project management.
                    We also lead Engineus' R&D initiatives to provide
                    groundbreaking technologies applying AR/VR to the eyewear
                    industry.
                </TreeviewRole>

                <History caseStudy={caseStudy} />

                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default Engineus;
