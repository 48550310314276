import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import previewVideo from "../../../assets/videos/case-studies/visionwear-x";
import imageIcons from "../../../assets/images/icons";

const VisionWearX: ICaseStudy = {
    id: "visionwear-x",
    customPath: Paths.visionwearX,

    preview: {
        title: "Dyslexia Diagnosis",
        description:
            "VisionWEARx provides virtual and augmented reality solutions for screening, diagnosing and treating learning disabilities like dyslexia, dyscalculia and dysgraphia.",
        image: require("./preview.png"),
        video: previewVideo,
    },

    carousel: {
        images: [require("./carousel/a.png")],
    },

    clientTag: {
        logo: clientLogos.visionwearX,
        clientPicture: require("./client-picture.png"),
        clientName:
            "Marietjie Cilliers\nDirector of Clinical Research, VisionWEARx",
        clientComment:
            "Treeview can take our vision from a next-level concept to something visible and functional within the VR space.",
        clientCommentSummary:
            "Treeview can take our vision from a next-level concept to something visible and functional within the VR space.",
        clutch: "https://clutch.co/profile/treeview#review-2032935",
        link: "https://visionwear-x.com/",
        stars: "5.0",
    },

    headingA:
        "AR/VR Solution for Screening, Diagnosis &\nTreatment of Dyslexia & Other Learning Disorders",

    about: {
        firstDescription:
            "VisionWEARx provides virtual and augmented reality solutions for diagnosing and treating learning disabilities. With the incorporation of a fully immersive testing environment leveraging quantitative data, this is a groundbreaking medical breakthrough that is changing lives.\n\nTreeview worked with the VisionWEARx team and domain experts like SGDA to redesign traditional paper-based learning disorder tests into the first-of-its-kind native virtual reality diagnosis platform.",
    },

    headingB:
        "A Diagnosis Platform Designed\nfrom the Ground up for Virtual Reality",

    specialSections: {
        visionwear: {
            texts: [
                "VisionWEARx is a learning disorder diagnosis platform that allows professionals to screen, diagnose and treat learning disorders. The platform enables patients to execute the tests in virtual reality while the professionals run these tests via a companion controller mobile application.",
                "VisionWEARx leverages virtual reality to personalize and monitor the environment & user experience when taking a learning disorder test. The software allows the personalization of the following:\n\n✅ The virtual environment where the user executes the tests.\n✅ Spatial audio stimulates focus and other mental attributes.\n✅ Test fonts, sizes, and colors.\n\nThe platform is compatible with multiple learning disorders, including dyslexia, dyscalculia, and irlen syndrome.",
                "Data collection is a critical component of the system. The software provides a much more thorough data analysis than what a traditional testing environment can provide. This data collection includes:\n\n✅ Learning disorder test results.\n✅ Time analysis of test session specific to each test question.\n✅ Eye behavior analysis of the test.\n✅ Video recording of first-person point of view video of the testing session.\n✅ Heart biometric feedback.\n✅ EEG brain analysis output.\n✅ Environment, audio, and test visualization report.",
            ],
            images: [
                [
                    require("./special/a.png"),
                    require("./special/b.png"),
                    require("./special/c.png"),
                    require("./special/d.png"),
                ],
                [
                    require("./special/e.png"),
                    require("./special/f.png"),
                    require("./special/g.png"),
                    require("./special/h.png"),
                ],
            ],
        },
    },

    treeviewRole: {
        description: "",
        cards: [
            {
                targetValue: 350,
                unit: "+",
                description: "Virtual Reality\nDiagnostics Conducted",
            },
            {
                unit: "✓",
                description: "Clinical Trail",
            },
            {
                unit: "📄",
                description: "Patent Pending",
            },
        ],
    },

    // videoReel: "248496167",
    history: {
        cards: [
            {
                icon: imageIcons.plant,
                description:
                    "Founded in 2021 with a vision of revolutionizing learning diagnosis with spatial computing technology.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "Collaboration of over 3+ years researching, developing, and deploying AR/VR diagnosis tools.",
            },
            {
                icon: imageIcons.cup,
                description:
                    "Leading technology startup in the learning disability industry.",
            },
        ],
    },
};

export default VisionWearX;
