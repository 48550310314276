import ampc from "./ampc.svg";
import australianEggs from "./australian-eggs.svg";
import coalsLight from "./coals-light.svg";
import connectNGrow from "./connect-n-grow.svg";
import dpir from "./dpir.svg";
import elders from "./elders.svg";
import forestLearning from "./forest-learning.svg";
import mla from "./mla.svg";
import nsw from "./nsw.svg";
import optus from "./optus.svg";
import theshowbanksa from "./theshowbanksa.svg";
import uoa from "./uoa.svg";
import woolworths from "./woolworths.svg";

const thinkDigitalClientLogos = {
    ampc,
    australianEggs,
    coalsLight,
    connectNGrow,
    dpir,
    elders,
    forestLearning,
    mla,
    nsw,
    optus,
    theshowbanksa,
    uoa,
    woolworths,
};

export default thinkDigitalClientLogos;
