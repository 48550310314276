import benja from "./benja.png";
import dimi from "./dimi.png";
import feco from "./feco.png";
import franco from "./franco.png";
import gera from "./gera.png";
import gonza from "./gonza.png";
import gus from "./gus.png";
import hernan from "./hernan.png";
import horacio from "./horacio.png";
import joseMaria from "./jose-maria.png";
import mateo from "./mateo.png";
import nahue from "./nahue.png";
import pablo from "./pablo.png";
import raimundo from "./raimundo.png";
import santiagoRossi from "./santiago-rossi.png";
import santiago from "./santiago.png";
import susana from "./susana.png";

import palo from "./palo.png";
import german from "./german.png";
import juanzu from "./juanzu.png";

const memberImages = {
    benja,
    dimi,
    feco,
    franco,
    gera,
    gonza,
    gus,
    hernan,
    horacio,
    joseMaria,
    mateo,
    nahue,
    pablo,
    raimundo,
    santiagoRossi,
    santiago,
    susana,
    palo,
    german,
    juanzu,
};

export default memberImages;
