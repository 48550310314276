import clutch1000 from "./clutch-1000.png";
import clutchA from "./clutch-a.png";
import clutchArVrCompany from "./clutch-ar-vr-company.png";
import clutchB from "./clutch-b.png";
import clutchC from "./clutch-c.png";
import clutchD from "./clutch-d.png";
import clutchLatam from "./clutch-latam.png";
import entrepreneur from "./entrepreneur.png";
import forbes from "./forbes.png";
import glossy from "./glossy.png";
import hackatonMeta from "./hackaton-meta.png";
import manifest from "./manifest.png";
import microsoft from "./microsoft.png";
import nyt from "./nyt.png";
import unity from "./unity.png";

const awardImages = {
    clutch1000,
    clutchA,
    clutchArVrCompany,
    clutchB,
    clutchC,
    clutchD,
    clutchLatam,
    entrepreneur,
    forbes,
    glossy,
    hackatonMeta,
    manifest,
    microsoft,
    unity,
    nyt,
};

export default awardImages;
