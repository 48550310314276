import React, { FC, useMemo } from "react";
import styled, { css } from "styled-components";
import Grid from "../../components/grids/Grid";
import { Text, Image } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile } from "../../hooks";

const SPress = styled.div<{ small?: boolean }>`
    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 36px;
    margin-top: 60px;

    ${(p) =>
        p.small &&
        css`
            width: ${p.theme.sizes.pageWidth.small};
            margin: auto;
        `}

    ${(p) => p.theme.mobileQuery} {
        width: 93vw;
        margin-left: 0;
    }
`;

const Press: FC<{
    caseStudy: ICaseStudy;
    title?: string;
    small?: boolean;
    triple?: boolean;
}> = ({ caseStudy, title, small, triple }) => {
    const isMobile = useIsMobile();

    return (
        <SPress small={small}>
            <Text circularFont color="light" gradient={!title} size="H3">
                {title ?? "Press"}
            </Text>
            <Grid columns={isMobile ? 2 : triple ? 3 : 4}>
                {caseStudy.press?.map((item) => (
                    <Item key={item.title} {...item} />
                ))}
            </Grid>
        </SPress>
    );
};

const SItem = styled.div`
    display: grid;
    grid-gap: 13px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;

const SThumbnail = styled.div`
    height: 203px;
    border-radius: 15px;
    overflow: hidden;
`;

const Item: FC<{
    title: string;
    description: string;
    thumbnail: string;
    link: string;
}> = ({ title, description, thumbnail, link }) => {
    return (
        <SItem onClick={() => window.open(link)}>
            <SThumbnail>
                <Image image={thumbnail} contain imagePosition="left" />
            </SThumbnail>
            <Text circularFont color="light" size="H6">
                {title}
            </Text>
            <Text color="medium" size="H7">
                {description}
            </Text>
        </SItem>
    );
};

export default Press;
