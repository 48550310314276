import { FC } from "react";
import styled from "styled-components";
import { SSection } from "./";
import Grid from "../../components/grids/Grid";
import { Image } from "../../components/UI";
import { useIsMobile } from "../../hooks";
import awardImages from "../../assets/images/awards";

const SAwards = styled.div`
    width: 660px;

    ${(p) => p.theme.mobileQuery} {
        width: 100%;
    }
`;

const SAward = styled.div`
    height: 118px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.1);
    }
`;

const awards = {
    clutchA: {
        logo: awardImages.clutchArVrCompany,
        link: "https://clutch.co/developers/virtual-reality/leaders-matrix",
    },
    clutchB: {
        logo: awardImages.clutchLatam,
        link: "https://clutch.co/developers/virtual-reality/latin-america/leaders-matrix",
    },
    clutchC: {
        logo: awardImages.clutch1000,
        link: "https://clutch.co/press-releases/clutch-1000-fall-2023",
    },
    clutchD: {
        logo: awardImages.hackatonMeta,
        link: "https://treeview.studio/blog/meta-presence-platform-hackathon-2023/",
    },
    manifest: {
        logo: awardImages.manifest,
        link: "https://themanifest.com/virtual-reality/companies",
    },
    unity: {
        logo: awardImages.unity,
        link: "https://awards.unity.com/2020#best-project-in-architecture-engineering-construction",
    },
};

const Awards: FC = (props) => {
    const isMobile = useIsMobile();

    const items = Object.entries(awards).map(([key, client]) => (
        <SAward
            key={key}
            onClick={() => {
                window.open(client.link);
            }}
        >
            <Image image={client.logo} contain />
        </SAward>
    ));

    return (
        <SAwards>
            <Grid columns={isMobile ? 3 : 6}>{items.slice(0, 6)}</Grid>
        </SAwards>
    );
};

export default Awards;
