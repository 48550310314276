import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA7DNupQL7uqWKGOu1FICFdX_2AiMfdIQw",
    authDomain: "treeview-web.firebaseapp.com",
    projectId: "treeview-web",
    storageBucket: "treeview-web.appspot.com",
    messagingSenderId: "746825008781",
    appId: "1:746825008781:web:e4d6acaa7aab7511cbc4c4",
    measurementId: "G-NZR3BDK8DV",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
