import React, { FC } from "react";
import styled from "styled-components";
import Grid from "../../components/grids/Grid";
import { Text, Image, SVG } from "../../components/UI";
import { ICaseStudy } from "../../global-state/caseStudies";
import { useIsMobile } from "../../hooks";
import { SClient } from "../home/OurClients";

const SClients = styled.div`
    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 36px;
    margin-top: 60px;
`;

const SGrid = styled.div`
    width: ${(p) => p.theme.sizes.pageWidth.small};

    margin: auto;
    display: grid;
    grid-gap: 32px;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SRow = styled.div<{ width: string; expandWidth?: boolean }>`
    width: ${(p) => (p.expandWidth ? "100%" : p.width)};
    margin: auto;
`;

const Clients: FC<{
    caseStudy: ICaseStudy;
    title?: string;
    expandWidth?: boolean;
}> = ({ caseStudy, title, expandWidth }) => {
    const isMobile = useIsMobile();
    const clients = Object.entries(caseStudy?.clients ?? []).map(
        ([key, client]) => (
            <SClient
                key={key}
                onClick={() => {
                    if (client?.link != "") window.open(client.link);
                }}
                disabled={client?.link === ""}
            >
                <SVG image={client.image} contain />
            </SClient>
        )
    );

    const rowSize = isMobile ? 2 : 5;
    const rows = [];
    for (let i = 0; i < clients.length; i += rowSize)
        rows.push(clients.slice(i, i + rowSize));

    return (
        <SClients>
            <Text gradient size="H3" oneline circularFont>
                {title ?? "Clients Served"}
            </Text>
            <SGrid>
                {rows.map((row, i) => (
                    <SRow
                        width={`${row.length * 200}px`}
                        expandWidth={expandWidth}
                        key={i}
                    >
                        <Grid columns={row.length}>{row}</Grid>
                    </SRow>
                ))}
            </SGrid>
        </SClients>
    );
};

export default Clients;
