import Page from "../components/Page";
import { useRouter } from "../hooks";
import { Text } from "../components/UI";

function Policy() {
    const router = useRouter();
    return (
        <Page title="Policy">
            <Text color="light" size="H5">
                {`
Privacy Policy

Effective Date: 2023

Treeview ("we", "us", "our") is committed to protecting the privacy of our users ("you", "your").
This Privacy Policy outlines our commitment to ensure that your data remains private and secure when you use our app.

1. Data We Do NOT Collect

At Treeview, we understand the importance of privacy and have designed our app with a strong focus on safeguarding your information.

We do not collect, store, or process any of your personal data while you are using our app.

This commitment to privacy means that you can trust that your data remains safe and will not be shared with anyone.

2. Data We DO Collect: Anonymized Data Collection

At Treeview, our apps are designed to enhance user experience through understanding of app usage patterns. To achieve this, we collect anonymized data reflecting basic app usage behavior. This process involves gathering non-personal information without any identifiers that could link back to you, ensuring your anonymity and privacy are maintained. This data aids us in identifying trends and improving app functionality, all while upholding our commitment to your privacy by ensuring that your identity remains secure and untraceable.

3. Security Measures

To further protect your privacy, we have implemented stringent security measures within Treeview.
These measures ensure that your data is not accessed, disclosed, altered, or destroyed by unauthorized parties.
Additionally, we continuously update and improve our security protocols to address new and emerging threats, providing you with the highest level of privacy protection possible.

4. Changes to This Privacy Policy

We may update our Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated Privacy Policy on this page. You are advised to review this Privacy Policy periodically to stay informed about any modifications. Changes to this Privacy Policy are effective when they are posted on this page.

5. Contact Us

If you have any questions or concerns about this Privacy Policy or our commitment to protecting your privacy, please contact us at support@treeview.studio.

By using Treeview developed apps, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
                `}
            </Text>
        </Page>
    );
}

export default Policy;
