import clientLogos from "../../../assets/images/client-logos";
import videos from "../../../assets/videos";
import { Paths } from "../../../context/RouterContext";
import { ICaseStudy } from "../../../global-state/caseStudies";
import previewVideo from "../../../assets/videos/case-studies/microsoft";
import imageIcons from "../../../assets/images/icons";

const Microsoft: ICaseStudy = {
    id: "microsoft",
    customPath: Paths.microsoft,

    preview: {
        title: "Microsoft Mixed Reality Digital Twin",
        description:
            "Treeview collaborated with Microsoft to design and develop a digital twin IoT POC solution for Uruguay's largest renewable energy production project.",
        image: require("./preview.png"),
        video: previewVideo,
    },

    carousel: {
        images: [require("./carousel/a.png")],
    },

    clientTag: {
        logo: clientLogos.microsoft,
        clientPicture: require("./client-picture.png"),
        clientName:
            "Elianne Elbaum\nMicrosoft Co-Innovation Lab Manager",
        clientComment:
            "Treeview was an exceptional partner to collaborate with. Their team demonstrated the highest level of technical expertise, swift execution, and innovative ideas. Along with our industry partners, they successfully enabled a compelling use case that integrates Microsoft's latest emerging technologies, adding innovation and value to the region's largest industries.",
        clientCommentSummary:
            "Treeview was an exceptional partner to collaborate with. Their team demonstrated the highest level of technical expertise, swift execution, and innovative ideas. Along with our industry partners, they successfully enabled a compelling use case that integrates Microsoft's latest emerging technologies, adding innovation and value to the region's largest industries.",
        // stars: "5.0",
        // clutch: "https://clutch.co/profile/treeview#summary",
        link: "https://www.microsoft.com/es-uy/",
    },

    headingA: "Hybrid Renewable Energy Generation\nMixed Reality Digital Twin Simulation",

    about: {
        firstDescription: "",
        imageCards: [
            {image: require("./about-images/a.png"), caption: "Chris Young, Jun Yamasaki and Elianne Elbaum - Microsoft Team"},
            {image: require("./about-images/b.png"), caption: "Omar Paganini - Minister of Industry of Uruguay"},
            {image: require("./about-images/c.png"), caption: "Beatriz Argimón - Vice President of Uruguay"},
            {image: require("./about-images/d.png"), caption: "UTEC Directors"}
        ]
    },

    treeviewRole: {
        description:
            "Treeview collaborated with Microsoft and Teyma to design an develop a digital twin MR solution enabled non-technical users to visualize and understand multiple data layers of the renewable energy production site, including live wind and solar visual data, the project's real-time energy production, complex capacity factor, and additional technical data.\n\nThe digital twin solution also allowed users to inspect specific equipment by visualizing additional layers of information. The primary MR headset used was the Microsoft Hololens 2, and the solution was also available on iPad via ARKit, as well as through a web deployment solution.",

    },

    history: {
        cards: [
            {
                icon: imageIcons.lightBlub,
                description:
                    "Microsoft approached Treeview to explore how Microsoft technologies could add value to Teyma, an industry-leading renewable energy production enterprise.",
            },
            {
                icon: imageIcons.treeview,
                description:
                    "We collaborated with Microsoft and Teyma to design and develop a digital twin IoT POC solution for Uruguay’s largest renewable energy production project.",
            },
            {
                icon: imageIcons.rocket,
                description:
                    "Successful deployment of the solution and public launch at the Microsoft Montevideo Co-Innovation Lab anniversary.", 
                },
        ],
    },
};

export default Microsoft;
