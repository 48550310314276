import { FC } from "react";
import { SSection } from "../home/";
import { OffsetGrid } from "../../components/grids/OffsetGrid";
import { Text } from "../../components/UI";
import BasicCard from "../../components/cards/BasicCard";
import imageIcons from "../../assets/images/icons";
import { useIsMobile } from "../../hooks";

const LatestWork: FC = () => {
    const isMobile = useIsMobile();
    const items = [
        {
            title: "Client Ownership and Control",
            description:
                "All the software and visual assets we develop will be fully owned and controlled by you. Owning your digital assets is key to creating long-term value for your company as you journey into future technologies.",
            image: imageIcons.police,
        },
        {
            title: "Dedicated Team",
            description:
                "We offer a detail-oriented, personalized service, becoming the ideal choice for organizations seeking a tailored approach to spatial computing. Our boutique size enables us to adapt quickly to specific needs and pivot rapidly.",
            image: imageIcons.ball,
        },
        {
            title: "Experienced in Project Management",
            description:
                "We provide comprehensive project management services, encompassing evaluation of implementation stages, coordination of plans and schedules, information gathering, team organization, progress tracking, and adjustment making.",
            image: imageIcons.compass,
        },
        {
            title: "We are Ready to Assist You!",
            description:
                "Our team, comprised of senior level developers and designers specialized in AR/VR, is ready to assist you. This eliminates the hassle and risk of hiring an in-house team for your innovation needs.",
            image: imageIcons.plane,
        },
        {
            title: "Quality Focused",
            description:
                "We are a team of highly skilled and experienced developers, committed to delivering exceptional high-quality spatial computing experiences.",
            image: imageIcons.diamond,
        },
        {
            title: "Building Long Term Partnerships",
            description:
                "We work to establish trust by focusing on communication, open collaboration, and the pursuit of excellence that forms the foundation of multi-year collaboration with our clients.",
            image: imageIcons.handShake,
        },
    ].map((i) => (
        <BasicCard
            key={i.title}
            title={i.title}
            description={i.description}
            image={i.image}
        />
    ));

    return (
        <SSection style={{ marginTop: isMobile ? "50px" : "254px" }}>
            <div style={{ display: "grid", gridGap: "10px" }}>
                <Text gradient size="H2" circularFont oneline>
                    How We Work
                </Text>
                <Text color="light" size="H4" circularFont>
                    Engaging in long-term partnerships to help you thrive into
                    the future
                </Text>
                <Text
                    color="medium"
                    size="H6"
                    style={{ width: isMobile ? "auto" : "717px" }}
                >
                    We are a committed, flexible, and collaborative team,
                    dedicated to crafting quality software that ensures you are
                    always at the forefront.
                </Text>
            </div>
            <OffsetGrid items={items} />
        </SSection>
    );
};

export default LatestWork;
