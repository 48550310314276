import React, { FC } from "react";
import styled, { css } from "styled-components";
import { IClientTag } from "../../global-state/caseStudies";
import { GradientBorder, Image, SVG, Text } from "../UI";
import { useIsMobile } from "../../hooks";
import icons from "../../assets/icons";
import clientLogos from "../../assets/images/client-logos";

const SClientReview = styled.div`
    padding: 36px;
    height: 305px;
    width: 608px;
    max-width: 80vw;

    display: grid;
    grid-gap: 16px;
    position: relative;

    ${(p) => p.theme.mobileQuery} {
        grid-gap: 0px;
    }
`;

const SLogo = styled.div<{ clickable: boolean }>`
    height: 65px;
`;

const SClient = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 16px;
    align-items: center;

    ${(p) => p.theme.mobileQuery} {
        width: 64px;
    }
`;

const SClientPicture = styled.div`
    height: 64px;
    width: 64px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
`;

const SClutch = styled.div<{ clickable?: boolean }>`
    position: absolute;
    bottom: 24px;
    right: 24px;
    height: 16px;
    width: 57px;

    ${(p) =>
        p.clickable &&
        css`
            cursor: pointer;
            transition: 0.3s;
            :hover {
                transform: scale(1.1);
            }
        `}
`;

const ClientReview: FC<{ clientTag: IClientTag }> = ({ clientTag }) => {
    const isMobile = useIsMobile();
    return (
        <GradientBorder>
            <SClientReview>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: ".8fr 1.3fr",
                        gridGap: isMobile ? 32 : 64,
                        height: "min-content",
                    }}
                >
                    <SLogo
                        clickable={clientTag.link != undefined}
                        onClick={() =>
                            clientTag.link && window.open(clientTag.link)
                        }
                    >
                        <Image
                            contain
                            image={clientTag.logo}
                            imagePosition="left"
                        />
                    </SLogo>
                    <SClient>
                        <SClientPicture>
                            <Image contain image={clientTag.clientPicture} />
                        </SClientPicture>
                        <Text size="H7" color="light" center={isMobile}>
                            {clientTag.clientName}
                        </Text>
                    </SClient>
                </div>
                <div style={{ display: "flex", gap: 8 }}>
                    <SVG
                        gradient
                        image={icons.star}
                        style={{ width: 32, height: 32 }}
                    />
                    <Text oneline circularFont size="H3" color="light">
                        {clientTag.stars}
                    </Text>
                </div>
                <Text size="H3" gradient circularFont style={{ width: "100%" }}>
                    {clientTag.clientCommentSummary}
                </Text>

                <SClutch
                    clickable={clientTag.clutch != undefined}
                    onClick={() =>
                        clientTag.clutch && window.open(clientTag.clutch)
                    }
                >
                    <Image image={clientLogos.clutch} contain />
                </SClutch>
            </SClientReview>
        </GradientBorder>
    );
};

export default ClientReview;
