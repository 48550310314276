import React from "react";
import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import logo from "../../../database/case-studies/newtons-room/logo.png";
import download from "../../../database/case-studies/newtons-room/download.png";
import { Button, Image, Text } from "../../../components/UI";
import caseStudy from "../../../database/case-studies/newtons-room";
import About from "../../case-study/About";
import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import DevelopmentServices from "../../case-study/DevelopmentServices";
import Vimeo from "../../../components/Vimeo";
import Press from "../../case-study/Press";
import BecomeAnAlphaTester from "../../case-study/BecomeAnAlphaTester";
import Youtube from "../../../components/Youtube";

const SLogo = styled.div`
    height: 110px;
    margin: 250px 0 150px 0;

    ${(p) => p.theme.mobileQuery} {
        width: 93vw;
        margin: 100px 0;
    }
`;

const SDownload = styled.div`
    height: 83px;
    margin-bottom: 90px;

    cursor: pointer;
    transition: 0.3s;
    :hover {
        transform: scale(1.05);
    }

    ${(p) => p.theme.mobileQuery} {
        width: 93vw;
        margin: 100px 0;
    }
`;

const SVideo = styled.div`
    width: ${(p) => p.theme.sizes.pageWidth.small};
    margin: auto;
    aspect-ratio: 16/9;
    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        margin-left: 0;
    }
`;

const SPressKit = styled.div`
    height: min-content;
    display: grid;
    grid-gap: 200px;
    place-items: center;
    grid-template-columns: auto min-content;
    margin-bottom: 40px;
    margin-top: 100px;
    margin-right: 110px;

    ${(p) => p.theme.mobileQuery} {
        width: 95vw;
        grid-gap: 16px;
    }
`;

function NewtonsRoom() {
    return (
        <Page title="Newton's Room: Mixed Reality Physics Simulator">
            <SSection>
                <SLogo>
                    <Image contain image={logo} />
                </SLogo>
                <SDownload
                    onClick={() =>
                        window.open(
                            "https://www.meta.com/en-gb/experiences/6459298387462158/"
                        )
                    }
                >
                    <Image contain image={download} />
                </SDownload>
                <SVideo>
                    <Youtube id={caseStudy.videoReel ?? ""} />
                </SVideo>
                <SPressKit>
                    <Text
                        circularFont
                        gradient
                        size="H3"
                        style={{ width: "100%" }}
                    >
                        Mixed Reality Physics
                        <br />
                        Experiential Learning Education
                    </Text>
                    <Button
                        onClick={() =>
                            window.open(
                                `${window.location.pathname}/presskit.pdf`
                            )
                        }
                    >
                        PRESSKIT
                    </Button>
                </SPressKit>
                <About caseStudy={caseStudy} />
                <DevelopmentServices
                    caseStudy={caseStudy}
                    title="☄️  Project Details"
                />
                <div />
                <Press
                    small
                    caseStudy={caseStudy}
                    title="📚  Developer Blog"
                    triple
                />
                <div />
                <BecomeAnAlphaTester caseStudy={caseStudy} />
                <div />
                <MoreCaseStudies caseStudy={caseStudy} />
                <div />
                <div />
            </SSection>
        </Page>
    );
}

export default NewtonsRoom;
