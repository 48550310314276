import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import icons from "../assets/icons";
import FullWidthContainer from "./FullWidthContainer";
import { IconButton, Image } from "./UI";

const SImageCarousel = styled.div`
    height: 100%;
    position: relative;
`;

const SControls = styled.div`
    position: absolute;
    bottom: 19px;
    left: 50%;
    transform: translateX(-50%);

    height: 40px;
    display: grid;
    grid-template-columns: 40px auto 40px;
    grid-gap: 16px;
`;

const SDots = styled.div`
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    grid-gap: 4px;
`;

const SDot = styled.div<{ selected?: boolean }>`
    height: 10px;
    width: 10px;
    background-color: ${(p) =>
        p.selected ? p.theme.colors.light : p.theme.colors.mediumDark};
    border-radius: 50%;
`;

const SImage = styled.div<{ selected: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    transition: 0.3s;
    opacity: ${(p) => (p.selected ? 1 : 0)};
`;

interface IProps {
    images: string[];
    imagePosition?: string;
}

const ImageCarousel: FC<IProps> = (props) => {
    const [selected, setSelected] = useState(0);
    const images = props.images.map((image, i) => (
        <SImage key={i} selected={i === selected}>
            <Image image={image} imagePosition={props.imagePosition} />
        </SImage>
    ));

    function rotateHandler(direction: 1 | -1): void {
        setSelected((p) => {
            p += direction;
            if (p < 0) p = props.images.length - 1;
            if (p >= props.images.length) p = 0;
            return p;
        });
    }

    return (
        <FullWidthContainer>
            <SImageCarousel>
                {images}

                {images.length > 1 && (
                    <SControls>
                        <IconButton
                            solid
                            image={icons.arrowLeft}
                            onClick={() => rotateHandler(-1)}
                        />
                        <SDots>
                            {props.images.map((image, i) => (
                                <SDot key={i} selected={i === selected} />
                            ))}
                        </SDots>
                        <IconButton
                            solid
                            image={icons.arrowRight}
                            onClick={() => rotateHandler(1)}
                        />
                    </SControls>
                )}
            </SImageCarousel>
        </FullWidthContainer>
    );
};

export default ImageCarousel;
