import baby from "./baby.png";
import ball from "./ball.png";
import bank from "./bank.png";
import bullEye from "./bull-eye.png";
import burger from "./burger.png";
import car from "./car.png";
import clock from "./clock.png";
import compass from "./compass.png";
import crane from "./crane.png";
import cup from "./cup.png";
import diamond from "./diamond.png";
import gift from "./gift.png";
import handShake from "./hand-shake.png";
import hart from "./hart.png";
import laptop from "./laptop.png";
import lightBlub from "./light-blub.png";
import lightning from "./lightning.png";
import plane from "./plane.png";
import plant from "./plant.png";
import police from "./police.png";
import rocket from "./rocket.png";
import ruler from "./ruler.png";
import scientist from "./scientist.png";
import starEyes from "./star-eyes.png";
import starFace from "./star-face.png";
import star from "./star.png";
import tako from "./tako.png";
import telescope from "./telescope.png";
import treeview from "./treeview.png";
import truck from "./truck.png";
import tube from "./tube.png";
import wifi from "./wifi.png";
import work from "./work.png";
import world from "./world.png";

const imageIcons = {
    baby,
    ball,
    bank,
    bullEye,
    burger,
    car,
    clock,
    compass,
    crane,
    cup,
    diamond,
    gift,
    handShake,
    hart,
    laptop,
    lightBlub,
    lightning,
    plane,
    plant,
    police,
    rocket,
    ruler,
    scientist,
    starEyes,
    starFace,
    star,
    tako,
    telescope,
    treeview,
    truck,
    tube,
    wifi,
    work,
    world,
};

export default imageIcons;
