import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import videos, { IVideoFile } from "../assets/videos";
import { useIsMobile } from "../hooks";
import { Button, Text, Image } from "./UI";
import VideoView from "./VideoView";

const SPageTitle = styled.div`
    height: calc(100vh - 70px);
    display: grid;
    grid-template-columns: auto 470px;
    align-items: center;

    margin: auto;
    width: 1188px;
    transform: translateX(40px);

    ${(p) => p.theme.mobileQuery} {
        transform: none;
        width: 100%;
        height: auto;
        grid-template-columns: auto;
        grid-auto-flow: reverserow;
        margin-bottom: 50px;
    }
`;

const STextBox = styled.div`
    display: grid;
    grid-gap: 30px;
    height: min-content;

    ${(p) => p.theme.mobileQuery} {
        justify-items: center;
    }
`;

const SVideoBox = styled.div<{ big?: boolean }>`
    height: 100%;

    transform: scale(${(p) => (p.big ? 1 : 0.75)});

    ${(p) => p.theme.mobileQuery} {
        grid-row-start: 1;
        transform: scale(${(p) => (p.big ? 1.05 : 0.75)});
        max-width: 500px;
        margin: auto;
    }
`;

interface IProps extends PropsWithChildren {
    title: string;
    subTitle?: string;
    description?: string;
    descriptionNode?: React.ReactNode;
    video?: IVideoFile;
    image?: string;
    action?: {
        text: string;
        onClick(): void;
    };
    extra?: React.ReactNode;
}

const PageTitle: FC<IProps> = ({
    title,
    subTitle,
    description,
    descriptionNode,
    action,
    video,
    image,
    extra,
}) => {
    const isMobile = useIsMobile();

    return (
        <SPageTitle>
            <STextBox>
                <Text
                    circularFont
                    size="H1"
                    color="light"
                    weight="medium"
                    center={isMobile}
                >
                    {title}
                </Text>
                {subTitle && (
                    <Text
                        circularFont
                        size="H4"
                        gradient
                        weight="medium"
                        center={isMobile}
                        style={{ width: "100%" }}
                    >
                        {subTitle}
                    </Text>
                )}
                {descriptionNode ?? (
                    <Text size="H6" color="medium" center={isMobile}>
                        {description}
                    </Text>
                )}
                <div />
                {action && (
                    <Button onClick={() => action.onClick()}>
                        {action.text}
                    </Button>
                )}
                <div />
                {extra && extra}
            </STextBox>
            <SVideoBox big={video != videos.servicesHeroLightBulb}>
                {video && <VideoView video={video} />}
                {image && <Image image={image} contain />}
            </SVideoBox>
        </SPageTitle>
    );
};

export default PageTitle;
