import { FC } from "react";
import styled, { css } from "styled-components";
import { GradientBorder, Text, Image } from "../UI";
import { ITeamMember } from "../../global-state/teamMembers";
import { useIsMobile } from "../../hooks";

const STeamMember = styled.div`
    display: grid;
    padding-bottom: 21px;
    grid-template-rows: auto min-content min-content;
    height: inherit;
`;

const SAvatar = styled.div`
    position: absolute;
    height: 200px;
    width: 230px;
    bottom: 8px;

    ${(p) => p.theme.mobileQuery} {
        height: 170px;
        width: 170px;
    }
`;

const SAvatarHolder = styled.div`
    position: relative;
`;

interface IProps {
    member: ITeamMember;
}

const TeamMember: FC<IProps> = ({ member }) => {
    const isMobile = useIsMobile();
    return (
        <GradientBorder
            style={{
                height: isMobile ? 170 : 230,
                width: isMobile ? 170 : 230,
                marginTop: isMobile ? 70 : 30,
            }}
        >
            <STeamMember>
                <SAvatarHolder>
                    <SAvatar>
                        <Image image={member.image} contain />
                    </SAvatar>
                </SAvatarHolder>
                <Text center oneline color="light" size="H4" circularFont>
                    {member.name}
                </Text>
                <Text center color="mediumDark" size="H6" oneline>
                    {member.position}
                </Text>
            </STeamMember>
        </GradientBorder>
    );
};

export default TeamMember;
