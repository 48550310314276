import React, { FC } from "react";
import styled from "styled-components";
import VimeoPlayer from "@u-wave/react-vimeo";

const SVimeo = styled.div`
    height: 100%;
    * {
        height: 100%;
        width: 100%;
    }
`;

const Vimeo: FC<{ id: string }> = ({ id }) => {
    return (
        <SVimeo>
            <div>
                <iframe
                    src={`https://player.vimeo.com/video/${id}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder={"0"}
                    allowFullScreen={true}
                    allow="autoplay; fullscreen; picture-in-picture"
                    title="smd.mp4"
                ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </SVimeo>
    );
};

export default Vimeo;
