import styled from "styled-components";
import Page from "../../../components/Page";
import { SSection } from "../../home/";
import { Image, Text } from "../../../components/UI";

import caseStudy from "../../../database/case-studies/ulta-beauty";

import MoreCaseStudies from "../../case-study/MoreCaseStudies";
import { Helmet } from "react-helmet";
import { useIsMobile } from "../../../hooks";
import ImageCarousel from "../../../components/ImageCarousel";
import ClientTag from "../../case-study/ClientTag";
import About from "../../case-study/About";
import TreeviewRole from "../../case-study/TreeviewRole";
import Video from "../../case-study/Video";

import imageAboutA from "./about-a.png";
import imageAboutB from "./about-b.png";
import History from "../../case-study/History";

const SAboutPictures = styled.div`
    height: 497px;
    width: 100%;
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;

    ${(p) => p.theme.mobileQuery} {
        grid-template-columns: 1fr;
        grid-template-rows: 397px 397px;
        height: auto;
        margin: 32px 0;
    }
`;

function UltaBeauty() {
    const isMobile = useIsMobile();

    return (
        <Page title={`Case Study - ${caseStudy?.id}`}>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <SSection gridGap={isMobile ? "32px" : "64px"}>
                <ImageCarousel
                    images={caseStudy.carousel.images ?? []}
                    imagePosition={caseStudy?.carousel?.imagePosition}
                />
                <ClientTag caseStudy={caseStudy} />
                <Text circularFont gradient size="H3" style={{ width: "auto" }}>
                    {caseStudy.headingA}
                </Text>
                <About caseStudy={caseStudy}>
                    <a href="https://www.ulta.com/" target="_blank">
                        ULTA Beauty
                    </a>{" "}
                    is the largest beauty retailer in the United States. The
                    company is the premier destination for cosmetics, fragrance,
                    body, skin, and haircare products. With over 25,000 products
                    from approximately 500 beauty brands across all categories,
                    <br />
                    <br />
                    ULTA Beauty exists to disrupt the status quo. The company is
                    an <b>industry leader in innovation,</b> exploring new ways
                    to bring experiences to customers that enable the company to
                    express their brand's story in powerful new ways.
                </About>
                <SSection gridGap="16px" small>
                    <Text
                        circularFont
                        color="light"
                        size="H3"
                        style={{ marginBottom: 32, marginTop: -64 }}
                    >
                        💄  Ariana Grande’s REM Beauty VR
                    </Text>
                    <Text size="H6" color="medium">
                        ULTA Beauty introduced an immersive way for customers to
                        experience R.E.M. Beauty products using Meta Quest
                        headsets in-store. This marked the first time the beauty
                        retailer has
                        <b>
                            ventured into virtual reality interactive
                            experiences.
                        </b>
                        <br />
                        <br />
                        Set in an outer-space dream theme with{" "}
                        <b>Ariana Grande</b>
                        's music, the VR experience allows customers to interact
                        with and virtually try on various beauty products. The
                        experience showcases new ways of connecting with brands
                        through immersive and emotional storytelling, deepening
                        brand-customer engagement.
                    </Text>
                    <SAboutPictures>
                        <Image image={imageAboutA} contain />
                        <Image image={imageAboutB} contain />
                    </SAboutPictures>
                    <Video id="903840843?h=6d28f0be31" />
                </SSection>
                <TreeviewRole caseStudy={caseStudy}>
                    We work closely with ULTA Beauty's digital innovation team,
                    providing development services that enable ULTA to{" "}
                    <b>leverage the latest spatial computing technologies.</b>{" "}
                    As part of the collaboration, we have developed internal
                    tools for ULTA's development team, co-designed, and deployed
                    in-store interactive experiences, as well as R&D initiatives
                    to explore the future of retail through immersive
                    technologies.
                </TreeviewRole>
                <History caseStudy={caseStudy} />
                <MoreCaseStudies caseStudy={caseStudy} />
            </SSection>
        </Page>
    );
}

export default UltaBeauty;
