import { FC } from "react";
import { SSection } from "../home/";
import { Text } from "../../components/UI";
import { useIsMobile } from "../../hooks";
import Grid from "../../components/grids/Grid";
import NumberCard from "../../components/cards/NumberCard";
import styled from "styled-components";
import Testimonials from "./Testimonials";
import DATABASE from "../../database";

const LatestWork: FC = () => {
    const isMobile = useIsMobile();

    return (
        <SSection small>
            <div style={{ display: "grid", gridGap: "10px" }}>
                <Text color="light" size="H4" oneline circularFont>
                    Why Us?
                </Text>
                <Text
                    gradient
                    size="H3"
                    oneline={!isMobile}
                    style={{ width: "100%" }}
                    circularFont
                >
                    Ideal AR/VR Development Partner
                    <br />
                    <br />
                </Text>
                <Text color="medium" size="H6">
                    We are passionate early adopters of virtual and augmented
                    reality technology. We believe AR/VR is the next generation
                    of the evolution of consumer technology, from personal
                    computers → smartphones → AR/VR. We feel privileged to
                    participate in the manifestation of the next great
                    technological revolution.
                </Text>
                <div />
            </div>

            <Grid style={{ margin: isMobile ? 0 : "50px" }}>
                <NumberCard
                    targetValue={DATABASE[
                        "global-variables"
                    ].getYearsOfExperience()}
                    unit="+"
                    description="Years in Business"
                />
                <NumberCard
                    targetValue={150}
                    unit="+"
                    description="AR/VR Projects Developed"
                />
                <NumberCard
                    targetValue={DATABASE["global-variables"].totalClients}
                    unit="+"
                    description="Clients Served"
                />
            </Grid>

            <Item
                title="🔌 Plug & Play a Team of Experts"
                description="AR/VR developers are a scarce resource and hard to find. Hiring talented ones with multiple years of experience is even more challenging. We take the whole AR/VR development process off your shoulders, so you don’t have to worry about hiring an in-house dev team anymore. By partnering with us, you can start focusing on what really matters: building a healthy and growing business."
            />
            <Item
                title="💎 Quality Software, Built to Last"
                description="We are obsessed with quality and creating amazing AR/VR experiences. We take pride in delivering world-class software to our clients and exceeding expectations. We are a senior level team striving to provide the industry’s highest level of development and design. Everyone working on your project is fully committed and invested in your success."
            />
            <Item
                title="🤝 A Partner for Your Whole AR/VR Journey"
                description="Our expert team will assist you in every step of your AR/VR journey. We’ll provide everything you’ll need to build world-class software, including AR/VR development, 3D design, UI/UX development, backend development, project management, QA and DevOps."
            />
            <Item
                title="🌟 Serving Clients since the Oculus DK2"
                description="Founded in 2016, we have been creating AR/VR software long enough to have experienced just about everything that could happen when building software in this niche. Because of this, we are less likely to make mistakes when the stakes are high, we scope projects more accurately, and we provide expert guidance to launch better AR/VR software, faster."
            />
            <Item
                title={`🤩 +${DATABASE["global-variables"].totalClients} Happy Clients`}
                description={`In these +${DATABASE[
                    "global-variables"
                ].getYearsOfExperience()} years, we have worked with more than ${
                    DATABASE["global-variables"].totalClients
                } happy clients. We’ve gained ample experience in AR/VR development for various industries and made our solutions compliant with the specific requirements. Some of the industries we have worked with include entertainment, architecture, construction, healthcare, well-being, agrotech, and education.`}
            />

            <Grid style={{ margin: isMobile ? 0 : "50px" }}>
                <NumberCard
                    targetValue={20}
                    unit="k+"
                    description="Git Commits Pushed"
                />
                <NumberCard
                    targetValue={1}
                    unit="m+"
                    description="Lines of Code Shipped"
                />
                <NumberCard
                    targetValue={25}
                    unit="+"
                    description="AR/VR Devices Developed"
                />
            </Grid>

            {!isMobile && <Item title="🏆 What Our Clients Are Saying" />}

            {!isMobile && <Testimonials />}
        </SSection>
    );
};

const SItem = styled.div`
    display: grid;
    grid-gap: 16px;
`;

const Item: FC<{ title: string; description?: string }> = (props) => {
    return (
        <SItem>
            <Text circularFont size="H3" color="light">
                {props.title}
            </Text>
            {props.description && (
                <Text size="H6" color="medium">
                    {props.description}
                </Text>
            )}
        </SItem>
    );
};

export default LatestWork;
